import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { NewDeliveryService } from './new-delivery.service';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { Observable } from 'rxjs';
import { startWith, map} from 'rxjs/operators';
import { isUndefined } from 'util';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonModalComponent } from 'src/app/shared/dialog/common-modal.component';
import { MatSnackBar } from '@angular/material';
import { INewDelivery } from 'src/app/modals/INewDelivery';
import { IPowderType } from 'src/app/modals/IPowderType';
import { ISupplier } from 'src/app/modals/ISupplier';
import { IBatch } from 'src/app/modals/IBatch';
import { QRCodeDialogData } from 'src/app/shared/dialog/qrcodegenerator/QRCodeDialogData';
import { QrcodegeneratorComponent } from 'src/app/shared/dialog/qrcodegenerator/qrcodegenerator.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MaterialTypeService } from '../add-material-type/material-type.service';

@Component({
  selector: 'app-new-delivery',
  templateUrl: './new-delivery.component.html',
  styleUrls: ['./new-delivery.component.css']
})

export class NewDeliveryComponent implements OnInit {

  newDeliveryData: INewDelivery;

  filteredPowder: Observable<IPowderType[]>;
  powders: IPowderType[] = [];

  filteredSupplier: Observable<ISupplier[]>;
  suppliers: ISupplier[] = [];

  filteredBatch: Observable<IBatch[]>;
  batches: IBatch[] = [];

  containerTypes: any[] = [
    {value: 'Metal'},
    {value: 'Plastic'}
  ];

  labCheckCycle = 0;
  newDeliveryForm: FormGroup;
  powderName: string;
  batchName: string;
  totalMass: number;
  emptyContainerMass: number;

  powderVal = 0;
  containerType: string;

  isRefreshed = false;

  showEmptyContainerWeight = true;
  Title: any;
  Mssg: any;
  YButton: any;
  NButton: any;

  constructor(private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private newDeliveryService: NewDeliveryService,
    public dialog: MatDialog,
    private router: Router,
    public translate: TranslateService,
    private materialTypeService: MaterialTypeService) {
  }

  ngOnInit() {

    this.newDeliveryForm = this.formBuilder.group({
      supplierName: ['', [Validators.required]],
      powderName: ['', [Validators.required]],
      batchName: ['', [Validators.required]],
      containerType: ['', [Validators.required]],
      container_weight: [1, [Validators.required, Validators.min(1), Validators.max(500)]],
      capacity: [1, [Validators.required, Validators.min(1), Validators.max(500)]],
      containerEmptyWeight: [1, [Validators.required, Validators.min(1)]],
      number_of_containers: [1, [Validators.required, Validators.min(1), Validators.max(500)]]
    });

    this.containerType = 'Plastic';
    this.newDeliveryData = this.route.snapshot.data['newDeliveryData'];
    this.powders = this.newDeliveryData.powderTypes;
    this.suppliers = this.newDeliveryData.suppliers;
    this.batches = this.newDeliveryData.batches === undefined ? [] : this.newDeliveryData.batches;
    this.newDeliveryForm.controls['containerType'].setValue(this.containerTypes[0].value);

    this.filteredSupplier = this.newDeliveryForm.get('supplierName').valueChanges
      .pipe(
        startWith(''),
        map(supplier => supplier ? this._filteredSupplier(supplier) : this.suppliers.slice())
    );

    this.filteredPowder = this.newDeliveryForm.get('powderName').valueChanges
      .pipe(
        startWith(''),
        map(powder => powder ? this._filteredMaterial(powder) : this.powders.slice())
    );

    this.filteredBatch = this.newDeliveryForm.get('batchName').valueChanges
      .pipe(
        startWith(''),
        map(batch => {
          if (batch) {
            return this._filteredBatch(batch);
          } else {
            return this.batches;
          }
        })
    );

    // this.checked = false;
    this.totalMass = 1;
    this.emptyContainerMass = 1;
   // this.sieveCycle = 0;
  }

  createDelivery(ND_Form: NgForm): void {
    const container = {} as IContainer;
    const duplicateBatch: IBatch = this.batches.find(batch => batch.batchName === this.newDeliveryForm.get('batchName').value);
    const newBatchColor: string = this.randomHexCode();

    container.supplierName = this.newDeliveryForm.get('supplierName').value;
    container.powderName = this.powders.find(pw => pw.powderName === this.newDeliveryForm.get('powderName').value).powderName;
    container.powderCssColor = this.powders.find(pw => pw.powderName === this.newDeliveryForm.get('powderName').value).powderCssColor;
    container.batchCssColor = duplicateBatch ? duplicateBatch.batchCssColor : newBatchColor;
    container.batchName = duplicateBatch ? duplicateBatch.batchName : this.newDeliveryForm.get('batchName').value;
    container.containerOrMachineName = '';
    container.containerEmptyWeight = this.newDeliveryForm.value.containerType === 'Plastic' ? 0 : this.newDeliveryForm.value.containerEmptyWeight;
    container.isSeived = true;
    container.isMachine = false;
    container.lastSeiveCycle = 0;
    container.containerType = this.newDeliveryForm.value.containerType === 'Plastic' ? 'P' : 'M';
    container.mass = this.newDeliveryForm.get('container_weight').value;
    container.bookedForSource = false;
    container.bookedForDest = false;
    container.selectionTimestamp = '0';
    container.selectedByUserId = null;
    container.capacity = this.newDeliveryForm.get('capacity').value;
    container.lastLabCheckAtCycle = 0;
    container.labCheckAtCycle = this.labCheckCycle;

    const duplicateSupplier: ISupplier = this.suppliers.find(supplier => supplier.name === container.supplierName);
    if (!duplicateSupplier) {
      this.suppliers.push({name: container.supplierName});
    }
    this.newDeliveryService.createNewDelivery(container, this.newDeliveryForm.get('number_of_containers').value, duplicateSupplier ? false : true, duplicateBatch ? false : true).subscribe(res => {
      this.parseResult(res, duplicateBatch ? true : false, newBatchColor, ND_Form);
    });
  }

  printLabel(): void {
    console.log('Delivery created');
  }

  reset(event): void {

    this.newDeliveryForm.reset();
    this.setDefaultValues();
    event.preventDefault();
  }

  setDefaultValues(): void {

    this.newDeliveryForm.get('containerType').setValue('Metal');
    this.newDeliveryForm.get('containerEmptyWeight').setValue(1);
    this.newDeliveryForm.get('container_weight').setValue(1);
    this.newDeliveryForm.get('capacity').setValue(1);
    this.newDeliveryForm.get('number_of_containers').setValue(1);

  }

  private _filteredMaterial(value: string): IPowderType[] {
    const filterValue = value.toLowerCase();
    return this.powders.filter(powder => powder.powderName.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filteredBatch(value: string): IBatch[] {
    const filterValue = value.toLowerCase();
    return this.batches.filter(batch => batch.batchName.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filteredSupplier(value: string): ISupplier[] {
    const filterValue = value.toLowerCase();
    return this.suppliers.filter(supplier => supplier.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private randomHexCode(): string {
    return '#80' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }


  /**
   * Opens a toast / snacbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  /**
   * Event occurs when powder type is selected.
   * Changes the batches based on the powder type selection.
   * @param $event selection event
   */
  onSelectPowderType($event): void {
    if ($event.isUserInput) {
      console.log('selected powder type : ' + $event.source.value);
      const powderType = this.powders.find(powder => powder.powderName === $event.source.value);

      if (powderType !== null) {
        this.newDeliveryForm.get('batchName').reset();
        this.batches = powderType.batches === undefined ? [] : powderType.batches.filter(batch => (batch.batchName !== ''));
        this.filteredBatch = this.newDeliveryForm.get('batchName').valueChanges
          .pipe(
            startWith(''),
            map(batch => {
              if (batch) {
                return this._filteredBatch(batch);
              } else {
                return this.batches;
              }
            })
          );

          this.materialTypeService.findMaterialTypes(
            this.newDeliveryForm.get('powderName').value,
            'asc',
            0,
            10
            ).subscribe(res => {
              this.labCheckCycle = res[0].materialTypes[0].labCheckAtCycle;
            });
      } else {
        this.batches = [];
      }
    }
  }

  /**
   * This method parses the JSON response received after a call to newDelivery Service
   */
  parseResult(res: Object, isDuplicaBatch: boolean, newBatchColor: string , ND_Form: NgForm): void {
    const obj = JSON.parse(JSON.stringify(res));
    if (obj.isSuccess === true ) {
      obj.containers.forEach(element => {
        (element as IContainer).containerType = (element as IContainer).containerOrMachineName;
      });

      if (!isDuplicaBatch) {
        this.addNewBatch(newBatchColor);
      }
      ND_Form.resetForm();
      this.setDefaultValues();
      // this.SavedMessageModal();
      this.openQRPrintDialog(obj.containers);
    }
  }


  /**
   * This method opens the QR(s) Dialog, for print.
   */
  openQRPrintDialog(containers: IContainer[]): void {
    this.translate.get('Dialog.Title23').subscribe(value => this.Title = value);

    const formDialogData: QRCodeDialogData = new QRCodeDialogData();
    formDialogData.dialogTitle = this.Title;

    // formDialogData.dialogMsg = '';
    formDialogData.containers = containers;

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(QrcodegeneratorComponent, dialogConfig);
    this.openSnackBar('New delivery successfully created', 'New Delivery');

    dialogRef.afterClosed().subscribe(result => {
      console.log('form dialog result : ' + JSON.stringify(result));

      this.dialog.closeAll();
    });
  }

  SavedMessageModal() {
    this.translate.get('Dialog.Title24').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message24').subscribe(value => this.Mssg = value);
    this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
    this.translate.get('BTN.no_mdone_BTN').subscribe(value => this.NButton = value);

    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      data: {
        myaction: 'new-delivery',
        dialogTitle: this.Title,
        dialogMsg: this.Mssg,
        yes_text: this.YButton,
        no_text: this.NButton
      }
    });


  dialogRef.afterClosed().subscribe(result => {
    console.log('form dialog result : ' + JSON.stringify(result));

    this.dialog.closeAll();
    if (result.backScreen === 'start') {
    this.router.navigate(['/app/', 'powder-settings']);
    }
  });

  }

  addNewBatch(newBatchColor: string): void {
    const powderType: IPowderType = this.powders.find(powder => powder.powderName === this.newDeliveryForm.get('powderName').value);

    if (powderType) {
      powderType.batches === undefined ? powderType.batches = [] : powderType.batches.push({
        'batchName': this.newDeliveryForm.get('batchName').value,
        'batchCssColor': newBatchColor
      });
    }
  }


  /**
   * Container type selectioN listener
   * @param $event
   */
  onSelectContainerType($event): void {
    if ($event.isUserInput) {
      if ($event.source.value === 'Plastic') {
        this.showEmptyContainerWeight = false;
      } else {
        this.showEmptyContainerWeight = true;
      }
    }
  }
}
