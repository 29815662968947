import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { NewDeliveryService } from "./new-delivery.service";
import { INewDelivery } from "src/app/modals/INewDelivery";

@Injectable({
    providedIn: 'root'
})
export class NewDeliveryResolver implements Resolve<INewDelivery> {

    constructor(private newDeliveryService: NewDeliveryService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<INewDelivery> {
        return this.newDeliveryService.fetchSuppliersBatchAndPowderType();
    }
}
