import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MachineTypeService } from './machine-type.service';
import { IMachineType } from 'src/app/modals/IMachineType';
import { Router } from '@angular/router';
import { ComponentCommunicationService } from 'src/app/services/component-communication.service';
import { Subscription } from 'rxjs';
import { FileUploader, FileLikeObject, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-machine-type',
  templateUrl: './add-machine-type.component.html',
  styleUrls: ['./add-machine-type.component.css']
})

export class AddMachineTypeComponent implements OnInit, OnDestroy {

  /**
   * Back-end service URL
   */
  uri =  environment.apiUrl + 'api/uploadImage';

  @ViewChild('machineImage', { read: ElementRef })
  fileVariable: ElementRef;

  uploader: FileUploader;
  errorMessage: string;
  allowedMimeType = ['image/jpeg', 'image/jpg', 'image/png'];
  maxFileSize = 1 * 1024 * 1024;
  machinePicture: string;

  attachmentList: any = [];

  response: IMachineType;

  geometry: string;

  /**
   * Form group used for the form
   */
  machineTypeForm: FormGroup;

  capacityCalculationGroup: FormGroup;

  commSub: Subscription;

  addAndEditMachineTypeBtnTxt = 'Save';

  isAddMachineTypeScreen = true;

  _id: string;

  constructor(public formBuilder: FormBuilder,
    private machineTypeService: MachineTypeService,
    private snackBar: MatSnackBar,
    private router: Router,
    private compCommService: ComponentCommunicationService<IMachineType>,
    private authService: AuthService) {

    this.uploader = new FileUploader({
      url: this.uri,
      allowedMimeType: this.allowedMimeType,
      maxFileSize: this.maxFileSize // 1 MB
      //authToken: 'Bearer ' + authService.getToken()
    });

  /**
   * Set the Authorization Header
   * params for uploader
   */
    const authHeader: Array<{
      name: string;
      value: string;
    }> = [];
    authHeader.push({ name: 'Authorization', value: 'Bearer ' + authService.getToken() });
    const uploadOptions = <FileUploaderOptions>{ headers: authHeader };
    this.uploader.setOptions(uploadOptions);

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this.uploader.onAfterAddingFile = (item) => this.onAfterAddingFile(item);

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.attachmentList.push(JSON.parse(response));
      console.log(this.attachmentList[0].uploadname);
      this.machinePicture = this.attachmentList[0].uploadname;
      this.errorMessage = `Image Successfully Uploaded`;
    };
  }

  ngOnInit() {

    this.machineTypeForm = this.formBuilder.group({
      manufacturer: ['', [Validators.required]],
      model: ['', [Validators.required]],
      factor2: ['', [Validators.required]],
      geometry: [''],
      xDimension: [''],
      yDimension: [''],
      maxFeedPistonHeight: [''],
      diametre: [''],
      maxVolOfHopper: [''],
      numberOfLasers: [1, [Validators.required, Validators.min(1)]],
      maxLaserPowder: [1, [Validators.required, Validators.min(1)]],
      opticStyleLaser: ['', [Validators.required]],
      substrateTypeMaterial: ['', [Validators.required]],
      minSubstrateTemperature: [10, [Validators.required]],
      maxSubstrateTemperature: [40, [Validators.required]],
      isShaftExchangable: false,
      capacity: [{ value: 0, disabled: true }],
      noOfFeedPistons: [0],
      recoaterType1: ['', [Validators.required]],
      recoaterType2: [''],
      recoaterType3: [''],
      comment: [''],
      filename: this.attachmentList.length > 0 ? this.attachmentList[0].uploadname : ''
    });


    this.commSub = this.compCommService.getCommObject().subscribe(res => {
      if (res) {
        this.setFormData(res);
        this.isAddMachineTypeScreen = res.isAddMachineTypeScreen;
        this.addAndEditMachineTypeBtnTxt = 'Save';
      }
    });


    this.machineTypeForm.controls['maxVolOfHopper'].valueChanges.subscribe(value => {
        this.machineTypeForm.get('capacity').setValue(this.machineTypeForm.get('maxVolOfHopper').value);
    });

    this.machineTypeForm.controls['geometry'].valueChanges.subscribe(value => {
      this.resetFieldsByGeometry();
      this.calcMachineCapacity();
    });

    this.machineTypeForm.controls['xDimension'].valueChanges.subscribe(value => {
      this.calcMachineCapacity();
    });

    this.machineTypeForm.controls['yDimension'].valueChanges.subscribe(value => {
      this.calcMachineCapacity();
    });

    this.machineTypeForm.controls['maxFeedPistonHeight'].valueChanges.subscribe(value => {
      this.calcMachineCapacity();
    });

    this.machineTypeForm.controls['noOfFeedPistons'].valueChanges.subscribe(value => {
      this.calcMachineCapacity();
    });

    this.machineTypeForm.controls['diametre'].valueChanges.subscribe(value => {
      this.calcMachineCapacity();
    });

  }


  /**
   * Saving the new machine type
   */
  addNewMachineType(): void {

    const machineType: IMachineType = this.machineTypeForm.getRawValue() as IMachineType;

    if (this.isAddMachineTypeScreen) {
      this.machineTypeService
        .addNewMachineType(machineType, this.machinePicture)
        .subscribe(res => {
          this.parseResult(res);
        }, error => {
          this.errorUIUpdate(error);
        });
    } else {
      this.machineTypeService
        .editMachineType(machineType, this.machinePicture, this._id)
        .subscribe(res => {
          this.parseResult(res);
        }, error => {
          this.errorUIUpdate(error);
        });
    }

  }

  reset(): void {
    this.machineTypeForm.reset();
    this.cancel();
  }

  /**
   * Opens a toast / snackbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  viewMachines(): void {
    this.router.navigate(['/app/machines/', 'manage-machine-type']);
  }

  ngOnDestroy(): void {
    this.commSub.unsubscribe();
  }

  /**
   * In case the Machine Type gets edited or a copy of machine type
   * is requested by the user this form sets the data before hand.
   * @param res
   */
  setFormData(res: IMachineType): void {
    this.machineTypeForm.get('manufacturer').setValue(res.manufacturer);
    this.machineTypeForm.get('model').setValue(res.model);
    this.machineTypeForm.get('factor2').setValue(res.factor2);
    this.machineTypeForm.get('geometry').setValue(res.geometry);
    this.machineTypeForm.get('xDimension').setValue(res.xDimension);
    this.machineTypeForm.get('yDimension').setValue(res.yDimension);
    this.machineTypeForm.get('maxFeedPistonHeight').setValue(res.maxFeedPistonHeight);
    this.machineTypeForm.get('diametre').setValue(res.diametre);
    this.machineTypeForm.get('maxVolOfHopper').setValue(res.maxVolOfHopper);
    this.machineTypeForm.get('numberOfLasers').setValue(res.numberOfLasers);
    this.machineTypeForm.get('maxLaserPowder').setValue(res.maxLaserPowder);
    this.machineTypeForm.get('opticStyleLaser').setValue(res.opticStyleLaser);
    this.machineTypeForm.get('substrateTypeMaterial').setValue(res.substrateTypeMaterial);
    this.machineTypeForm.get('minSubstrateTemperature').setValue(res.minSubstrateTemperature);
    this.machineTypeForm.get('maxSubstrateTemperature').setValue(res.maxSubstrateTemperature);
    this.machineTypeForm.get('isShaftExchangable').setValue(res.isShaftExchangable);
    this.machineTypeForm.get('capacity').setValue(res.capacity);
    this.machineTypeForm.get('noOfFeedPistons').setValue(res.noOfFeedPistons);
    this.machineTypeForm.get('recoaterType1').setValue(res.recoaterType1);
    this.machineTypeForm.get('recoaterType2').setValue(res.recoaterType2);
    this.machineTypeForm.get('recoaterType3').setValue(res.recoaterType3);
    this.machineTypeForm.get('comment').setValue(res.comment);
    this.machinePicture = res.machinePicture;
    this._id = res._id;
  }

  /**
   * When User clicks the cancel button
   * we remove any error messages being displayed
   * on the form and also the last selected file
   * if any is present (we check this by uploader queue length).
   */
  cancel(): void {
    this.fileVariable.nativeElement.value = '';
    this.errorMessage = '';
    if (this.uploader.queue.length > 0) {
      this.uploader.queue[0].remove();
    }
  }

  /**
   * We trigger the upload method of the library
   * after checking the uploader queue length only
   * so that we avoid any index out of bounds exceptions
   */
  upload(): void {
    if (this.uploader.queue.length > 0) {
      this.uploader.queue[0].upload();
    }
  }

  /**
   * This method is called only when the item is
   * successfully added as opposed to onWhenAddingFileFailed
   */
  onAfterAddingFile(item: FileItem) {
    this.errorMessage = '';
  }

  /**
   *
   * @param item
   * @param filter
   * @param options
   *
   * In case the file is not uploaded due to the applied restrictions
   * on size or file type this method get called and we set the required
   * error message here which is then displayed to the user .
   */
  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        this.errorMessage = `Maximum upload size exceeded, Allowed
            ${(this.maxFileSize) / (1024 * 1024)} MB of
            ${(item.size / (1024 * 1024)).toFixed(2)} MB`;
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
        break;
      default:
        this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
  }

  /**
  * This method parses the JSON response received after a call to newDelivery Service
  */
  parseResult(res: Object): void {
    const obj = JSON.parse(JSON.stringify(res));
    if (obj.isSuccess === true) {
      if (obj.successMsg === 'Machine Type Edited') {
        this.openSnackBar('You have created a new Machine Type', 'Added Machine Type');
      } else if (obj.successMsg === 'Machine Type Created') {
        this.openSnackBar('You have updated the Machine Type', 'Edit Machine Type');
      }
      this.viewMachines();
    }
  }


  /**
   * This method handles the response json for a failure due to uniqueness
   * conflict for Model Number value after the response from
   * add machine type Service
   */
  private errorUIUpdate(error: Object) {
    const obj = JSON.parse(JSON.stringify(error));
    if (obj.isModel) {
      this.machineTypeForm.get('model').setErrors({ 'alreadyexists': true });
    }
  }

  /** Capacity of the Machine is calculated
   * as per the formulas
   */
  private calcMachineCapacity(): void {

    let totalCapacity = 0;

    if (this.machineTypeForm.get('geometry').value === 'rectangular') {
    totalCapacity = this.machineTypeForm.get('xDimension').value
    * this.machineTypeForm.get('yDimension').value
    * this.machineTypeForm.get('maxFeedPistonHeight').value
    * this.machineTypeForm.get('noOfFeedPistons').value;
    } else {
      totalCapacity = ( 3.14159 *
      this.machineTypeForm.get('diametre').value *
      this.machineTypeForm.get('diametre').value ) /
      4 * this.machineTypeForm.get('maxFeedPistonHeight').value
      * this.machineTypeForm.get('noOfFeedPistons').value;
    }
    this.machineTypeForm.get('capacity').setValue( Number(totalCapacity / 1000000).toFixed(2));
  }


/**
 * The fields are reset as the Geometry is changed
 */
  private resetFieldsByGeometry() {
    this.machineTypeForm.get('xDimension').setValue('');
    this.machineTypeForm.get('yDimension').setValue('');
    this.machineTypeForm.get('diametre').setValue('');
    this.machineTypeForm.get('maxFeedPistonHeight').setValue('');
    this.machineTypeForm.get('noOfFeedPistons').setValue(0);
  }


}
