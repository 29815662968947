import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatDialogConfig, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { NewDeliveryService } from '../../new-delivery/new-delivery.service';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { QRCodeDialogData } from 'src/app/shared/dialog/qrcodegenerator/QRCodeDialogData';
import { QrcodegeneratorComponent } from 'src/app/shared/dialog/qrcodegenerator/qrcodegenerator.component';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-container-new-delivery',
  templateUrl: './container-new-delivery.component.html',
  styleUrls: ['./container-new-delivery.component.css']
})
export class ContainerNewDeliveryComponent implements OnInit {
  Title: any;
  Mssg: any;

  constructor(private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private newDeliveryService: NewDeliveryService,
    public dialog: MatDialog,
    public translate: TranslateService) {

  }

  containerTypes: any[] = [
    {value: 'Metal-EOS'},
    {value: 'Metal-Trumpf'},
    {value: 'Plastic'},
    {value: 'Silo'}
  ];

  showEmptyContainerWeight = true;

  filteredSupplier: Observable<string[]>;

  /**
   * Form group used for the form
   */
  containerForm: FormGroup;

  commSub: Subscription;

  suppliers: string[];

  checked = false;

  ngOnInit() {
    this.suppliers = this.route.snapshot.data['suppliers'];

    this.containerForm = this.formBuilder.group({
      supplier: ['', [Validators.required]],
      capacity: [1, [Validators.required, Validators.min(1)]],
      containerEmptyWeight: [1, [Validators.required, Validators.min(1)]],
      containerType: [Validators.required],
      isHopper: [Validators.required]
    });
    this.containerForm.controls['containerType'].setValue(this.containerTypes[0].value);

    this.filteredSupplier = this.containerForm.get('supplier').valueChanges
      .pipe(
        startWith(''),
        map(supplier => supplier ? this._filteredSupplier(supplier) : this.suppliers)
      );
  }

  viewContainers(): void {
    this.router.navigate(['/app/containers']);
  }

  /**
   * Creating a new container delivery
   */
  addNewContainer(): void {
    const container = {} as IContainer;

    container.containerOrMachineName = '';
    container.isSeived = false;
    container.isMachine = false;
    container.containerType = this.containerForm.value.containerType === 'Plastic' ? 'P' : 'M';
    container.containerType =
      this.containerForm.value.containerType === 'Plastic' ? 'P' :
      this.containerForm.value.containerType === 'Metal-EOS' ? 'M' :
      this.containerForm.value.containerType === 'Metal-Trumpf' ? 'T' : 'S';
    container.mass = 0;
    container.lastSeiveCycle = 0;
    container.supplierName = this.containerForm.value.supplier;
    container.capacity = this.containerForm.value.capacity;
    container.containerEmptyWeight =
      this.containerForm.value.containerType === 'Plastic' ? 0 : this.containerForm.value.containerEmptyWeight;
    container.bookedForSource = false;
    container.bookedForDest = false;
    container.selectionTimestamp = '0';
    container.selectedByUserId = null;
    container.powderName = '';
    container.powderCssColor = '';
    container.batchCssColor = '';
    container.batchName = '';
    container.isHopper = this.checked;

    const duplicateSupplier: string = this.suppliers
    .find(sup => sup === this.containerForm.get('supplier').value);

    if (!duplicateSupplier) {
      this.suppliers.push(this.containerForm.get('supplier').value);
    }

    this.newDeliveryService
    .createNewDelivery(container, 1, duplicateSupplier ? false : true, false)
    .subscribe(res => {
      this.parseResult(res);
      this.openSnackBar('New Container Created', 'New Delivery');
      this.reset();
    });
  }

  reset(): void {
    this.containerForm.reset();
    this.containerForm.get('containerType').setValue('Metal');
    this.containerForm.get('containerEmptyWeight').setValue(1);
  }

  /**
   * Opens a toast / snacbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  /**
   * This method parses the JSON response received after a call to newDelivery Service
   */
  parseResult(res: Object): void {

    const obj = JSON.parse(JSON.stringify(res));

    if (obj.isSuccess === true) {

      obj.containers.forEach(element => {

        (element as IContainer).containerType = (element as IContainer).containerOrMachineName;

      });

      this.reset();

      this.openQRPrintDialog(obj.containers);
    }

  }

  /**
  * This method opens the QR(s) Dialog, for print.
  */
  openQRPrintDialog(containers: IContainer[]): void {
    this.translate.get('Dialog.Title21').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message21').subscribe(value => this.Mssg = value);
    const formDialogData: QRCodeDialogData = new QRCodeDialogData();
    formDialogData.dialogTitle = this.Title;
    formDialogData.dialogMsg = this.Mssg;
    formDialogData.containers = containers;

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(QrcodegeneratorComponent, dialogConfig);
    this.openSnackBar('New delivery successfully created', 'New Delivery');

    dialogRef.afterClosed().subscribe(result => {
      console.log('form dialog result : ' + JSON.stringify(result));

      this.dialog.closeAll();
    });
  }

  private _filteredSupplier(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.suppliers.filter(supplier => supplier.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Container type selectioN listener
   * @param $event
   */
  onSelectContainerType($event): void {
    if ($event.isUserInput) {
      if ($event.source.value === 'Plastic') {
        this.showEmptyContainerWeight = false;
      } else {
        this.showEmptyContainerWeight = true;
      }
    }
  }

}
