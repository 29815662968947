import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { INewDelivery } from "src/app/modals/INewDelivery";
import { PowderPickerService } from "src/app/shared/powderpicker/powder-picker-service";

@Injectable({
    providedIn: 'root'
})
export class ContainerNewDeliveryResolver implements Resolve<string[]> {

    constructor(private powderPickerService: PowderPickerService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<string[]> {
        return this.powderPickerService.getSuppliers();
    }
}
