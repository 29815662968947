import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // const authToken = this.authService.getToken();
    // const authRequest = req.clone({
    //   headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    // });
    // return next.handle(authRequest);
    const authToken = this.authService.getToken();
    if (!req.url.includes('token')) {
      const authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
      return next.handle(authRequest);
    } else {
      return next.handle(req);
    }
  }

}
