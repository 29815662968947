import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { MachineTypeDataSource } from './machine-type-datasource';
import { MachineTypeService } from '../add-machine-type/machine-type.service';
import { Router } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ComponentCommunicationService } from 'src/app/services/component-communication.service';
import { IMachineType } from 'src/app/modals/IMachineType';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-machine-type',
  templateUrl: './list-machine-type.component.html',
  styleUrls: ['./list-machine-type.component.css']
})
export class ListMachineTypeComponent implements OnInit, AfterViewInit {

  dataSource: MachineTypeDataSource;
  displayedColumns: string[];
  searchString : string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('input') input: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  btn: any;

  constructor(private machineTypeService: MachineTypeService,
              private router: Router,
              public translate: TranslateService,
              private compCommService: ComponentCommunicationService<IMachineType>) { }

  ngOnInit() {
    this.displayedColumns = ['machinePicture', 'manufacturer', 'model', 'capacity', 'substrateTypeMaterial', 'comment',  'edit', 'copy'];
    this.dataSource = new MachineTypeDataSource(this.machineTypeService);
    this.dataSource.loadMachineTyes('', 'asc', 0, 10);
  }

  ngAfterViewInit(): void {

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

      fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadMachineTypePage();
        })
      )
      .subscribe();


    merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => this.loadMachineTypePage())
      )
      .subscribe();
  }

  loadMachineTypePage() {
    this.searchString = this.input.nativeElement.value;

    this.dataSource.loadMachineTyes(
      this.searchString,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  addMachineType(): void {
    this.compCommService.setCommObject(null);
    this.router.navigate(['/app/machines/', 'add-machine-type']);
  }

  editMachineType(selectedMachineType: IMachineType): void {
    selectedMachineType.isAddMachineTypeScreen = false;
    this.compCommService.setCommObject(selectedMachineType);
    this.router.navigate(['/app/machines/', 'add-machine-type']);
  }

  copyMachineType(selectedMachineType: IMachineType): void {
    selectedMachineType.isAddMachineTypeScreen = true;
    this.compCommService.setCommObject(selectedMachineType);
    this.router.navigate(['/app/machines/', 'add-machine-type']);
  }

  checkBTN() {
    this.translate.get('BTN.add_matrl_typ_BTN').subscribe(value => this.btn = value);
  }
}
