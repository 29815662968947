import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, OnInit} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { IBatchNode } from './IBatchNode';
import { IBatchHistoryDetails } from './IBatchHistoryDetail';

/**
 * @title Tree with nested nodes
 */
@Component({
  selector: 'app-batch-history',
  templateUrl: './batch-history.component.html',
  styleUrls: ['./batch-history.component.css'],
})
export class BatchHistoryComponent implements OnInit {

  batchHistoryDetail: IBatchHistoryDetails;
  treeControl = new NestedTreeControl<IBatchNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<IBatchNode>();

  constructor(private route: ActivatedRoute) {
    this.dataSource.data = this.route.snapshot.data['batchHistoryData'];
    this.treeControl.dataNodes = this.route.snapshot.data['batchHistoryData'];
  }

  hasChild = (_: number, node: IBatchNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.batchHistoryDetail = this.route.snapshot.data['batchHistoryData'];

    console.log(this.batchHistoryDetail);

  }

}

