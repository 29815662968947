import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'pm-app',
  templateUrl: './pm.app.component.html',
  styleUrls: ['./pm.app.component.css']
})
export class PMAppComponent implements OnInit {

  userIsAuthorized: boolean = false;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.getAuthStatusListener()
      .subscribe(isAuthorizedUser => {
        this.userIsAuthorized = isAuthorizedUser;
      });
  }
}
