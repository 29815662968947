export enum Permissions {
  _1 = 'powder-settings',
  _2 = 'containers',
  _3 = 'manage-material',
  _4 = 'machines',
  _5 = 'new-delivery',
  _6 = 'powder-overview',
  _7 = 'load-machine-source',
  _8 = 'unload-machine',
  _9 = 'sieve-source',
  _10 = 'mix-batch-source',
  _11 = 'change-properties',
  _12 = 'batch-history',
  _13 = 'unload-polluted-powder',
  _14 = 'unload-unused-powder',
  _15 = 'complete-unload',
  _16 = 'add-container',
  _17 = 'scrap-powder-btn',
  _18 = 'scrap-container-btn',
  _19 = 'add-material-type',
  _20 = 'edit-material-type-btn',
  _21 = 'add-machine-to-park',
  _22 = 'manage-machine-type',
  _23 = 'add-machine-to-park-btn',
  _24 = 'edit-Machine-park-btn',
  _25 = 'add-machine-type',
  _26 = 'edit-machine-type-btn',
  _27 = 'copy-machine-type-btn'
}
