export class IScreens {
    
    /**
     * constants used as an identifier for Powder Management Screens
     */
    public static LOAD_MACHINE_SCREEN: string = "loadMachineScreen";
    public static UNLOAD_UNUSED_POWDER_SCREEN: string = "unloadUnusedPowderScreen";
    public static SIEVE_SCREEN: string = "sieveScreen";
    public static MIX_BATCH_SCREEN: string = "mixBatchScreen";
    public static UNLOAD_COMPLETE_POWDER_SCREEN: string = "unloadCompletePowderScreen";
    public static UNLOAD_POLLUTED_POWDER_SCREEN: string = "unloadPollutedPowderScreen";

    private screenMap: Map<number, string>;
    private screenPosition: number = 0;

    constructor() {
        this.screenMap = new Map();
        
        this.screenMap.set(1, IScreens.LOAD_MACHINE_SCREEN);
        this.screenMap.set(2, IScreens.UNLOAD_UNUSED_POWDER_SCREEN);
        this.screenMap.set(3, IScreens.SIEVE_SCREEN);
        this.screenMap.set(4, IScreens.MIX_BATCH_SCREEN);
        this.screenMap.set(5, IScreens.UNLOAD_COMPLETE_POWDER_SCREEN);
        this.screenMap.set(6, IScreens.UNLOAD_POLLUTED_POWDER_SCREEN);
        
    }

    /**
     * Gets the current screen
     */
    public getCurrentScreen(): string {
        return this.screenMap.get(this.screenPosition);
    }

    /**
     * Sets Load Machine Screen
     */
    public setCurrentScreenAsLoadMachine(): void {
        this.screenPosition = 1;
    }

    /**
     * Sets Unload Machine Unused Powder  Screen
     */
    public setCurrentScreenAsUnloadUnusedPowderScreen(): void {
        this.screenPosition = 2;
    }
    
    /**
     * Sets Sieve Screen
     */
    public setCurrentScreenAsSieve(): void {
        this.screenPosition = 3;
    }

    /**
     * Sets Load Machine Screen
     */
    public setCurrentScreenAsMixBatch(): void {
        this.screenPosition = 4;
    }

     /**
     * Sets Unload Machine Complete Powder  Screen
     */
    public setCurrentScreenAsUnloadCompletePowderScreen(): void {
        this.screenPosition = 5;
    }

    /**
     * Sets Unload Machine Polluted Powder  Screen
     */
    public setCurrentScreenAsUnloadPollutedPowderScreen(): void {
        this.screenPosition = 6;
    }
}