import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPowderType } from 'src/app/modals/IPowderType';
import { map } from 'rxjs/operators';

/**
 * Handles the services to fetch Powder data from the backend
 */
@Injectable({
  providedIn: 'root'
})
export class MaterialTypeService {

  /**
   * Back-end service URL
   */
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '3200/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'material-apis';
    }
  }


  /**
   * Creating a new material type
   */
  addNewMaterialType(materialType: IPowderType): Observable<Object> {
    const path = this.STAGE + '/material-type';
    const endpoint = this.API_ROOT + path;

    return this.http.post(endpoint, {
      materialTypeData: materialType
    });
  }


  /**
   * Editing a material type
   */
  editMaterialType(materialType: IPowderType, oldPowderName: string, powderId: string): Observable<Object> {
    const path = this.STAGE + '/material-type';
    const endpoint = this.API_ROOT + path;

    return this.http.put(endpoint, {
      materialTypeData: materialType,
      oldPowderName: oldPowderName,
      _id: powderId
    });
  }

  /**
   * Finding the material types based on certain parameters
   */
  findMaterialTypes(
    search: string, sortOrder: string,
    pageNumber: number, pageSize: number):  Observable<IPowderType> {

    const path = this.STAGE + '/material-types';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
        params: new HttpParams()
            .set('search', search)
            .set('sortOrder', sortOrder)
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
    }).pipe(
        map(res =>  res['materialTypeDetails'])
    );
  }

}
