import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { MachineParkService } from './machine-park.service';

export class MachineParkListDataSource implements DataSource<IContainer> {

  private machineParkSubject = new BehaviorSubject<IContainer[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private noOfMachinesInParkSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public noOfMachineTypes$ = this.noOfMachinesInParkSubject.asObservable();


  constructor(private machineParkService: MachineParkService) { }

  connect(collectionViewer: CollectionViewer): Observable<IContainer[]> {
    return this.machineParkSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.machineParkSubject.complete();
    this.noOfMachinesInParkSubject.complete();
    this.loadingSubject.complete();
  }

  loadMachinesInPark(
    search: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number) {

    this.loadingSubject.next(true);

    this.machineParkService.findMachinesInPark(search, sortDirection,pageIndex, pageSize).
      pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(machineDetails => {
        this.machineParkSubject.next(machineDetails[0].machineTypes);
        this.noOfMachinesInParkSubject.next(machineDetails[1].machineTypeLength);
      });
  }

}

