import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

/**
 * Service handling communication between different components
 */
@Injectable({
    providedIn: 'root'
})
export class ComponentCommunicationService<T> {

    private communicationSubject: BehaviorSubject<T> = new BehaviorSubject(null);

    /**
     * Emits the selected object for communication
     *
     * @param object object used for communication
     */
    setCommObject(object: T): void {
        this.communicationSubject.next(object);
    }

    /**
     * Observable to subscribe to get the communicated object
     */
    getCommObject(): Observable<T> {
        return this.communicationSubject.asObservable();
    }
}

