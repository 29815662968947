import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MaterialTypeDataSource } from './material-type-datasource';
import { MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MaterialTypeService } from '../add-material-type/material-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCommunicationService } from 'src/app/services/component-communication.service';
import { IPowderType } from 'src/app/modals/IPowderType';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-list-material-type',
  templateUrl: './list-material-type.component.html',
  styleUrls: ['./list-material-type.component.css']
})
export class ListMaterialTypeComponent implements OnInit, AfterViewInit {

  dataSource: MaterialTypeDataSource;
  displayedColumns: string[];
  searchString : string = '';
  restrictions = ['add-material-type', 'edit-material-type-btn'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('input') input: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  btn: any;

  constructor(private materialTypeService: MaterialTypeService,
    private router: Router,
    public translate: TranslateService,
    private compCommService: ComponentCommunicationService<IPowderType>,
    private route: ActivatedRoute,
    public authService: AuthService
    ) {}

  ngOnInit() {
    console.log(this.route.snapshot.data);
    this.displayedColumns = ['alloy', 'density', 'labCheckAtCycle', 'comment'];
    const isTrue = this.authService.checkRestrictions(this.restrictions[1]);
    if (isTrue) {
      this.displayedColumns.push('edit');
    }
    this.dataSource = new MaterialTypeDataSource(this.materialTypeService);
    this.dataSource.loadMaterialTyes('', 'asc', 0, 10);
  }

  ngAfterViewInit(): void {

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

      fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadMaterialTypePage();
        })
      )
      .subscribe();


    merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => this.loadMaterialTypePage())
      )
      .subscribe();
  }

  loadMaterialTypePage() {
    this.searchString = this.input.nativeElement.value;

    this.dataSource.loadMaterialTyes(
      this.searchString,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  addMaterialType(): void {
    this.compCommService.setCommObject(null);
    this.router.navigate(['/app/', 'add-material-type']);
  }

  editMaterialType(selectedMaterialType: IPowderType): void {
    this.compCommService.setCommObject(selectedMaterialType);
    this.router.navigate(['/app/', 'add-material-type']);
  }

  checkBTN() {
    this.translate.get('BTN.add_matrl_typ_BTN').subscribe(value => this.btn = value);
  }
}
