import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-unused-powder-target',
  templateUrl: './unused-powder-target.component.html',
  styleUrls: ['./unused-powder-target.component.css']
})
export class UnusedPowderTargetComponent implements OnInit {
    
  constructor() { }
  
  ngOnInit() {
  } 
}