import { AuthService } from 'src/app/services/auth.service';
import { DataSource } from '@angular/cdk/table';
import { IContainer } from './IContainer';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { PowderPickerService } from './powder-picker-service';
import { catchError, finalize } from 'rxjs/operators';
import { DataSourceHelper } from './DataSourceHelper';
import { DataSourceMovementHelper } from './DataSourceMovementHelper';
import { IScreens } from './IScreens';
import { IPowderType } from 'src/app/modals/IPowderType';

export class PowderPickerDataSource implements DataSource<IContainer> {

  private containersSubject = new BehaviorSubject<IContainer[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private noOfContainersSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public noOfContainers$ = this.noOfContainersSubject.asObservable();

  materialTypes: Array<IPowderType>;
  /**
   * data source helper instance
   */
  private dataSourceHelper: DataSourceHelper;
  /**
   * data source movement helper instance
   */
  private dataSourceMovementHelper: DataSourceMovementHelper;

  constructor(
    private powderPickerService: PowderPickerService,
    private authService: AuthService,
    ) {


  }

  connect(collectionViewer: CollectionViewer): Observable<IContainer[]> {
    this.dataSourceHelper = new DataSourceHelper( this.authService, this.containersSubject);
    this.dataSourceMovementHelper = new DataSourceMovementHelper(this.containersSubject);
    return this.containersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.containersSubject.complete();
    this.noOfContainersSubject.complete();
    this.loadingSubject.complete();
  }

  loadContainers(
    search: string,
    sortDirection: string,
    activeSortName: string,
    filter: Map<string, string>,
    pageIndex: number,
    pageSize: number,
    isSource: boolean,
    isSieveScreen: boolean,
    isMixBatchScreen: boolean,
    isLoadMachineScreen: boolean,
    isUnloadUnusedPowder: boolean,
    isUnloadCompletePowder: boolean,
    isUnloadPollutedPowder: boolean,
    isPowderMovementScreen: boolean,
    isListContainerScreen: boolean,
    isPowderOverviewScreen: boolean,
    materialTypes: IPowderType[]) {

    this.materialTypes = materialTypes;

    this.loadingSubject.next(true);

    if (isPowderMovementScreen) {
      // Powder movement last screen

      this.powderPickerService.getSelectedContainers(pageIndex, pageSize,
        isSource, isUnloadPollutedPowder).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)))
        .subscribe(containerDetail => {

          const containers: IContainer[] = containerDetail[0].containers;

          this.updateIsCurrentUserInPossession(containers);

            containers.forEach(container => {
              container.alloyDensity = containerDetail[1].alloyDensity;
              container.granularityFactor = containerDetail[1].granularityFactor;
            });

          this.dataSourceMovementHelper.unselectContainers(containers);
          this.noOfContainersSubject.next(containerDetail[1].containersLength);
        });
    } else {

      // Powder movement source / destination screens

      this.powderPickerService.findContainers(search, sortDirection, activeSortName,
        filter, pageIndex, pageSize, isSource, isSieveScreen,
        isMixBatchScreen, isLoadMachineScreen, isUnloadUnusedPowder,
        isUnloadCompletePowder, isUnloadPollutedPowder, isListContainerScreen,
        isPowderOverviewScreen).pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(containerDetail => {

          if (containerDetail[0] !== undefined) {

            const containers: IContainer[] = containerDetail[0].containers;
            this.updateIsCurrentUserInPossession(containers);

            containers.forEach(container => {
              container.alloyDensity = containerDetail[1].alloyDensity;
              container.granularityFactor = containerDetail[1].granularityFactor;
            });

            this.dataSourceHelper.addToTemporaryContainerSubject(containers, isSource);
            this.noOfContainersSubject.next(containerDetail[1].containersLength);
          }

        });
    }
  }

  /**
   * Modifies the temporary containers list for a
   * specific container identified by container name with selection of the checkbox
   *
   * @param selectedContainer selected container
   */
  public modifyTemporaryContainerForSelection(selectedContainer: IContainer,
    checked: boolean, isSource: boolean, isPowderMovementScreen: boolean,
    userId: string, pmScreens: IScreens): void {
    if (isPowderMovementScreen) {
      this.dataSourceMovementHelper.modifyTheContainerOnSelection(selectedContainer, checked, isSource, pmScreens);
    } else {
      this.dataSourceHelper.modifyTemporaryContainerForSelection(selectedContainer, isSource, checked, userId);
    }
  }

  /**
   * Gets the selected containers from the temporary containers
   *
   * @param isSource identifier between source and destination
   */
  public getSelectedContainers(isSource: boolean): IContainer[] {
    return this.dataSourceHelper.getSelectedContainers(isSource);
  }

  /**
   * Gets the selected containers from the temporary containers
   *
   * @param isSource identifier between source and destination
   */
  public getTemporaryContainers(): IContainer[] {
    return this.dataSourceHelper.getTemporaryContainers();
  }

   /**
   * Gets the selected containers from the source / destination containers
   */
  public getSelectedContainersForMovement(): IContainer[] {
    return this.dataSourceMovementHelper.getSelectedContainers();
  }

  public checkDestFormState(): boolean {
    return this.dataSourceHelper.checkDestFormState();
  }

  /**
   * Removing the containers / machines with mass 0 from the source
   */
  public removeSrcEmptyContainerAndMachines(): void {
    this.dataSourceHelper.removeSrcEmptyContainerAndMachines();
    this.dataSourceMovementHelper.removeSrcEmptyContainerAndMachines();

  }

  /**
   * get Source containers / machines
   */
  public getSrcContainerAndMachines() {
    return this.dataSourceMovementHelper.getSrcContainerAndMachines();
  }

   /**
   * Removing the selected containers
   */
  public removeSelectedContainers(containerNames: string[]): void {
    this.dataSourceHelper.removeSelectedContainers(containerNames);
  }

  /**
   * Scrapping the powder for the selected containers
   */
  public scrapPowderForSelectedContainers(containerNames: string[]): void {
    this.dataSourceHelper.scrapPowderForSelectedContainers(containerNames);
  }

  private setDisplayName(containers: IContainer[]): void {

    containers.forEach(ctr => {
     if (ctr.isMachine) {
       ctr.displayName = ctr.machineDetails.serialNumber + ' / ' + ctr.mData.model;
     } else {
       ctr.displayName = ctr.containerOrMachineName;
     }
    });
  }

  private updateIsCurrentUserInPossession(containers: IContainer[]) {

    let userId: string = this.authService.getUserId();

    containers.forEach(container => {
      if (container.selectedByUserId === '' || container.selectedByUserId === null || container.selectedByUserId === undefined ) {
        container.isCurrentUserInPossession = true;
      } else if (userId === container.selectedByUserId) {
        container.isCurrentUserInPossession = true;
      } else {
        container.isCurrentUserInPossession = false;
      }

      if (container.lastLabCheckAtCycle === undefined) {
        if (container.powderName !== '') {
          const index = this.materialTypes.findIndex(material => material.powderName === container.powderName);
          container.lastLabCheckAtCycle = 0;
          container.labCheckAtCycle = this.materialTypes[index].labCheckAtCycle;
        }
      } else {
        if (container.powderName !== '') {
          const index = this.materialTypes.findIndex(material => material.powderName === container.powderName);
          container.labCheckAtCycle = this.materialTypes[index].labCheckAtCycle;
        }
      }

    });

  }

}

