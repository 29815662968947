import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-load-machine-target',
  templateUrl: './load-machine-target.component.html',
  styleUrls: ['./load-machine-target.component.css']
})
export class LoadMachineTargetComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
