import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IContainerDetail } from 'src/app/shared/powderpicker/IContainerDetail';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';

@Injectable({ providedIn: 'root' })
export class ScannerService {

  /**
   * Back-end service URL
   */
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '5200/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'container-apis';
    }
  }

  private qrResponseSource = new BehaviorSubject('qr-response-source');
  currentQRResponse = this.qrResponseSource.asObservable();

  changeQRResponse(qrResultString: string) {
    this.qrResponseSource.next(qrResultString);
  }

  /**
   * Finding the details w.r.t the containerOrMachineName passed
   * @param containerOrMachineName
   */
  findContainerDetails(
    containerOrMachineName: string):  Observable<IContainer> {

    const path = this.STAGE + '/containersViaQR';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
        params: new HttpParams()
            .set('search', containerOrMachineName)
    }).pipe(map(res =>  res['containerDetail']));
  }
}
