import { IContainer } from '../../powderpicker/IContainer';
import { IModelData } from 'src/app/modals/IModelData';
import { IPowderType } from 'src/app/modals/IPowderType';
import { IBatch } from 'src/app/modals/IBatch';

export class CommonFormDialogData {
    dialogTitle: string;
    dialogMsg: string;
    containerData: IContainer;
    isChangePropForm: boolean;
    isChangeContainerPropForm: boolean;
    isSieveForm: boolean;
    isUnloadCompletePowderForm: boolean;
    isUnloadUnusedPowderForm: boolean;
    isUnloadPollutedPowderForm: boolean;
    sourceMass: number;
    sourceCapacityKg: number;
    sourceEmptyContainerWeight?: number;
    destinationMass: number;
    destinationCapacity: number;
    destinationCapacityKg: number;
    sourceCapacity: number;
    isSourceMassDisabled: boolean;
    userInputTitle: string;
    noOfFeedPistons: number;
    powderTypes: IPowderType[];
}
