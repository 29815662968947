import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { IModelData } from 'src/app/modals/IModelData';
import { MachineParkService } from './machine-park.service';

@Injectable({
    providedIn: 'root'
})
export class MachineParkModelsResolver implements Resolve<IModelData[]> {

    constructor(private machineParkService: MachineParkService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<IModelData[]> {
        return this.machineParkService.getModels();
    }
}
