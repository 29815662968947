import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonModalComponent } from '../shared/dialog/common-modal.component';
import { PowderPickerService } from '../shared/powderpicker/powder-picker-service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
// import Transaction from './../models/Transaction';

@Injectable({ providedIn: 'root' })
export class TimerService {

  notifyUserOnSecondsLeft = 480;
  timerDuration = 600;
  incrementingTime = 0;
  interval;
  isTimerRunning = false;
  Title: any;
  Mssg: any;
  YButton: any;
  NButton: any;

  constructor(public dialog: MatDialog,
    private powderPickerService: PowderPickerService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    public translate: TranslateService) {

  }

  startTimer(delay: number) {

    this.incrementingTime = delay;

    this.interval = setInterval(() => {

      this.isTimerRunning = true;

      console.log(this.incrementingTime);

      if (this.incrementingTime === this.notifyUserOnSecondsLeft) {
        // Trigger Dialog when 20 Seconds are left

      this.translate.get('Dialog.Title19').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message19').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
      this.translate.get('BTN.no_mdone_BTN').subscribe(value => this.NButton = value);

        const dialogRef = this.dialog.open(CommonModalComponent, {
          data: {
            myaction: 'no_source',
            dialogTitle: this.Title,
            dialogMsg: this.Mssg,
            yes_text: this.YButton,
            no_text: this.NButton
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          // console.log('form dialog result : ' + JSON.stringify(result));

          this.dialog.closeAll();
          if (result.backScreen === 'start') {
            console.log('result.backScreen ========', result.backScreen);
            this.stopTimer();

            // reset all the params
            this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe(res => {

              let obj = JSON.parse(JSON.stringify(res));

              if (obj.isSuccess === true ) {
                this.stopTimer();
                this.router.navigate(['/app/', 'powder-settings']);
              } else {
                console.log('no reset needed');
                this.stopTimer();
                // show error notification to the user
              }
            });

            // this.stopTimer();
          } else if (result.backScreen === 'noSource') {
              console.log('result.backScreen ========', result.backScreen);
              this.powderPickerService.updateTransactionTimestamp(this.authService.getUserId()).subscribe(res =>{

              let newobj = JSON.parse(JSON.stringify(res));

              if (newobj.isSuccess === true ) {
                this.incrementingTime = 0;
                console.log('timestamp updated at the backend');
              } else {
                console.log('timestamp can not be updated at the backend');
                // show error notification to the user
              }

            });

          }
        });

      } else if ( this.incrementingTime === this.timerDuration) {
        // Trigger Dialog when user doesn't respond to the first dialog
        // this.stopTimer();
        this.dialog.closeAll();
        this.stopTimer();
        this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe(res => {

                let obj = JSON.parse(JSON.stringify(res));

                if (obj.isSuccess === true ) {
                  this.stopTimer();
                  this.router.navigate(['/app/', 'powder-settings']);
                } else {
                  // show error notification to the user
                }
              });

        this.openSnackBar('Due to inactivty the transaction was discarded !! Press Ok to Continue.' , 'OK');
        // const dialogRef = this.dialog.open(CommonModalComponent, {
        //   data: {
        //     myaction: 'discard_pwm_process',
        //     dialogTitle: 'OOPS Transaction Discarded!!',
        //     dialogMsg: 'Due to inactivty the transaction was discarded !! Press Ok to Continue.',
        //     yes_text: 'OK',
        //     no_text: ''
        //   }
        // });

        // dialogRef.afterClosed().subscribe(result => {

        //   this.dialog.closeAll();
        //   this.openSnackBar('Due to inactivty the transaction was discarded !! Press Ok to Continue.', 'OK');
        //   if (result.backScreen === 'start') {
        //     this.stopTimer();
        //     // reset all the params
        //     this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe(res => {

        //       let obj = JSON.parse(JSON.stringify(res));

        //       if (obj.isSuccess === true ) {
        //         this.stopTimer();
        //         this.router.navigate(['/app/', 'powder-settings']);
        //       } else {
        //         // show error notification to the user
        //       }
        //     });

        //   }
        //   this.stopTimer();
        // });

      } else if ( this.incrementingTime > this.timerDuration) {
        this.stopTimer();
      }

      this.incrementingTime++;

    }, 1000);

  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      verticalPosition: 'top'
    });
  }

  stopTimer() {
    clearInterval(this.interval);
    this.incrementingTime = 0;
    this.isTimerRunning = false;
  }

}


