import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Form, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonFormDialogData } from './CommonFormDialogData';
import { FormValidators } from './formValidators';
import { INewDelivery } from 'src/app/modals/INewDelivery';
import { IPowderType } from 'src/app/modals/IPowderType';
import { ActivatedRoute } from '@angular/router';
import { IBatch } from 'src/app/modals/IBatch';
import { Observable } from 'rxjs';

/**
 * Responsible to handle common form dialog
 */
@Component({
  selector: 'app-common-form-dialog',
  templateUrl: './common-form-dialog.component.html',
  styleUrls: ['./common-form-dialog.component.css']
})
export class CommonFormDialogComponent implements OnInit {

  /**
   * Based on user's checbox selection we show-hide
   * input field of cycle (used as an ngModel)
  */
  showSieveCycle = false;

  /**
   * Form group used for the form
   */
  dialogForm: FormGroup;

  /**
   * Dialog Title
   */
  dialogTitle: string;

  /**
   * Dialog Message
   */
  dialogMsg: string;

  userInputTitle: string;

  noOfFeedPistons: number;

  sourceMassControl: FormControl = new FormControl();

  powders: IPowderType[] = [];
  batches: IBatch[] = [];

  lastLabCheck: number;

  constructor(private route: ActivatedRoute, public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CommonFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public commonFormDialogData: CommonFormDialogData) {
    // to pass the dialog title and other fields to the dialog that opens
    this.dialogTitle = commonFormDialogData.dialogTitle;
    this.dialogMsg = commonFormDialogData.dialogMsg;
    this.userInputTitle = commonFormDialogData.userInputTitle;
    this.noOfFeedPistons = commonFormDialogData.noOfFeedPistons;
    this.powders = commonFormDialogData.powderTypes;
    this.generateDialogForm(commonFormDialogData);
  }

  ngOnInit() {

  }

  // As per PM-239 to provide the Shortlisted list of batches as per the powderType selected
  onSelectPowderType($event): void {
    if ($event.isUserInput) {
      console.log('selected powder type : ' + $event.source.value);
      const powderType = this.powders.find(powder => powder.powderName === $event.source.value);

      if (powderType !== null) {
        this.batches = powderType.batches.filter(batch => (batch.batchName !== ''));
            } else {
        this.batches = [];
      }
    }
  }

  save(): void {
    this.dialogRef.close(this.dialogForm.value);
  }

  close(): void {
    this.dialogRef.close();
  }

  /**
   * Generates the dialogForm based on CommonFormDialogData
   * @param commonFormDialogData
   */
  generateDialogForm(commonFormDialogData: CommonFormDialogData): void {
    commonFormDialogData.containerData.mass = Math.round(commonFormDialogData.containerData.mass * 100) / 100;
    if (commonFormDialogData.isSieveForm ||
      commonFormDialogData.isUnloadCompletePowderForm ||
      commonFormDialogData.isUnloadUnusedPowderForm) {
      this.dialogForm = this.formBuilder.group({
        sourceMass: [commonFormDialogData.sourceMass, [Validators.required]],
        destinationMass: [commonFormDialogData.destinationMass, [Validators.required]],
        isMachineEmpty: [false]
      }, {
        validator: FormValidators.sourceDestinationComparision(commonFormDialogData)
      });
    }
    // to differentiate the fields to be shown or hidden in the ChangePropertiesComponent edit dialog
    else if (commonFormDialogData.isChangePropForm) {
      this.lastLabCheck = commonFormDialogData.containerData.lastLabCheckAtCycle;
      this.dialogForm = this.formBuilder.group({
        isSieved: [commonFormDialogData.containerData.isSeived],
        cycle: [commonFormDialogData.containerData.lastSeiveCycle, [Validators.required, Validators.min(0)]],
        mass: [commonFormDialogData.containerData.mass, [Validators.required]],
        powderName: [commonFormDialogData.containerData.powderName],
        batchName: [commonFormDialogData.containerData.batchName],
        labCheck: [false]
      });

    }
    // to differentiate the fields to be shown or hidden in the ListContainersComponent edit dialog
    else if (commonFormDialogData.isChangeContainerPropForm) {
      this.dialogForm = this.formBuilder.group({
        weight: [commonFormDialogData.containerData.containerEmptyWeight, [Validators.required]]
      });

    } else if (commonFormDialogData.isUnloadPollutedPowderForm) {
      this.dialogForm = this.formBuilder.group({
        sourceMass: [commonFormDialogData.sourceMass],
        destinationMass: [commonFormDialogData.destinationMass, [Validators.required]],
        isMachineEmpty: [true],
        zact1: [1, Validators.required],
        zact2: [1, Validators.required],
        hopperLoad: [1, Validators.required]
      }, {
        validator: FormValidators.sourceDestinationComparision(commonFormDialogData)
      });
    }
  }
}
