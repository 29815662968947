import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unused-powder-source',
  templateUrl: './unused-powder-source.component.html',
  styleUrls: ['./unused-powder-source.component.css']
})
export class UnusedPowderSourceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
