import { Component, OnInit, ViewChild, ViewContainerRef, Inject, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { PowderPickerComponent } from 'src/app/shared/powderpicker/powder-picker.component';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { PowderPickerService } from 'src/app/shared/powderpicker/powder-picker-service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModalComponent } from 'src/app/shared/dialog/common-modal.component';
import { MatDialog} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.css']
})
export class TargetComponent implements OnInit, AfterViewInit, OnDestroy {


  yes_text: string;
  no_text: string;
  dialogTitle: string;
  dialogMsg: string;
  myaction: string;
  userId: string = null;
  sourcecontainers: string[] = [];
  timestamparray: string[] = [];
  user = '';
  alloyDensity: number;
  granularityFactor: number;

  @ViewChild(PowderPickerComponent) picker;

  /**
  * Identifies the source / destination screen for sieve powder module
  */
  @Input()
  isSieveScreen = false;

  /**
   * Identifies the source / destination screen for mix batches module
   */
  @Input()
  isMixBatchScreen = false;

  /**
  * Identifies the source / destination screen for load machine module
  */
  @Input()
  isLoadMachineScreen = false;

  /**
  * Identifies the source / destination screen for machine unload unused powder module
  */
  @Input()
  isUnloadUnusedPowder = false;

  /**
  * Identifies the source / destination screen for machine unload complete powder module
  */
  @Input()
  isUnloadCompletePowder = false;

  /**
  * Identifies the source / destination screen for machine unload polluted powder module
  */
  @Input()
  isUnloadPollutedPowder = false;

  /**
   * To store the total capacity in destination
   */
  totalCapacityInDestination = 0;
  Title: any;
  Mssg: any;
  Button: any;
  YButton: any;
  NButton: any;

  // Subscription of the observer of the screen size
  mediaQuery$: Subscription;

  // The active media query (xs | sm | md | lg | xl)
  activeMediaQuery: string;

  @ViewChild(PowderPickerComponent) powderPickerComponent: PowderPickerComponent;

  constructor(private router: Router,
    private powderPickerService: PowderPickerService,
    public dialog: MatDialog,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private mediaObserver: MediaObserver,
  ) {
      this.userId = authService.getUserId();
    }

  ngOnInit() {
    this.powderPickerService.currentconatiners.subscribe(res => this.sourcecontainers = res);
    console.log('source containers list : ', this.sourcecontainers);
    this.timestamparray = this.sourcecontainers;
    this.powderPickerService.currentuser.subscribe(res => this.user = res);
    console.log('source containers user : ', this.user);
  }

  ngAfterViewInit(): void {
    this.alloyDensity = +this.activatedRoute.snapshot.queryParamMap.get('alloyDensity');
    this.granularityFactor = +this.activatedRoute.snapshot.queryParamMap.get('granularityFactor');
    this.mediaQuery$ = this.mediaObserver.media$.subscribe( (change: MediaChange) => {
      this.activeMediaQuery = `${change.mqAlias}`;
    });
  }

  ngOnDestroy () {
    this.mediaQuery$.unsubscribe();
  }

  getSelectedContainers(): void {
    const containers: IContainer[] = this.picker.getSelectedContainers();
    let isAnythingSelected = false;
    for (const container of containers) {
      // for (let pcontainer of this.timestamparray) {
      //   if ( pcontainer !== container.containerOrMachineName ) {
          this.timestamparray.push(container.containerOrMachineName);
      //   }
      // }
      if (container.bookedForDest === true) {
        isAnythingSelected = true;
        this.totalCapacityInDestination += ( (container.capacity * this.alloyDensity * this.granularityFactor / 100))  - container.mass;
      }
    }

    console.log('Total containers = ', this.sourcecontainers);
    if (containers.length > 0 && isAnythingSelected) {
      this.powderPickerService.addToSourceDesContainers(containers, false, this.authService.getUserId()).subscribe(res => {
        if (this.isSieveScreen) {
          this.router.navigate(['/app/powder-settings/', 'sieve-movement'],
          { queryParams : { totalCapacityInDestination: this.totalCapacityInDestination} });
        } else if (this.isMixBatchScreen) {
          this.router.navigate(['/app/powder-settings/', 'mix-batch-movement'],
          { queryParams : { totalCapacityInDestination: this.totalCapacityInDestination} });
        } else if (this.isLoadMachineScreen) {
          this.router.navigate(['/app/powder-settings/', 'movement'],
          { queryParams : { totalCapacityInDestination: this.totalCapacityInDestination} });
        } else if (this.isUnloadUnusedPowder) {
          this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-unused-powder-movement'],
          { queryParams : { totalCapacityInDestination: this.totalCapacityInDestination} });
        } else if (this.isUnloadCompletePowder) {
          this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-complete-powder-movement'],
          { queryParams : { totalCapacityInDestination: this.totalCapacityInDestination} });
        } else if (this.isUnloadPollutedPowder) {
          this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-polluted-powder-movement'],
          { queryParams : { totalCapacityInDestination: this.totalCapacityInDestination} });
        }
      });
    } else {
      this.translate.get('Dialog.Title1').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message1').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.ok_BTN').subscribe(value => this.Button = value);
      this.yes_text = this.Button;
      this.dialogTitle = this.Title;
      this.dialogMsg = this.Mssg;
      this.no_text = '';
      this.myaction = 'no_destination';
      this.openDialog();
    }
  }

  checkDestFormState(): void {
    this.timestamparray = [];
    this.timestamparray = this.sourcecontainers;
    if (this.picker.checkDestFormState()) {
      this.translate.get('Dialog.Title4').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message12').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
      this.translate.get('BTN.no_stay_BTN').subscribe(value => this.NButton = value);
      this.yes_text = this.YButton;
      this.dialogTitle = this.Title;
      this.dialogMsg = this.Mssg;
      this.no_text = this.NButton;
      this.myaction =  'select_destination';
      this.openDialog();
    } else {
      if (this.isSieveScreen) {
        this.router.navigate(['/app/powder-settings/', 'sieve-source']);
      } else if (this.isMixBatchScreen) {
        this.router.navigate(['/app/powder-settings/', 'mix-batch-source']);
      }  else if (this.isLoadMachineScreen) {
        this.router.navigate(['/app/powder-settings/', 'load-machine-source']);
      } else if (this.isUnloadUnusedPowder) {
        this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-unused-powder']);
      } else if (this.isUnloadCompletePowder) {
        this.router.navigate(['/app/powder-settings/unload-machine/', 'complete-unload']);
      } else if (this.isUnloadPollutedPowder) {
        this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-polluted-powder']);
      }
    }
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '250px',
      data: {myaction: this.myaction ,
        dialogTitle:
        this.dialogTitle,
        dialogMsg: this.dialogMsg,
        yes_text: this.yes_text,
        no_text: this.no_text}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.backScreen === 'source') {
        if (this.isSieveScreen) {
          this.router.navigate(['/app/powder-settings/', 'sieve-source']);
        } else if (this.isMixBatchScreen) {
          this.router.navigate(['/app/powder-settings/', 'mix-batch-source']);
        } else if (this.isLoadMachineScreen) {
          this.router.navigate(['/app/powder-settings/', 'load-machine-source']);
        } else if (this.isUnloadUnusedPowder) {
          this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-unused-powder']);
        } else if (this.isUnloadCompletePowder) {
          this.router.navigate(['/app/powder-settings/unload-machine/', 'complete-unload']);
        } else if (this.isUnloadPollutedPowder) {
          this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-polluted-powder']);
        }
      }
    });
  }

  selectContainerViaQRCode() {
    this.powderPickerComponent.selectContainerViaQRCode();
  }

}

