import { TimerService } from 'src/app/services/timer.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  Route
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy {
  private allRoutes = [] as Imenuoption[];
  public breadcrumbs = [] as Imenuoption[];
  public actions = [] as Imenuoption[];

  private authStatusSub: Subscription;
  userIsAuthorized = false;

  rowActionClass: string[] = [
    'col-md-3 col-lg-2 col-sm-4 col-xs-6 card-short',
    'col-md-3 col-lg-3 col-sm-4 col-xs-6 card-long'
  ];

  /**
   * @class DetailComponent
   * @constructor
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private timerService: TimerService
  ) {
    this.breadcrumbs = [];
    this.resumeTimerForTransaction();
  }

  ngOnInit() {
    this.userIsAuthorized = this.authService.getIsAuthorized();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthorizedUser => {
        console.log("is authorized user", isAuthorizedUser);
        this.userIsAuthorized = isAuthorizedUser;
        this.resumeTimerForTransaction();
      });

    this.getAllRoutes('', this.router.config);

    // subscribe to the NavigationEnd event
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        // set breadcrumbs
        const root: ActivatedRoute = this.activatedRoute.root;

        this.breadcrumbs = this.getBreadcrumbs(root);

        let currentAction: Imenuoption;
        this.breadcrumbs.forEach(option => {
          currentAction = option;
        });

        if (currentAction !== undefined) {
          this.getCurrentActions(currentAction, this.allRoutes);
        }
      });
  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Imenuoption[] = []
  ): Imenuoption[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_IMAGE_URL = 'imageURL';
    const ROUTE_DATA_IS_ACTION = 'isAction';
    const ROUTE_DATA_DESCRIPTION = 'description';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const option: Imenuoption = {
        _id: '',
        name: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        description: child.snapshot.data[ROUTE_DATA_DESCRIPTION],
        imageURL: child.snapshot.data[ROUTE_DATA_IMAGE_URL],
        navURL: url,
        isAction: child.snapshot.data[ROUTE_DATA_IS_ACTION]
      };

      breadcrumbs.push(option);

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  /**
   * To get all the routes from the router config file
   * and store in an array
   */
  getAllRoutes(parent: String, config: Route[]): void {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];

      const option = {} as Imenuoption;
      option.navURL = parent + '/' + route.path;

      if (option.navURL === '/home' || option.navURL === '/home/') {
        option.navURL = '/';
      }

      option.imageURL = option.navURL === '/' ? '' : route.data.imageURL;
      option.name = option.navURL === '/' ? '' : route.data.breadcrumb;
      option.isAction = option.navURL === '/' ? '' : route.data.isAction;
      option.description = option.navURL === '/' ? '' : route.data.description;

      this.allRoutes.push(option);

      if (route.children) {
        const currentPath = route.path ? parent + '/' + route.path : parent;
        this.getAllRoutes(currentPath, route.children);
      }
    }
  }

  /**
   *
   * @param currentRoute
   * @param allRoutes
   *
   * With respect to the current route we identify
   * all the routes in the all routes array and set
   * the actions array with the respective routes
   */
  getCurrentActions(
    currentRoute: Imenuoption,
    allRoutes: Imenuoption[]
  ): Imenuoption[] {
    this.actions = [];

    /**
     * We compare the currently active route
     * with all the routes of the application router
     */
    allRoutes.forEach(option => {
      let currentRouteSplitter = [] as string[];
      currentRouteSplitter = currentRoute.navURL.split('/');

      let optionRouteSplitter = [] as string[];
      optionRouteSplitter = option.navURL.split('/');

      const currentPathLength = currentRouteSplitter.length;

      let resultant = '';

      /**
       * Comparing current route's data with the
       * each route data and create the resultant url
       *
       * e.g. if the current-route url is /app/powdersettings/new-delivery
       * and option-route url is /app/powdersettings
       * the resultant url will be /app/powdersettings
       */

      if (option.navURL.startsWith(currentRoute.navURL)) {
        for (let i = 0; i <= currentPathLength; i++) {
          resultant += '/' + optionRouteSplitter[i];
        }

        /**
         * To remove multiple / appended in the beginning
         */

        while (resultant.charAt(0) === '/') {
          resultant = resultant.substr(1);
        }

        /**
         * Only the actions where isAction is true
         * will become the part of actions array
         * i.e. the ones to be displayed in the form of CARDS
         */

        this.allRoutes.forEach(route => {
          if (route.navURL === '/' + resultant && route.isAction === 'true') {
            this.actions.push(route);
          }
        });
      }
    });

    /** Remove Duplicate actions e.g. the actions array
     * will contain multiple /app/powdersettings/
     * as per the split and url concat logic above */

    this.actions = this.actions.filter(
      (option, index, self) =>
        index === self.findIndex(t => t.navURL === option.navURL)
    );

    const restrictions: string[] = this.authService.getRestrictions();

    const modifiedActions: Imenuoption[] = [];
    let isElementFound: boolean;

    for (let index1 = 0; index1 < this.actions.length; index1++) {
      isElementFound = false;

      for (let index2 = 0; index2 < restrictions.length; index2++) {
        if (this.actions[index1].navURL.search(restrictions[index2]) !== -1) {
          isElementFound = true;
          break;
        }
      }

      if (!isElementFound) {
        modifiedActions.push(this.actions[index1]);
      }
    }
    this.actions = modifiedActions;
    return this.actions;
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

  /** To check if the logged in user has any pending transaction
   * we start the timer again from the difference of current timestamp & timestamp
   * value fetched from back-end when the user logs in again to the application
   **/
  resumeTimerForTransaction() {
    if (
      !this.timerService.isTimerRunning &&
      this.authService.getTransactionTimeStamp() !== 0
    ) {
      const currentTime = new Date().getTime();
      const serverTime = this.authService.getTransactionTimeStamp();

      const delay = Math.trunc((currentTime - serverTime) / 1000);

      this.timerService.startTimer(delay);
    }
  }
}
