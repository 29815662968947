import { NgModule } from '@angular/core';

import { PowderPickerComponent } from './powderpicker/powder-picker.component';
import { CommonModalComponent } from './dialog/common-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PowderOverviewChartComponent } from './powder-overview-chart/powder-overview-chart.component';
import { ChartsModule } from 'ng2-charts';
import { SourceComponent } from './movement/source/source.component';
import { TargetComponent } from './movement/target/target.component';
import { SrcTgtTransferComponent } from './movement/src-tgt-transfers/src-tgt-transfer.component';
import { CommonFormDialogComponent } from './dialog/common-form-dialog/common-form-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QrcodegeneratorComponent } from './dialog/qrcodegenerator/qrcodegenerator.component';
import {NgxPrintModule} from 'ngx-print';
import { CoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  imports: [
    CoreModule,
    ChartsModule,
    QRCodeModule,
    NgxPrintModule,
    TranslateModule,
    ZXingScannerModule,
  ],
  declarations: [PowderPickerComponent,
    CommonModalComponent,
    PowderOverviewChartComponent,
    SourceComponent,
    TargetComponent,
    SrcTgtTransferComponent,
    CommonFormDialogComponent,
    QrcodegeneratorComponent,
    QrScannerComponent
],
  entryComponents: [CommonModalComponent, CommonFormDialogComponent, QrcodegeneratorComponent],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ],
  exports: [
    SourceComponent,
    TargetComponent,
    SrcTgtTransferComponent,
    PowderOverviewChartComponent,
    PowderPickerComponent,
    CommonModalComponent
  ]
})
export class SharedModule { }
