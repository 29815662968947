import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PowderPickerService } from 'src/app/shared/powderpicker/powder-picker-service';

@Component({
  selector: 'app-mix-batch-target',
  templateUrl: './mix-batch-target.component.html',
  styleUrls: ['./mix-batch-target.component.css']
})
export class MixBatchTargetComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }
}
