import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsumableTypeDataSource } from './consumable-type-datasource';

@Component({
  selector: 'app-list-consumable-type',
  templateUrl: './list-consumable-type.component.html',
  styleUrls: ['./list-consumable-type.component.css']
})
export class ListConsumableTypeComponent implements OnInit {
  dataSource: ConsumableTypeDataSource;
  displayedColumns: string[];
  searchString = '';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  addConsumableType(): void {
    // this.compCommService.setCommObject(null);
    this.router.navigate(['/app/consumables/', 'add-consumable-type']);
  }

}
