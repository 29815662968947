import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangePropertiesService } from './change-properties.sevice';
import { MatSnackBar } from '@angular/material';
import { CommonFormDialogData } from 'src/app/shared/dialog/common-form-dialog/CommonFormDialogData';
import { CommonFormDialogComponent } from 'src/app/shared/dialog/common-form-dialog/common-form-dialog.component';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.css']
})
export class ListContainersComponent implements OnInit {

  userId: string = null;
  Title: any;
  Mssg: any;

  constructor(private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private changePropertiesService: ChangePropertiesService,
    private authService: AuthService,
    public translate: TranslateService) {
    this.userId = authService.getUserId();
  }

  ngOnInit() {
  }

  /**
   * This method opens the Change Properties Dialog.
   */
  openChangePropertiesDialog(selectedContainer: IContainer): void {
    // this.openDialog(selectedContainer);
    this.translate.get('Dialog.Title22').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message22').subscribe(value => this.Mssg = value);

    const formDialogData: CommonFormDialogData = new CommonFormDialogData();
    formDialogData.dialogTitle = this.Title;
    formDialogData.dialogMsg = this.Mssg + selectedContainer.containerOrMachineName;
    formDialogData.isChangeContainerPropForm = true;
    formDialogData.containerData = selectedContainer;

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(CommonFormDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('form dialog result : ' + JSON.stringify(result));

      const data = JSON.parse(JSON.stringify(result));
      // selectedContainer.isSeived = data.isSieved;
      // selectedContainer.lastSeiveCycle = data.cycle;
      selectedContainer.containerEmptyWeight = data.weight;

      this.changePropertiesService.changeProperties(selectedContainer).subscribe(res => {

        const obj = JSON.parse(JSON.stringify(res));

        if (obj.isSuccess === true ) {
          this.openSnackBar('Properties Changed Successfully!','Change Properties');
          this.dialog.closeAll();
        } else {
          this.openSnackBar('Something Went Wront, Please Try Again','Change Properties');
          this.dialog.closeAll();
        }

      });

    });
}
/**
   * Opens a toast / snacbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}
