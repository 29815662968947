import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LogHistoryService } from './log-history.service';

@Injectable({
  providedIn: 'root'
})

export class LogHistoryDataResolver implements Resolve<any> {

  constructor(
    private logHistoryService: LogHistoryService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.logHistoryService.fetchLogHistoryDetails();
  }
}
