import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PowderOverviewComponent } from './powder-overview/powder-overview.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule} from '@angular/material/dialog';
import { NewDeliveryComponent } from './new-delivery/new-delivery.component';
import { SieveSourceComponent } from './sieve-powder/sieve-source/sieve-source.component';
import { SieveTargetComponent } from './sieve-powder/sieve-target/sieve-target.component';
import { ChangePropertiesComponent } from './change-properties/change-properties.component';
import { SievePowderSrcTgtTransferComponent } from './sieve-powder/sieve-powder-src-tgt-transfer/sieve-powder-src-tgt-transfer.component';
import { MixBatchSrcTgtTransferComponent } from './mix-batches/mix-batch-src-tgt-transfer/mix-batch-src-tgt-transfer.component';
import { MixBatchSourceComponent } from './mix-batches/mix-batch-source/mix-batch-source.component';
import { MixBatchTargetComponent } from './mix-batches/mix-batch-target/mix-batch-target.component';
import { LoadMachineSrcTgtTransferComponent } from './load-machines/load-machine-src-tgt-transfer/load-machine-src-tgt-transfer.component';
import { LoadMachineTargetComponent } from './load-machines/load-machine-target/load-machine-target.component';
import { LoadMachineSourceComponent } from './load-machines/load-machine-source/load-machine-source.component';
import { UnusedPowderSourceComponent } from './unload-machine/unload-unused-powder/unused-powder-source/unused-powder-source.component';
import { UnusedPowderSrcTgtTransferComponent } from './unload-machine/unload-unused-powder/unused-powder-src-tgt-transfer/unused-powder-src-tgt-transfer.component';
import { UnusedPowderTargetComponent } from './unload-machine/unload-unused-powder/unused-powder-target/unused-powder-target.component';
import { CompletePowderSourceComponent } from './unload-machine/unload-complete-powder/complete-powder-source/complete-powder-source.component';
import { CompletePowderTargetComponent } from './unload-machine/unload-complete-powder/complete-powder-target/complete-powder-target.component';
import { CompletePowderSrcTgtTransferComponent } from './unload-machine/unload-complete-powder/complete-powder-src-tgt-transfer/complete-powder-src-tgt-transfer.component';
import { AddMaterialTypeComponent } from './add-material-type/add-material-type.component';
import { ListMaterialTypeComponent } from './list-material-type/list-material-type.component';
import { AddMachineTypeComponent } from './add-machine-type/add-machine-type.component';
import { ListMachineTypeComponent } from './list-machine-type/list-machine-type.component';
import { FileUploadModule } from 'node_modules/ng2-file-upload/ng2-file-upload';
import { PollutedPowderSourceComponent } from './unload-machine/unload-polluted-powder/polluted-powder-source/polluted-powder-source.component';
import { PollutedPowderTargetComponent } from './unload-machine/unload-polluted-powder/polluted-powder-target/polluted-powder-target.component';
import { PollutedPowderSrcTgtTransferComponent } from './unload-machine/unload-polluted-powder/polluted-powder-src-tgt-transfer/polluted-powder-src-tgt-transfer.component';
import { MachineParkListComponent } from './machine-park-list/machine-park-list.component';
import { ListContainersComponent } from './container-management/list-container/list-container.component';
import { ContainerNewDeliveryComponent } from './container-management/container-new-delivery/container-new-delivery.component';
import { AddMachineParkComponent } from './add-machine-park/add-machine-park.component';
import { CoreModule } from '../core/core.module';
import { BatchHistoryComponent } from './batch-history/batch-history.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { QrDetailsComponent } from './qr-details/qr-details.component';
import { ScanningScreenComponent } from './scanning-screen/scanning-screen.component';
import { MatCardModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { LogHistoryComponent } from './log-history/log-history.component';
import { AddConsumableTypeComponent } from './add-consumable-type/add-consumable-type.component';
import { ListConsumableTypeComponent } from './list-consumable-type/list-consumable-type.component';

@NgModule({
  declarations: [
    PowderOverviewComponent,
    SieveSourceComponent,
    SieveTargetComponent,
    SievePowderSrcTgtTransferComponent,
    NewDeliveryComponent,
    LoadMachineSourceComponent,
    LoadMachineTargetComponent,
    LoadMachineSrcTgtTransferComponent,
    ChangePropertiesComponent,
    MixBatchSrcTgtTransferComponent,
    MixBatchSourceComponent,
    MixBatchTargetComponent,
    UnusedPowderSourceComponent,
    UnusedPowderSrcTgtTransferComponent,
    UnusedPowderTargetComponent,
    CompletePowderSourceComponent,
    CompletePowderTargetComponent,
    CompletePowderSrcTgtTransferComponent,
    AddMaterialTypeComponent,
    ListMaterialTypeComponent,
    AddMachineTypeComponent,
    ListMachineTypeComponent,
    PollutedPowderSourceComponent,
    PollutedPowderTargetComponent,
    PollutedPowderSrcTgtTransferComponent,
    MachineParkListComponent,
    ListContainersComponent,
    ContainerNewDeliveryComponent,
    AddMachineParkComponent,
    BatchHistoryComponent,
    QrScannerComponent,
    QrDetailsComponent,
    ScanningScreenComponent,
    LogHistoryComponent,
    AddConsumableTypeComponent,
    ListConsumableTypeComponent
    ],
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    FileUploadModule,
    ZXingScannerModule,
    MatCardModule,
    TranslateModule
  ],
  exports: []
})
export class WizardsModule { }
