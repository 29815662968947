import { IContainerDetail } from './../../shared/powderpicker/IContainerDetail';
import { ScannerService } from './../scanning-screen/scanner.service';
import { Component, OnInit } from '@angular/core';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';

@Component({
  selector: 'app-qr-details',
  templateUrl: './qr-details.component.html',
  styleUrls: ['./qr-details.component.css']
})
export class QrDetailsComponent implements OnInit {

  containerOrMachineName = '...';
  powderName = '...';
  batchName = '...';
  sieveCycle = 0;
  isSieved = false;
  powderMass = 0;

  constructor(private scanner: ScannerService) { }

  ngOnInit() {
    this.scanner.currentQRResponse.subscribe( qrResultString => {
      if (qrResultString !== 'qr-response-source') {
        this.scanner.findContainerDetails(qrResultString).subscribe( containerDetail => {
          this.parseAndDisplayContainerData(containerDetail[0]);
        });
      }
    });
  }

  parseAndDisplayContainerData(containerDetail: IContainer): void {
    this.containerOrMachineName = containerDetail.containerOrMachineName;
    this.powderName = containerDetail.powderName;
    this.batchName = containerDetail.batchName;
    this.sieveCycle = containerDetail.lastSeiveCycle;
    this.isSieved = containerDetail.isSeived;
    this.powderMass = containerDetail.mass;
  }
}
