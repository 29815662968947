import { DataSource } from "@angular/cdk/table";
import { CollectionViewer } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { MachineTypeService } from "../add-machine-type/machine-type.service";
import { IMachineType } from "src/app/modals/IMachineType";

export class MachineTypeDataSource implements DataSource<IMachineType> {

  private machineTypesSubject = new BehaviorSubject<IMachineType[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private noOfMachineTypesSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public noOfMachineTypes$ = this.noOfMachineTypesSubject.asObservable();


  constructor(private machineTypeService: MachineTypeService) { }

  connect(collectionViewer: CollectionViewer): Observable<IMachineType[]> {
    return this.machineTypesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.machineTypesSubject.complete();
    this.noOfMachineTypesSubject.complete();
    this.loadingSubject.complete();
  }

  loadMachineTyes(
    search: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number) {

    this.loadingSubject.next(true);

    this.machineTypeService.findMachineTypes( search, sortDirection, pageIndex, pageSize ).
      pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(machineDetails => {
        this.machineTypesSubject.next(machineDetails[0].machineTypes);
        this.noOfMachineTypesSubject.next(machineDetails[1].machineTypeLength);
      });
  }
}

