import { QrDetailsComponent } from './../wizards/qr-details/qr-details.component';
import { QrScannerComponent } from './../wizards/qr-scanner/qr-scanner.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PowderOverviewComponent } from '../wizards/powder-overview/powder-overview.component';
import { NewDeliveryComponent } from '../wizards/new-delivery/new-delivery.component';
import { PowderOverviewChartResolver } from '../shared/powder-overview-chart/powder-overview-chart-resolver';
import { SieveSourceComponent } from '../wizards/sieve-powder/sieve-source/sieve-source.component';
import { ChangePropertiesComponent } from '../wizards/change-properties/change-properties.component';
import { SieveTargetComponent } from '../wizards/sieve-powder/sieve-target/sieve-target.component';
import { SievePowderSrcTgtTransferComponent } from '../wizards/sieve-powder/sieve-powder-src-tgt-transfer/sieve-powder-src-tgt-transfer.component';
import { MixBatchSourceComponent } from '../wizards/mix-batches/mix-batch-source/mix-batch-source.component';
import { MixBatchTargetComponent } from '../wizards/mix-batches/mix-batch-target/mix-batch-target.component';
import { MixBatchSrcTgtTransferComponent } from '../wizards/mix-batches/mix-batch-src-tgt-transfer/mix-batch-src-tgt-transfer.component';
import { LoadMachineSrcTgtTransferComponent } from '../wizards/load-machines/load-machine-src-tgt-transfer/load-machine-src-tgt-transfer.component';
import { LoadMachineTargetComponent } from '../wizards/load-machines/load-machine-target/load-machine-target.component';
import { LoadMachineSourceComponent } from '../wizards/load-machines/load-machine-source/load-machine-source.component';
import { UnusedPowderSourceComponent } from '../wizards/unload-machine/unload-unused-powder/unused-powder-source/unused-powder-source.component';
import { UnusedPowderTargetComponent } from '../wizards/unload-machine/unload-unused-powder/unused-powder-target/unused-powder-target.component';
import { UnusedPowderSrcTgtTransferComponent } from '../wizards/unload-machine/unload-unused-powder/unused-powder-src-tgt-transfer/unused-powder-src-tgt-transfer.component';
import { CompletePowderSourceComponent } from '../wizards/unload-machine/unload-complete-powder/complete-powder-source/complete-powder-source.component';
import { CompletePowderTargetComponent } from '../wizards/unload-machine/unload-complete-powder/complete-powder-target/complete-powder-target.component';
import { CompletePowderSrcTgtTransferComponent } from '../wizards/unload-machine/unload-complete-powder/complete-powder-src-tgt-transfer/complete-powder-src-tgt-transfer.component';
import { AddMaterialTypeComponent } from '../wizards/add-material-type/add-material-type.component';
import { ListMaterialTypeComponent } from '../wizards/list-material-type/list-material-type.component';
import { AddMachineTypeComponent } from '../wizards/add-machine-type/add-machine-type.component';
import { ListMachineTypeComponent } from '../wizards/list-machine-type/list-machine-type.component';
import { PollutedPowderSrcTgtTransferComponent } from '../wizards/unload-machine/unload-polluted-powder/polluted-powder-src-tgt-transfer/polluted-powder-src-tgt-transfer.component';
import { PollutedPowderTargetComponent } from '../wizards/unload-machine/unload-polluted-powder/polluted-powder-target/polluted-powder-target.component';
import { PollutedPowderSourceComponent } from '../wizards/unload-machine/unload-polluted-powder/polluted-powder-source/polluted-powder-source.component';
import { MachineParkListComponent } from '../wizards/machine-park-list/machine-park-list.component';
import { MachineParkModelsResolver } from '../wizards/machine-park-list/machine-park-models-resolver';
import { ContainerNewDeliveryComponent } from '../wizards/container-management/container-new-delivery/container-new-delivery.component';
import { ListContainersComponent } from '../wizards/container-management/list-container/list-container.component';
import { AddMachineParkComponent } from '../wizards/add-machine-park/add-machine-park.component';
import { ContainerNewDeliveryResolver } from '../wizards/container-management/container-new-delivery/container-new-delivery-resolver';
import { ContainerMachineGuard } from '../guards/container-machine.guard';
import { BatchHistoryComponent } from '../wizards/batch-history/batch-history.component';
import { NewDeliveryResolver } from '../wizards/new-delivery/new-delivery-resolver';
import { BatchHistoryDataResolver } from '../wizards/batch-history/batch-history.resolver';
import { NavResolver } from '../core/navigation/navigation.resolver';
import { NavdataComponent } from '../core/navigation/navdata.component';
import { NavigationComponent } from '../core/navigation/navigation.component';
import { AuthGuard } from '../guards/auth.guard';
import { ScanningScreenComponent } from '../wizards/scanning-screen/scanning-screen.component';
import { LogHistoryComponent } from '../wizards/log-history/log-history.component';
import { LogHistoryDataResolver } from '../wizards/log-history/log-history.resolver';
import { AddConsumableTypeComponent } from '../wizards/add-consumable-type/add-consumable-type.component';
import { ListConsumableTypeComponent } from '../wizards/list-consumable-type/list-consumable-type.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app'
  },
  {
    path: 'home',
    component: NavdataComponent,
    resolve: {
      navData: NavResolver
    },
    pathMatch: 'full'
  },
  {
    path: 'app',
    data: {
      breadcrumb: 'Home',
      isAction: 'true'
    },
    children: [
      {
        path: 'powder-settings',
        data: {
          breadcrumb: 'Powder Operations',
          imageURL: './assets/images/powder.png',
          isAction: 'true',
          description: 'All Powder Operations - Do you want to move Powder in the shop sieve or mix Batches?'
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'new-delivery',
            component: NewDeliveryComponent,
            resolve: {
              newDeliveryData: NewDeliveryResolver
            },
            data: {
              breadcrumb: 'New Delivery',
              imageURL: './assets/images/truck.png',
              isAction: 'true',
              description: 'Register new delivery of powder in the System.'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'powder-overview',
            component: PowderOverviewComponent,
            resolve: {
              powderTypes: PowderOverviewChartResolver
            },
            data: {
              breadcrumb: 'Powder Overview',
              imageURL: './assets/images/powder_overview.png',
              isAction: 'true',
              description: 'Here you get an overview over all the powder that you have in the shop.'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'load-machine-source',
            component: LoadMachineSourceComponent,
            resolve: {
              powderTypes: PowderOverviewChartResolver
            },
            canActivate: [
              AuthGuard , ContainerMachineGuard
            ],
            data: {
              breadcrumb: 'Load Machine',
              imageURL: './assets/images/load_powder.png',
              isAction: 'true',
              description: 'Here you can start loading a machine with powder.'
            }
          },
          {
            path: 'load-machine-target',
            component: LoadMachineTargetComponent,
            resolve: {
              powderTypes: PowderOverviewChartResolver
            },
            data: {
              breadcrumb: 'Load Machine',
              imageURL: './assets/images/load_powder.png',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'movement',
            component: LoadMachineSrcTgtTransferComponent,
            data: {
              breadcrumb: 'Powder Movement'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'unload-machine',
            data: {
              breadcrumb: 'Unload Machine',
              imageURL: './assets/images/unload_powder.png',
              isAction: 'true',
              description: 'Here you can unload a machine.'
            },
            canActivate: [AuthGuard],
            children: [
              {
                path: 'unload-polluted-powder',
                component: PollutedPowderSourceComponent,
                data: {
                  breadcrumb: 'Unload Polluted Powder',
                  imageURL: './assets/images/unload_powder.png',
                  isAction: 'true'
                },
                canActivate: [AuthGuard , ContainerMachineGuard
                ]
              },
              {
                path: 'unload-unused-powder',
                component: UnusedPowderSourceComponent,
                data: {
                  breadcrumb: 'Unload Unused Powder',
                  imageURL: './assets/images/unload_powder.png',
                  isAction: 'true'
                },
                canActivate: [AuthGuard , ContainerMachineGuard
                ]
              },
              {
                path: 'complete-unload',
                component: CompletePowderSourceComponent,
                data: {
                  breadcrumb: 'Complete Unload',
                  imageURL: './assets/images/unload_powder.png',
                  isAction: 'true'
                },
                canActivate: [AuthGuard , ContainerMachineGuard
                ]
              },
              {
                path: 'unload-unused-powder-target',
                component: UnusedPowderTargetComponent,
                data: {
                  breadcrumb: 'Unload Unused Powder',
                  isAction: 'false'
                },
                canActivate: [AuthGuard]
              },
              {
                path: 'unload-complete-powder-target',
                component: CompletePowderTargetComponent,
                data: {
                  breadcrumb: 'Complete Unload',
                  isAction: 'false'
                },
                canActivate: [AuthGuard]
              },
              {
                path: 'unload-polluted-powder-target',
                component: PollutedPowderTargetComponent,
                data: {
                  breadcrumb: 'Unload Polluted Powder',
                  isAction: 'false'
                },
                canActivate: [AuthGuard]
              },
              {
                path: 'unload-unused-powder-movement',
                component: UnusedPowderSrcTgtTransferComponent,
                data: {
                  breadcrumb: 'Unload Unused Powder',
                  isAction: 'false'
                },
                canActivate: [AuthGuard]
              },
              {
                path: 'unload-complete-powder-movement',
                component: CompletePowderSrcTgtTransferComponent,
                data: {
                  breadcrumb: 'Complete Unload',
                  isAction: 'false'
                },
                canActivate: [AuthGuard]
              },
              {
                path: 'unload-polluted-powder-movement',
                component: PollutedPowderSrcTgtTransferComponent,
                data: {
                  breadcrumb: 'Unload Polluted Powder',
                  isAction: 'false'
                },
                canActivate: [AuthGuard]
              }
            ]
          },
          {
            path: 'sieve-source',
            component: SieveSourceComponent,
            data: {
              breadcrumb: 'Sieve Powder',
              imageURL: './assets/images/sieve.png',
              isAction: 'true',
              description: 'This module assists you sieving powder.'
            },
            canActivate: [AuthGuard , ContainerMachineGuard
            ]
          },
          {
            path: 'sieve-target',
            component: SieveTargetComponent,
            data: {
              breadcrumb: 'Sieve Powder',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'sieve-movement',
            component: SievePowderSrcTgtTransferComponent,
            data: {
              breadcrumb: 'Sieve Powder',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'mix-batch-source',
            component: MixBatchSourceComponent,
            data: {
              breadcrumb: 'Mix or Move',
              imageURL: './assets/images/mix_move.png',
              isAction: 'true',
              description: 'Here you will get assistance with mixing batches.'
            },
            canActivate: [AuthGuard , ContainerMachineGuard
            ]
          },
          {
            path: 'mix-batch-target',
            component: MixBatchTargetComponent,
            data: {
              breadcrumb: 'Mix or Move',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'mix-batch-movement',
            component: MixBatchSrcTgtTransferComponent,
            data: {
              breadcrumb: 'Mix or Move',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'change-properties',
            component: ChangePropertiesComponent,
            resolve: {
              // resolver added to load all the dropdown
              // list in advance before the page load to make user experience better.
              newDeliveryData: NewDeliveryResolver
            },
            data: {
              breadcrumb: 'Powder Inventory',
              imageURL: './assets/images/inventory.png',
              isAction: 'true',
              description: 'Do you need a quick overview and make changes in an uncomplicated manner ?'
            },
            canActivate: [AuthGuard , ContainerMachineGuard
            ]
          },
          {
            path: 'batch-history',
            component: BatchHistoryComponent,
            resolve: {
              batchHistoryData: BatchHistoryDataResolver
            },
            data: {
              breadcrumb: 'Batch History',
              imageURL: './assets/images/batch_history.png',
              isAction: 'true',
              description: 'Find out the Batch Formation Details i.e. typically the output of Mix Batches.'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'logs',
            component: LogHistoryComponent,
            resolve: {
              logsData: LogHistoryDataResolver
            },
            data: {
              breadcrumb: 'Transactions',
              imageURL: './assets/images/batch_history.png',
              isAction: 'true',
              description: 'Find out the History of all the transactions i.e who, when, & what transaction was done.'
            },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'containers',
        component: ListContainersComponent,
        data: {
          breadcrumb: 'Containers',
          imageURL: './assets/images/container.png',
          isAction: 'true',
          description: 'Container Operations - If you want to register a newly delivered Container in the system or scrap one?'
        },
        canActivate: [AuthGuard , ContainerMachineGuard
        ]
      },
      {
        path: 'add-container',
        component: ContainerNewDeliveryComponent,
        resolve: {
          suppliers: ContainerNewDeliveryResolver
        },
        data: {
          breadcrumb: 'Add Container',
          isAction: 'false'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-material',
        component: ListMaterialTypeComponent,
        data: {
          breadcrumb: 'Materials',
          imageURL: './assets/images/material.png',
          isAction: 'true',
          description: 'Material Administraion - Here you can add new materials to the system or change values of current ones'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'add-material-type',
        component: AddMaterialTypeComponent,
        data: {
          breadcrumb: 'Add Material Type'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'machines',
        data: {
          breadcrumb: 'Machines',
          imageURL: './assets/images/machine.png',
          isAction: 'true',
          description: 'Machine Asset Management - Here'
          + 'you register machine types that you will receive '
          + 'in the near future or add machines to your park'
        },
        children: [
          {
            path: 'add-machine-to-park',
            component: AddMachineParkComponent,
            resolve: {
              models: MachineParkModelsResolver
            },
            data: {
              breadcrumb: 'My Shop',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'manage-machine-park',
            component: MachineParkListComponent,
            data: {
              breadcrumb: 'My Shop',
              imageURL: './assets/images/machine.png',
              isAction: 'true',
              description: 'In My Shop you find all the machines you have in your shop and can change the status.'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'manage-machine-type',
            component: ListMachineTypeComponent,
            data: {
              breadcrumb: 'Machine Types',
              imageURL: './assets/images/machine.png',
              isAction: 'true',
              description: 'Here you can register and define new machine types.'
           },
           canActivate: [AuthGuard]
          },
          {
            path: 'add-machine-type',
            component: AddMachineTypeComponent,
            data: {
              breadcrumb: 'Add Machine Type',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'consumables',
        data: {
          breadcrumb: 'Consumables',
          imageURL: './assets/images/gloves.png',
          isAction: 'true',
          description: 'Consumables Management - Here'
          + 'you register consumables types that you will receive '
          + 'in the near future or add consumables to your park'
        },
        children: [
          {
            path: 'add-machine-to-park',
            component: AddMachineParkComponent,
            resolve: {
              models: MachineParkModelsResolver
            },
            data: {
              breadcrumb: 'My Consumables Shop',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'manage-machine-park',
            component: MachineParkListComponent,
            data: {
              breadcrumb: 'My Consumables Shop',
              imageURL: './assets/images/gloves.png',
              isAction: 'true',
              description: 'In My Consumables Shop you find all the consumables you have in your shop and can change the status.'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'manage-consumable-type',
            component: ListConsumableTypeComponent,
            data: {
              breadcrumb: 'Consumable Types',
              imageURL: './assets/images/gloves.png',
              isAction: 'true',
              description: 'Here you can register and define new consumables types.'
           },
           canActivate: [AuthGuard]
          },
          {
            path: 'add-consumable-type',
            component: AddConsumableTypeComponent,
            data: {
              breadcrumb: 'Add Consumable Type',
              isAction: 'false'
            },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'scanner',
        component: ScanningScreenComponent,
        data: {
          breadcrumb: 'QR Scanner',
          imageURL: './assets/images/scanner.png',
          isAction: 'true',
          description: 'QR Scanner'
        },
        canActivate: [AuthGuard]
      }]
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, NavResolver]
})

export class PMRoutingModule { }
