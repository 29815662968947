import { AbstractControl } from '@angular/forms';
import { CommonFormDialogData } from './CommonFormDialogData';
/**
 * Handles the validations related to common form dialog
 */
export class FormValidators {

    /**
     * Performs the validations and sends error message in case the validation fails
     * @param containerData data passed used in validation
     */
    public static sourceDestinationComparision(containerData: CommonFormDialogData) {
        return (control: AbstractControl): { [key: string]: boolean } => {
            const destinationMass = control.get('destinationMass');
            const sourceMass = control.get('sourceMass');

            if (!destinationMass || !sourceMass) {
                return null;
            }

            if (containerData.isSieveForm === true) {
              if (sourceMass.value < (containerData.sourceEmptyContainerWeight)) {
                control.get('sourceMass').setErrors({ massExeeded: 'Container can\'t weigh less than '
                + (containerData.sourceEmptyContainerWeight) });
              }
              if (destinationMass.value < (containerData.containerData.containerEmptyWeight)) {
                control.get('destinationMass').setErrors({ massExeeded: 'Container can\'t weigh less than '
                + (containerData.containerData.containerEmptyWeight) });
              }
            } else {
              if ((destinationMass.value < (containerData.containerData.containerEmptyWeight
                + containerData.containerData.mass))) {
                control.get('destinationMass').setErrors({ massExeeded: 'Container can\'t weigh less than '
                + (containerData.containerData.containerEmptyWeight
                + containerData.containerData.mass) });
              }
              if (destinationMass.value > ( containerData.sourceMass
                 + containerData.containerData.mass
                + containerData.containerData.containerEmptyWeight) ||
                (destinationMass.value - containerData.containerData.containerEmptyWeight)
                > containerData.destinationCapacityKg) {
                control.get('destinationMass').setErrors({massExeeded: 'Destination overflow'});
              }
            }
        };
    }

}
