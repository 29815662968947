import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { IPowderType } from 'src/app/modals/IPowderType';
import { IBatch } from 'src/app/modals/IBatch';
import { ISeiveState } from 'src/app/modals/ISeiveState';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-powder-overview-chart',
  templateUrl: './powder-overview-chart.component.html',
  styleUrls: ['./powder-overview-chart.component.css']
})
export class PowderOverviewChartComponent implements OnInit {

  /**
   * Charts data related to powder types
   */
  @Input()
  chartPowderTypeData: IPowderType[] = [];

  /**
   * Charts data related to batches
   */
  @Input()
  chartBatchData: IBatch[] = [];

  /**
   * Charts data related to seive states
   */
  @Input()
  chartSeiveStateData: ISeiveState[] = [];

  /**
   * Identifies the type for which the chart data is selected
   * options - powder, batch, seive
   */
  @Input()
  powderOverviewChartType: string = "powder";

  @Output()
  onChartClicked = new EventEmitter();

  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;

  /**
   * show / hide a chart
   */
  showChart: boolean = true;

  /**
   * selected powder name used
   */
  @Input()
  defaultSelectedPowderType: string;

  // Pie
  pieChartLabels: string[] = [];
  pieChartData: number[] = [];
  pieChartColors: any[] = [];
  pieChartType:string = 'pie';


  pieChartOptions: any = {

    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Powder Type Data (Tooltips diplay Mass in KG)'
    }
  };
  Title: any;
  Title1: any;
  Title2: any;

  constructor(
    public translate: TranslateService,
  ) {

  }

  ngOnInit() {
    console.log("Charts Data inside charts : " + JSON.stringify(this.chartPowderTypeData));

    if (this.powderOverviewChartType === 'powder') {
      this.updatePowderTypeData();
    } else if (this.powderOverviewChartType === 'batch') {
      this.updateBatchData(this.defaultSelectedPowderType);
    } else {
      this.updateSeiveStateData(this.defaultSelectedPowderType);
    }
  }

  updateAllCharts() {
      if (this.powderOverviewChartType === 'powder') {
        this.updatePowderTypeData();
      } else if (this.powderOverviewChartType === 'batch') {
        this.updateBatchData(this.defaultSelectedPowderType);
      } else {
        this.updateSeiveStateData(this.defaultSelectedPowderType);
      }
  }

  // Chart Click event
  public chartClicked(e: any): void {
    if (e.active.length > 0) {

      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {

        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];

        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        console.log(clickedElementIndex, label, value);
        this.onChartClicked.emit(label);
      }
    }
  }

  public chartHovered(e:any):void {
    console.log(e);
  }

  /**
   * Refresing the chart with updated data
   */
  public refreshChart(powderName: string): void {
    this.showChart = false;

    setTimeout(()=>{
      this.showChart = true;
    }, 1);

    this.pieChartLabels = []
    this.pieChartData = [];
    this.pieChartColors = [];

    if (this.powderOverviewChartType === "batch") {
      this.updateBatchData(powderName);
    } else if (this.powderOverviewChartType === "seive") {
      this.updateSeiveStateData(powderName);
    }

  }

  /**
  * Updating the chart data for powder types
  */
  public updatePowderTypeData(): void {
    this.translate.get('Chart.Heading1').subscribe(value => this.Title = value);
    this.pieChartOptions.title.text = this.Title ;
    let chartColors: string[] = [];

    this.chartPowderTypeData.forEach(powderType => {
      chartColors.push(powderType.powderCssColor);
      this.pieChartLabels.push(powderType.powderName);
      this.pieChartData.push(powderType.mass);
    });
    if (chartColors.length > 0) {
      this.pieChartColors.push({ backgroundColor: chartColors });
    }
  }

  /**
  * Updating the chart data for batches
  */
  public updateBatchData(powderName: string): void {
    this.translate.get('Chart.Heading2').subscribe(value => this.Title1 = value);
    this.pieChartOptions.title.text = this.Title1 + powderName;
    let chartColors: string[] = [];

    this.chartBatchData.forEach(batch => {
      chartColors.push(batch.batchCssColor);
      this.pieChartLabels.push(batch.batchName);
      this.pieChartData.push(batch.mass);
    });
    if (chartColors.length > 0) {
      this.pieChartColors.push({ backgroundColor: chartColors });
    }
  }

  /**
  * Updating the chart data for seive states
  */
  public updateSeiveStateData(powderName: string): void {
    this.translate.get('Chart.Heading3').subscribe(value => this.Title2 = value);
    this.pieChartOptions.title.text = this.Title2 + powderName;

    this.chartSeiveStateData.forEach(seiveState => {
      this.pieChartLabels.push(String(seiveState.lastSeiveCycle));
      this.pieChartData.push(seiveState.mass);
    });
  }
}
