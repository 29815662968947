import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CommonModalComponent } from "../dialog/common-modal.component";
import { PowderPickerService } from "./powder-picker-service";
import { AuthService } from "src/app/services/auth.service";
import { TimerService } from "src/app/services/timer.service";
import { TranslateService } from '@ngx-translate/core';

export class DiscardDialogHelper {

  yes_text: string;
  no_text: string;
  dialogTitle: string;
  dialogMsg: string;
  myaction: string;
  Title: any;
  Mssg: any;
  YButton: any;
  NButton: any;

  constructor(private router: Router,
    private powderPickerService: PowderPickerService,
    public dialog: MatDialog,
    private authService: AuthService,
    private timerService: TimerService,
    public translate: TranslateService ) { }

  discardMovement(): void {
    this.translate.get('Dialog.Title4').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message12').subscribe(value => this.Mssg = value);
    this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
    this.translate.get('BTN.no_BTN').subscribe(value => this.NButton = value);
    this.myaction = 'discard_pwm_process';
    this.dialogTitle = this.Title;
    this.dialogMsg = this.Mssg;
    this.yes_text = this.YButton;
    this.no_text = this.NButton;
    this.openDialog();
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '250px',
      data: { myaction: this.myaction,
        dialogTitle: this.dialogTitle,
        dialogMsg: this.dialogMsg,
        yes_text: this.yes_text,
        no_text: this.no_text
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.backScreen === 'start') {

        // reset all the params
        this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe(res => {

          const obj = JSON.parse(JSON.stringify(res));

          if(obj.isSuccess === true ) {
            this.timerService.stopTimer();
            this.router.navigate(['/app/', 'powder-settings']);
          } else {
            // show error notification to the user
          }
          this.powderPickerService.setTransactionTimestamp(0);
        });

      }
    });
  }
}
