import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PMRoutingModule } from '../router/pmrouter.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatOptionModule,
  MatSelectModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTreeModule,
  MatIconModule,
  MatExpansionModule
} from '@angular/material';
import { ActionThumbnailComponent } from './actions/action-thumbnail.component';
import { NavdataComponent } from './navigation/navdata.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    MatIconModule,
    PMRoutingModule,
    TranslateModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavdataComponent,
    NavigationComponent,
    ActionThumbnailComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    MatIconModule,
    PMRoutingModule,
    MatExpansionModule
  ]
})
export class CoreModule { }
