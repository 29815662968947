import { BehaviorSubject } from "rxjs";
import { IContainer } from "./IContainer";
import { IScreens } from "./IScreens";

/**
 * Contains the manipulation of data for the powder movement datasource
 */
export class DataSourceMovementHelper {
    private selectedContainers: IContainer[] = [];

    constructor(private containersSubject: BehaviorSubject<IContainer[]>) {}

    /**
     * Unselecting the fetched containers except the selected containers
     */
    public unselectContainers(containers: IContainer[]): void {
        const fetchedContainers: IContainer[] = Object.values(containers);

        fetchedContainers.forEach(container => {

            const foundContainer: IContainer = this.selectedContainers.find(selectedContainer =>
              selectedContainer.containerOrMachineName === container.containerOrMachineName
            );

            if (foundContainer) {
                container.bookedForSource = foundContainer.bookedForSource;
                container.bookedForDest = foundContainer.bookedForDest;
            } else {
                container.bookedForSource = false;
                container.bookedForDest = false;
            }
        });

        this.containersSubject.next(fetchedContainers);
    }

    /**
     * Modifying the selected container based on checked state
     * @param container
     * @param checked
     * @param isSource
     */
    public modifyTheContainerOnSelection(container: IContainer, checked: boolean, isSource: boolean, pmScreens: IScreens) {

        const foundContainer: IContainer = this.selectedContainers.find(selectedContainer =>
          selectedContainer.containerOrMachineName.toLowerCase() === container.containerOrMachineName.toLowerCase()
        );
        const isSieveScreen: boolean = pmScreens.getCurrentScreen() === IScreens.SIEVE_SCREEN;
        const isUnloadMachinePowder: boolean = pmScreens.getCurrentScreen() === IScreens.UNLOAD_UNUSED_POWDER_SCREEN ||
                                                pmScreens.getCurrentScreen() === IScreens.UNLOAD_COMPLETE_POWDER_SCREEN ||
                                                pmScreens.getCurrentScreen() === IScreens.UNLOAD_POLLUTED_POWDER_SCREEN;

        const allowSingleSelection: boolean = isSieveScreen || isUnloadMachinePowder;

        // if container found make changes in the existing
        if (foundContainer) {
            if (isSource) {
                foundContainer.bookedForSource = checked;
            } else {
                foundContainer.bookedForDest = checked;
            }
            // if destination only one can be selected
        } else if (this.selectedContainers.length < 1) {
            if (isSource) {
                container.bookedForSource = checked;

            } else {
                container.bookedForDest = checked;
            }
            this.selectedContainers.push(container);

            // is source multiple can be selected
        } else if (isSource && !allowSingleSelection) {

            container.bookedForSource = checked;
            this.selectedContainers.push(container);
        } else {
            if (!isSource || allowSingleSelection) {
                this.selectedContainers.pop();
            }
            if (isSource) {
                container.bookedForSource = checked;
            } else {
                container.bookedForDest = checked;
            }
            this.selectedContainers.push(container);
        }

        this.unselectContainers(this.containersSubject.value);
    }

    /**
     * Getting the selected container for the source / destination.
     * In case of source the list may contain more than one element
     * but for destination only one element will be present.
     */
    public getSelectedContainers(): IContainer[] {
        return this.selectedContainers.filter(container => {
           return container.bookedForSource || container.bookedForDest;
        });
    }

    /**
    * Removing the containers / machines with mass 0 from the source
    */
    public removeSrcEmptyContainerAndMachines(): void {
        let containers: IContainer[] = Object.values(this.containersSubject.value);

        containers = containers.filter(container => container.mass !== 0);
        this.containersSubject.next(containers);
        this.selectedContainers = this.selectedContainers.filter(container => container.mass !== 0);
    }

    /**
    * Returning the Source containers / machines present on the Movement Screen
    */
   public getSrcContainerAndMachines() {
    const containers: IContainer[] = Object.values(this.containersSubject.value);

    return containers;
  }

}
