import { Component, OnInit, ViewChildren, QueryList, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { PowderPickerComponent } from 'src/app/shared/powderpicker/powder-picker.component';
import { Router, ActivatedRoute } from '@angular/router';
import { PowderPickerService } from 'src/app/shared/powderpicker/powder-picker-service';
import { CommonModalComponent } from 'src/app/shared/dialog/common-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DiscardDialogHelper } from 'src/app/shared/powderpicker/DiscardDialogHelper';
import { CommonFormDialogComponent } from '../../dialog/common-form-dialog/common-form-dialog.component';
import { CommonFormDialogData } from '../../dialog/common-form-dialog/CommonFormDialogData';
import { isUndefined } from 'util';
import { AuthService } from 'src/app/services/auth.service';
import { TimerService } from 'src/app/services/timer.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-src-tgt-transfer',
  templateUrl: './src-tgt-transfer.component.html',
  styleUrls: ['./src-tgt-transfer.component.css']
})

export class SrcTgtTransferComponent implements OnInit, AfterViewInit {
  options: FormGroup;

  /**
   * Powder mass selected from the UI (used as an ngModel)
   */
  powderMass: number;

  /**
   * temporary powder mass used in undo operation
   */
  tmpPowderMass: number;

  /**
   * Identifies whether the powder movement is allowed or not
   */
  shouldMovePowder: boolean;

  /**
   * Identifies whether undo operation is allowed or not
   */
  undoAllowed: boolean;

  /**
   * Option to allow edit or not on the mass input field
   */
  editMassInput = true;

  /**
   * Holds selected source containers for movement
   */
  selectedSrcContainerForMovement: IContainer[];

  /**
   * Holds selected destination containers for movement
   */
  selectedDestContainerForMovement: IContainer[];

  /**
   * List holds the masses of the source containers used when undo operation is performed
   */
  undoSrcMass: number[] = [];

  /**
   * List holds the cycles of the source containers used when undo operation is performed
   */
  undoSrcCycle: number[] = [];


  /**
   * List holds the lab check cycles of the source containers used when undo operation is performed
   */
  undoSrcLabCheckCycle: number[] = [];

  /**
   * List holds the lab check cycles of the source containers used when undo operation is performed
   */
  undoSrcLastLabCheckCycle: number[] = [];

  /**
   * Holds the total mass of the source containers when multiple sources are selected
   */
  totalSrcMass = 0;

  /**
  * Identifies the source / destination screen for sieve powder module
  */
  @Input()
  isSieveScreen = false;

  /**
   * Identifies the source / destination screen for mix batches module
   */
  @Input()
  isMixBatchScreen = false;

  /**
  * Identifies the source / destination screen for load machine module
  */
  @Input()
  isLoadMachineScreen = false;

  /**
  * Identifies the source / destination screen for machine unload unused powder module
  */
  @Input()
  isUnloadUnusedPowder = false;

  /**
  * Identifies the source / destination screen for machine unload complete powder module
  */
  @Input()
  isUnloadCompletePowder = false;

  /**
  * Identifies the source / destination screen for machine unload complete powder module
  */
  @Input()
  isUnloadPollutedPowder = false;

  /**
   * Used during sieving of powder movement
   */
  lastSieveCycle: number;

  /**
   * Used during sieving of powder movement
   */
  lastLabCheckCycle: number;

  /**
   * holds the destination lab check before movement (used in undo operation)
   */
  tmpDestinationLabCheckCycle: number;

  /**
   * holds the destination lab check before movement (used in undo operation)
   */
  tmpDestinationLastLabCheckCycle: number;

  /**
   * holds the destination lab check before movement (used in undo operation)
   */
  tmpDestinationLastLabCheck: number;

  /**
   * holds the destination mass before movement (used in undo operation)
   */
  tmpDestinationMass: number;

  /**
   * holds the source mass before movement (used in undo operation)
   */
  tmpSourceMass: number;

  /**
   * holds the last sieve cycle of desination before movement (used in undo operation)
   */
  tmpLastSieveCycle: number;


  /**
   * capacity of total number of containers
   */
  totalCapacityInDestination: number;


  massFormControl: FormControl = new FormControl();

  yes_text: string;
  no_text: string;
  myaction: string;
  dialogTitle: string;
  dialogMsg: string;

  /**
   * Helper object to Call the Discard Dialog
   */
  discardDialogHelper: DiscardDialogHelper;

  @ViewChildren(PowderPickerComponent) srcDesComponents: QueryList<PowderPickerComponent>;
  Title: any;
  Mssg: any;
  YButton: any;
  NButton: any;
  tmpPowderType: any;
  tmpBatchType: any;
  tmpPowderColor: string;
  tmpBatchColor: string;

  constructor(fb: FormBuilder,
    private router: Router,
    private powderPickerService: PowderPickerService,
    public dialog: MatDialog,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private timerService: TimerService,
    private snackBar: MatSnackBar,
    public translate: TranslateService) {
    this.options = fb.group({
      weight: [1, Validators.min(0.01)],
    });

    this.discardDialogHelper = new DiscardDialogHelper(router,
      powderPickerService,
      dialog,
      authService,
      timerService,
      translate);
  }

  ngOnInit() {
    this.powderMass = 1;
    this.tmpDestinationMass = 1;
    this.tmpSourceMass = 1;
    this.tmpLastSieveCycle = 1;
    this.lastLabCheckCycle = 1;
    // this.powderLostMass = 1;
    this.tmpPowderMass = this.powderMass;
    this.shouldMovePowder = true;
    this.undoAllowed = false;
    this.selectedSrcContainerForMovement = [];
    this.selectedDestContainerForMovement = [];
    this.massFormControl.disable();
  }

  ngAfterViewInit() {
    /**
     * + is the shortcut to convert string value to number
     */
    this.totalCapacityInDestination = +this.activatedRoute.snapshot.queryParamMap.get('totalCapacityInDestination');
  }

  /**
   * Saves the container movement changes to the back-end
   */
  saveContainerMovementChanges() {

    const containersForMovement: IContainer[] = this.selectedSrcContainerForMovement.concat(this.selectedDestContainerForMovement);
    let newBatchColor = '';
    const isDiffBatch: boolean = this.hasSrcTgtDiffBatch();
    if (this.isMixBatchScreen && isDiffBatch) {
      newBatchColor = this.randomHexCode();
    }

    this.powderPickerService.movePowder(containersForMovement, newBatchColor).subscribe(res => {

      const obj = JSON.parse(JSON.stringify(res));

      if (this.isMixBatchScreen && isDiffBatch) {
        this.selectedDestContainerForMovement[0].batchName = JSON.parse(JSON.stringify(res)).newBatchName;
        console.log('response: ' + obj.newBatchName);
        this.selectedDestContainerForMovement[0].batchCssColor = newBatchColor;
      }

      // removing the container / machine with mass 0
      const srcComponent: PowderPickerComponent = this.srcDesComponents.find(component => component.isSource);
      srcComponent.removeSrcEmptyContainerAndMachines();
      this.editMassInput = true;

      if (obj.isSuccess === true) {
        this.timerService.stopTimer();
        this.powderMass = this.selectedSrcContainerForMovement ?
            Math.round(this.selectedSrcContainerForMovement[0].mass * 100) / 100 : 0;
        const srcContainers: IContainer[] = srcComponent.getSrcContainerAndMachines();

        if ( this.powderMass !== 0 || srcContainers.length !== 0) {

          this.openSnackBar('Transaction was completed successfully.' , 'OK');
          setTimeout(() => {
            this.myaction = 'actual_movement';
            this.translate.get('Dialog.Title2').subscribe(value => this.Title = value);
            this.translate.get('Dialog.Message2').subscribe(value => this.Mssg = value);
            this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
            this.translate.get('BTN.no_mdone_BTN').subscribe(value => this.NButton = value);
            this.dialogTitle = this.Title;
            this.dialogMsg = this.Mssg;
            this.yes_text = this.YButton;
            this.no_text = this.NButton;
            this.openDialog();
          }, 1000);

        } else {
            // reset all the params
            this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe( res => {

            const obj = JSON.parse(JSON.stringify(res));

            if (obj.isSuccess === true ) {
              this.timerService.stopTimer();
              this.router.navigate(['/app/', 'powder-settings']);
              this.powderPickerService.setTransactionTimestamp(0);
              this.openSnackBar('Transaction was completed successfully.' , 'OK');
            } else {
              // show error notification to the user
            }
          });
        }

      } else {
        this.router.navigate(['/movement']);
      }
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      verticalPosition: 'top'
    });
  }

  /**
   * Random light color generation
   */
  private randomHexCode(): string {
    return '#80' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }

  /**
   * Move the powder from Source to Destination
   */
  moveContainer() {
    this.srcDesComponents.forEach(srcDesComponent => {

      if (srcDesComponent.isSource) {
        this.selectedSrcContainerForMovement = srcDesComponent.getSelectedContainerForMovement();
      } else {
        this.selectedDestContainerForMovement = srcDesComponent.getSelectedContainerForMovement();
      }
    });

    this.totalSrcMass = Math.round(this.getTotalSelectedSourceContainersMass() * 100) / 100;
    this.translate.get('Dialog.Title26').subscribe(value => this.Title = value);
    this.translate.get('BTN.ok_BTN').subscribe(value => this.YButton = value);
    this.dialogTitle = this.Title;
    this.yes_text = this.YButton;
    this.myaction = '';

    if (this.validatePowderMovement()) {
      // transfer the mass between source and destination container

      let isDiffBatch = false;
      if (this.isMixBatchScreen) {
        // TODO: identify whether the source and destination has even a single different batch
        isDiffBatch = this.hasSrcTgtDiffBatch();

        if (isDiffBatch) {
          this.translate.get('Dialog.Title3').subscribe(value => this.Title = value);
          this.translate.get('Dialog.Message3').subscribe(value => this.Mssg = value);
          this.translate.get('BTN.ok_BTN').subscribe(value => this.YButton = value);
          this.dialogTitle = this.Title;
          this.dialogMsg = this.Mssg;
          this.yes_text = this.YButton;
          this.myaction = '';
          this.openDialog();
        }
      }

      this.tmpPowderType = this.selectedDestContainerForMovement[0].powderName;
      this.tmpBatchType = this.selectedDestContainerForMovement[0].batchName;
      this.tmpPowderColor = this.selectedDestContainerForMovement[0].powderCssColor;
      this.tmpBatchColor = this.selectedDestContainerForMovement[0].batchCssColor;
      this.tmpDestinationLabCheckCycle = this.selectedDestContainerForMovement[0].labCheckAtCycle;
      this.tmpDestinationLastLabCheckCycle = this.selectedDestContainerForMovement[0].lastLabCheckAtCycle;

      if (this.selectedSrcContainerForMovement.length > 1) {
        // multi movement is done
        this.multiPowderMovement();
      } else {
        // single movement is done
        this.singlePowderMovement();
      }
      this.selectedDestContainerForMovement[0].powderName = this.selectedSrcContainerForMovement[0].powderName;
      this.selectedDestContainerForMovement[0].powderCssColor = this.selectedSrcContainerForMovement[0].powderCssColor;

      if (!isDiffBatch) {
        this.selectedDestContainerForMovement[0].batchName = this.selectedSrcContainerForMovement[0].batchName;
        this.selectedDestContainerForMovement[0].batchCssColor = this.selectedSrcContainerForMovement[0].batchCssColor;
      }

      this.tmpLastSieveCycle = this.selectedDestContainerForMovement[0].lastSeiveCycle;
      this.tmpDestinationMass = this.selectedDestContainerForMovement[0].mass;
      this.tmpPowderMass = this.powderMass;
      this.shouldMovePowder = false;
      this.undoAllowed = true;

      if (this.lastSieveCycle > this.selectedDestContainerForMovement[0].lastSeiveCycle) {
        this.selectedDestContainerForMovement[0].lastSeiveCycle = this.lastSieveCycle;
      }

      if (
        (this.lastLabCheckCycle > this.selectedDestContainerForMovement[0].lastLabCheckAtCycle) ||
        this.selectedDestContainerForMovement[0].lastLabCheckAtCycle === null ||
        this.selectedDestContainerForMovement[0].lastLabCheckAtCycle === undefined) {
        this.selectedDestContainerForMovement[0].lastLabCheckAtCycle = this.lastLabCheckCycle;
      }

      // if (this.isSieveScreen &&
      //   this.selectedDestContainerForMovement[0].labCheckAtCycle <=
      //     (this.selectedDestContainerForMovement[0].lastSeiveCycle - this.selectedDestContainerForMovement[0].lastLabCheckAtCycle) ) {
      //     this.selectedDestContainerForMovement[0].islabCheckDone = false;
      // }

    } else {
      this.selectedSrcContainerForMovement = [];
      this.selectedDestContainerForMovement = [];
      this.undoSrcCycle = [];
      this.undoSrcLabCheckCycle = [];
      this.undoSrcLastLabCheckCycle = [];
      this.undoSrcMass = [];
    }
  }

  /**
   * Reverts back the changes done when powder is moved untile the done button is pressed
   */
  undoChanges(): void {
    if (this.selectedSrcContainerForMovement.length > 1) {
      // multi movement is done
      this.selectedSrcContainerForMovement.forEach((container, index) => {
        container.mass = this.undoSrcMass[index];
        container.lastSeiveCycle = this.undoSrcCycle[index];
        container.labCheckAtCycle = this.undoSrcLabCheckCycle[index];
        container.lastLabCheckAtCycle = this.undoSrcLastLabCheckCycle[index];
      });

      if (this.isSieveScreen) {
        this.selectedDestContainerForMovement[0].mass = this.tmpDestinationMass;
      } else {
        this.selectedDestContainerForMovement[0].mass = this.selectedDestContainerForMovement[0].mass - this.totalSrcMass;
      }
      this.selectedDestContainerForMovement[0].labCheckAtCycle = this.tmpDestinationLabCheckCycle;
      this.selectedDestContainerForMovement[0].lastLabCheckAtCycle = this.tmpDestinationLastLabCheck;
      this.selectedDestContainerForMovement[0].lastSeiveCycle = this.tmpLastSieveCycle;
      this.powderMass = Math.round(this.totalSrcMass * 100) / 100 ;
    } else {
      // single movement is done

      if (this.isSieveScreen) {
        this.selectedSrcContainerForMovement[0].mass = this.tmpSourceMass;
        this.selectedDestContainerForMovement[0].mass = this.tmpDestinationMass;

      } else if (this.isUnloadUnusedPowder || this.isUnloadPollutedPowder) {

        /**
        * Updated total capacity in destination can be retrieved
        * by subtracting the newer mass of the container which has been changed
        * after the last movement.
        */
        this.totalCapacityInDestination = this.totalCapacityInDestination + this.selectedDestContainerForMovement[0].mass;

        this.selectedSrcContainerForMovement[0].mass = this.tmpSourceMass;
        this.selectedDestContainerForMovement[0].mass = this.tmpDestinationMass;

        this.powderMass = Math.round(this.tmpSourceMass * 100) / 100 ;

      } else {
        this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mass + this.powderMass;
        this.selectedDestContainerForMovement[0].mass = this.selectedDestContainerForMovement[0].mass - this.powderMass;
      }
      this.selectedSrcContainerForMovement[0].lastSeiveCycle = this.undoSrcCycle[0];
      this.selectedDestContainerForMovement[0].lastSeiveCycle = this.tmpLastSieveCycle;
      this.selectedSrcContainerForMovement[0].labCheckAtCycle = this.undoSrcLabCheckCycle[0];
      this.selectedSrcContainerForMovement[0].lastLabCheckAtCycle = this.undoSrcLastLabCheckCycle[0];
      this.selectedDestContainerForMovement[0].labCheckAtCycle = this.tmpDestinationLabCheckCycle;
      this.selectedDestContainerForMovement[0].lastLabCheckAtCycle = this.tmpDestinationLastLabCheck;
    }

    if (this.isLoadMachineScreen || this.isUnloadPollutedPowder || this.isUnloadUnusedPowder || this.isUnloadCompletePowder
      || this.isMixBatchScreen || this.isSieveScreen) {
      if (this.tmpPowderType !== null && this.tmpBatchType !== null) {
        this.selectedDestContainerForMovement[0].powderName = this.tmpPowderType;
        this.selectedDestContainerForMovement[0].powderCssColor = this.tmpPowderColor;
        this.selectedDestContainerForMovement[0].batchName = this.tmpBatchType;
        this.selectedDestContainerForMovement[0].batchCssColor = this.tmpBatchColor;
      } else {
        this.selectedDestContainerForMovement[0].powderName = '';
        this.selectedDestContainerForMovement[0].powderCssColor = '';
        this.selectedDestContainerForMovement[0].batchName = '';
        this.selectedDestContainerForMovement[0].batchCssColor = '';
      }
    }

    this.selectedSrcContainerForMovement = [];
    this.selectedDestContainerForMovement = [];
    this.undoSrcCycle = [];
    this.undoSrcMass = [];
    this.undoSrcLabCheckCycle = [];
    this.undoSrcLastLabCheckCycle = [];
    this.tmpPowderMass = this.powderMass;
    this.shouldMovePowder = true;
    this.undoAllowed = false;
  }

  /**
   * Performs single powder movement
   * when only 1 container is selected from the source
   */
  private singlePowderMovement() {

    this.selectedSrcContainerForMovement[0].mass = Math.round(this.selectedSrcContainerForMovement[0].mass * 100) / 100;
    this.selectedDestContainerForMovement[0].mass = Math.round(this.selectedDestContainerForMovement[0].mass * 100) / 100;
    this.lastSieveCycleCheck(this.selectedSrcContainerForMovement[0].lastSeiveCycle);
    this.labCheck(this.selectedSrcContainerForMovement[0].labCheckAtCycle);
    this.lastLabCheck(this.selectedSrcContainerForMovement[0].lastLabCheckAtCycle);
    this.undoSrcCycle[0] = this.selectedSrcContainerForMovement[0].lastSeiveCycle;
    this.undoSrcLabCheckCycle[0] = this.selectedSrcContainerForMovement[0].labCheckAtCycle;
    this.undoSrcLastLabCheckCycle[0] = this.selectedSrcContainerForMovement[0].lastLabCheckAtCycle;

    if (this.isSieveScreen) {

      // for components (Sieve Powder Component)
      this.lastSieveCycleCheck(this.selectedSrcContainerForMovement[0].lastSeiveCycle + 1);
      this.openDialogForSieving();
      this.tmpSourceMass = this.selectedSrcContainerForMovement[0].mass;

    } else if (this.isUnloadPollutedPowder || this.isUnloadUnusedPowder) {

      // for components (Unload Polluted Powder, Unload Unused Powder)
      this.openDialogForUnloadPowder(this.isUnloadPollutedPowder, this.isUnloadUnusedPowder);
      this.tmpSourceMass = this.selectedSrcContainerForMovement[0].mass;
      this.setCycleZeroForEmptyContainer();

    } else {

      // for components (Complete Unload machine, Load machine, mix and move)
      this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mass - this.powderMass;
      this.selectedDestContainerForMovement[0].mass = this.selectedDestContainerForMovement[0].mass + this.powderMass;
      this.setCycleZeroForEmptyContainer();

    }
  }


  /**
   * To set the value of lab check for
   * source and destination containers
   * before final movement of powder
   * from source to destination container
   */
  labCheck(value) {
    this.selectedDestContainerForMovement[0].labCheckAtCycle = value;
  }

   /**
   * To set the value of last lab check for
   * source and destination containers
   * before final movement of powder
   * from source to destination container
   */
  lastLabCheck(value) {
    this.lastLabCheckCycle = value;
    if (this.lastLabCheckCycle < this.selectedDestContainerForMovement[0].lastLabCheckAtCycle) {
      this.lastLabCheckCycle = this.selectedDestContainerForMovement[0].lastLabCheckAtCycle;
    }
  }

  /**
   * To set the value of lab check for
   * source and destination containers
   * before final movement of powder
   * from source to destination container
   */
  lastSieveCycleCheck(value) {
    this.lastSieveCycle = value;
    if (this.lastSieveCycle < this.selectedDestContainerForMovement[0].lastSeiveCycle) {
      this.lastSieveCycle = this.selectedDestContainerForMovement[0].lastSeiveCycle;
    }
  }

  /**
   * Performs multi powder movement
   * when more that 1 containers are selected from the source
   */
  private multiPowderMovement() {
    this.selectedDestContainerForMovement[0].mass = Math.round(this.selectedDestContainerForMovement[0].mass * 100) / 100;
    this.lastSieveCycle = this.selectedSrcContainerForMovement[0].lastSeiveCycle;
    let labCheckCycle = this.selectedSrcContainerForMovement[0].labCheckAtCycle;
    let lastLabCheck = this.selectedSrcContainerForMovement[0].lastLabCheckAtCycle;
    let sieveStatus = true;
    this.selectedSrcContainerForMovement.forEach(container => {
      if (this.lastSieveCycle < container.lastSeiveCycle) {
        this.lastSieveCycle = container.lastSeiveCycle;
      }

      if (labCheckCycle < container.labCheckAtCycle) {
        labCheckCycle = container.labCheckAtCycle;
      }

      if (lastLabCheck < container.lastLabCheckAtCycle) {
        lastLabCheck = container.lastLabCheckAtCycle;
      }

      if (container.isSeived === false) {
        // console.log('found False');
        sieveStatus = false;
      }

      container.mass = Math.round(container.mass * 100) / 100;

      this.undoSrcMass.push(container.mass);
      this.undoSrcCycle.push(container.lastSeiveCycle);
      this.undoSrcLabCheckCycle.push(container.labCheckAtCycle);
      this.undoSrcLastLabCheckCycle.push(container.lastLabCheckAtCycle);

      container.mass = 0;
      container.lastSeiveCycle = 0;
      container.labCheckAtCycle = 0;
      container.lastLabCheckAtCycle = 0;
    });

    this.lastSieveCycleCheck(this.lastSieveCycle);

    this.labCheck(labCheckCycle);

    this.lastLabCheck(lastLabCheck);

    if (this.isSieveScreen) {
      this.openDialogForSieving();
    } else if (this.isMixBatchScreen) {
      this.selectedDestContainerForMovement[0].mass = this.selectedDestContainerForMovement[0].mass + this.totalSrcMass;
      if (sieveStatus === true) {
        this.selectedDestContainerForMovement[0].isSeived = true;
      } else {
        this.selectedDestContainerForMovement[0].isSeived = false;
      }

      this.setCycleZeroForEmptyContainer();
    } else {
      this.selectedDestContainerForMovement[0].mass = this.selectedDestContainerForMovement[0].mass + this.totalSrcMass;
      this.setCycleZeroForEmptyContainer();
    }
    this.powderMass = 0;
  }

  checkPowderMovementFormState(): void {
    if (this.undoAllowed === true) {
      this.myaction = 'select_for_movement';
      this.translate.get('Dialog.Title4').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message4').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
      this.translate.get('BTN.no_mdone_BTN').subscribe(value => this.NButton = value);
      this.dialogTitle = this.Title;
      this.dialogMsg = this.Mssg;
      this.yes_text = this.YButton;
      this.no_text = this.NButton;

      this.openDialog();
    } else {
      this.navigateBackToTarget();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '250px',
      data: {
        myaction: this.myaction,
        dialogTitle: this.dialogTitle,
        dialogMsg: this.dialogMsg,
        yes_text: this.yes_text,
        no_text: this.no_text
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.backScreen === 'destination') {
        this.navigateBackToTarget();
      } else if (result.backScreen === 'movement') {

        this.selectedSrcContainerForMovement = [];
        this.selectedDestContainerForMovement = [];
        this.undoSrcCycle = [];
        this.undoSrcMass = [];
        this.undoSrcLabCheckCycle = [];
        this.undoSrcLastLabCheckCycle = [];
        this.shouldMovePowder = true;
        this.undoAllowed = false;

      } else if (result.backScreen === 'start') {
        // reset all the params
        this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe(res => {

          const obj = JSON.parse(JSON.stringify(res));

          if (obj.isSuccess === true) {
            this.router.navigate(['/app/', 'powder-settings']);
            this.powderPickerService.setTransactionTimestamp(0);
          } else {
            // show error notification to the user
          }
        });

      }
    });
  }

  /**
   * Used to open the dialog where source and destination mass is entered after weighing
   */
  openDialogForSieving(): void {
    const formDialogData: CommonFormDialogData = this.getCommonFormDialogDataForSieving();

    formDialogData.containerData = this.selectedDestContainerForMovement[0];
    formDialogData.sourceEmptyContainerWeight = this.selectedSrcContainerForMovement[0].containerEmptyWeight;

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(CommonFormDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        /**
         * We calculate the total mass Diff by taking destination mass
         * and container empty weight into the account
         */
        let massDiffDest = Math.abs(this.selectedDestContainerForMovement[0].mass
          - result.destinationMass);
        massDiffDest = Math.abs(massDiffDest
          - this.selectedDestContainerForMovement[0].containerEmptyWeight);

        /**
         * We calculate the total mass Diff by taking source mass
         * and container empty weight into the account
         */
        let massDiffSrc = Math.abs(this.selectedSrcContainerForMovement[0].mass
          - result.sourceMass);
        massDiffSrc = Math.abs(massDiffSrc
          - this.selectedSrcContainerForMovement[0].containerEmptyWeight);
        /**
         * We subtract the empty container's weight from the destination
         * so that we know how much powder is present into the destination
         * after movement
         */
        this.selectedDestContainerForMovement[0].mass =
          result.destinationMass
          - this.selectedDestContainerForMovement[0].containerEmptyWeight;

        /**
         * We subtract the empty container's weight from the source
         * so that we know how much powder is present into the source
         * after movement
         */
        this.selectedSrcContainerForMovement[0].mass =
          result.sourceMass
          - this.selectedSrcContainerForMovement[0].containerEmptyWeight;

        this.selectedSrcContainerForMovement[0].mass = Math.round(this.selectedSrcContainerForMovement[0].mass * 100) / 100;
        this.selectedDestContainerForMovement[0].mass = Math.round(this.selectedDestContainerForMovement[0].mass * 100) / 100;


        this.selectedDestContainerForMovement[0].lastSeiveCycle = this.lastSieveCycle;

        // empty container in source or destination with mass 0 should have cycle 0
        this.setCycleZeroForEmptyContainer();

      } else {
        this.undoChanges();
      }
    });
  }

  /**
     * Used to open the dialog where source and destination mass is entered after weighing
     */
  openDialogForUnloadPowder(isPollutedPowderUnload: boolean, isUnusedPowderUnload: boolean): void {
    const formDialogData: CommonFormDialogData = this.getCommonFormDataForUnload();

    formDialogData.containerData = this.selectedDestContainerForMovement[0];

    if (isPollutedPowderUnload) {
      formDialogData.isUnloadPollutedPowderForm = true;
      this.translate.get('Dialog.Message11').subscribe(value => this.Mssg = value);
      formDialogData.dialogMsg = this.Mssg;
      formDialogData.noOfFeedPistons = this.selectedSrcContainerForMovement[0].mData.noOfFeedPistons;
    } else if (isUnusedPowderUnload) {
      formDialogData.isUnloadUnusedPowderForm = true;
    }

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(CommonFormDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {


      if (result) {

        /**
         * To set the lab check cycle value for destination container
         */
        this.labCheck(this.selectedSrcContainerForMovement[0].labCheckAtCycle);


        /**
         * We calculate the total mass Diff by taking destination mass
         * and container empty weight into the account
         */
        let massDiff = Math.abs(this.selectedDestContainerForMovement[0].mass
          - result.destinationMass);
        massDiff = Math.abs(massDiff
          - this.selectedDestContainerForMovement[0].containerEmptyWeight);

        /**
         * We subtract the empty container's weight from the destination
         * so that we know how much powder is present into the destination
         * after movement
         */
        this.selectedDestContainerForMovement[0].mass =
          result.destinationMass
          - this.selectedDestContainerForMovement[0].containerEmptyWeight;

        if (!formDialogData.isUnloadUnusedPowderForm) {
          this.selectedDestContainerForMovement[0].isSeived = false;
        }

        if (result.isMachineEmpty !== undefined && !result.isMachineEmpty) {

          if (formDialogData.isUnloadUnusedPowderForm) {
            this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mass - massDiff;
          } else if (formDialogData.isUnloadPollutedPowderForm) {
            this.factorsBasedPowderMovement(result);
          } else {
            this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mass - massDiff;
          }
        } else {
          this.selectedSrcContainerForMovement[0].mass = 0;
        }

        this.selectedSrcContainerForMovement[0].mass = Math.round(this.selectedSrcContainerForMovement[0].mass * 100) / 100;
        this.selectedDestContainerForMovement[0].mass = Math.round(this.selectedDestContainerForMovement[0].mass * 100) / 100;

        /**
         * Updated total capacity in destination can be retrieved
         * by subtracting the newer mass of the container which has been changed
         * after the last movement.
         */
        this.totalCapacityInDestination = this.totalCapacityInDestination - this.selectedDestContainerForMovement[0].mass;
        this.powderMass = Math.round(this.selectedSrcContainerForMovement[0].mass * 100) / 100 ;

        // empty container in source or destination with mass 0 should have cycle 0
        this.setCycleZeroForEmptyContainer();

      } else {
        this.undoChanges();
      }
    });
  }


  /**
   *
   * @param $event Gets the value of total mass
   * that has been selected from the source-powder-picker.
   */
  populateMass($event) {
    this.powderMass = Math.round($event.totalMass * 100) / 100 ;
    this.editMassInput = $event.allowEdit;
  }

  /**
   * Getting the total mass of the selected source containers
   */
  getTotalSelectedSourceContainersMass(): number {
    let mass = 0;

    if (this.selectedSrcContainerForMovement.length > 0) {
      this.selectedSrcContainerForMovement.forEach(container => {
        mass = mass + container.mass;
      });
    }

    return mass;
  }

  /**
   * Checks wether the selected src containers are of same batches and des is of same batch
   */
  checkForSameBatchMovement(): boolean {
    let isMovementAllowed = true;
    // TODO: need to change for a machine
    let srcBatchName = '';

    // if(containerWithNoMachine) {
    //   srcBatchName = containerWithNoMachine.batchName;
    // }
    srcBatchName = this.selectedSrcContainerForMovement[0].batchName;

    const srcPowderName: string = this.selectedSrcContainerForMovement[0].powderName;
    // let isDestMachine: boolean = this.selectedDestContainerForMovement[0].isMachine;

    // let diffBatchWithinSrc = this.selectedSrcContainerForMovement.find(container =>
    // (container.batchName !== srcBatchName && !container.isMachine) ||
    // container.powderName != srcPowderName);

    // if(diffBatchWithinSrc || (srcBatchName === '' &&
    // this.selectedDestContainerForMovement[0].powderName !== srcPowderName) ||
    // (srcBatchName !== this.selectedDestContainerForMovement[0].batchName && !isDestMachine)) {
    //   isMovementAllowed = false;
    // }

    if ((this.selectedDestContainerForMovement[0].powderName !== srcPowderName) ||
      (srcBatchName !== this.selectedDestContainerForMovement[0].batchName)) {
      isMovementAllowed = false;
    }

    // TODO: need to handle movement between machine and batches

    // if(diffBatchWithinSrc || (srcBatchName === '' && this.selectedDestContainerForMovement[0].powderName !== srcPowderName) || (srcBatchName !== this.selectedDestContainerForMovement[0].batchName && !isDestMachine && srcBatchName !== '')) {
    //   isMovementAllowed = false;
    // }

    return isMovementAllowed;
  }


  discardMovement(): void {
    this.discardDialogHelper.discardMovement();
  }

  /**
   * Navigating back to target based on screens
   */
  navigateBackToTarget(): void {
    if (this.isSieveScreen) {
      this.router.navigate(['/app/powder-settings/', 'sieve-target']);
    } else if (this.isMixBatchScreen) {
      this.router.navigate(['/app/powder-settings/', 'mix-batch-target']);
    } else if (this.isLoadMachineScreen) {
      this.router.navigate(['/app/powder-settings/', 'load-machine-target']);
    } else if (this.isUnloadUnusedPowder) {
      this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-unused-powder-target']);
    } else if (this.isUnloadCompletePowder) {
      this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-complete-powder-target']);
    } else if (this.isUnloadPollutedPowder) {
      this.router.navigate(['/app/powder-settings/unload-machine/', 'unload-polluted-powder-target']);
    }
  }

  /**
   * Performs validation checks while performing powder movement
   */
  validatePowderMovement(): boolean {
    let isMovementValid = false;

    const effectiveMachineOrConatinerCapacityInKg = this.selectedDestContainerForMovement[0].isMachine ?
      ((this.selectedDestContainerForMovement[0].mData.capacity
        * this.selectedSrcContainerForMovement[0].granularityFactor / 100
        * this.selectedSrcContainerForMovement[0].alloyDensity) -
        this.selectedDestContainerForMovement[0].mass) :
      ((this.selectedDestContainerForMovement[0].capacity
        * this.selectedSrcContainerForMovement[0].granularityFactor / 100
        * this.selectedSrcContainerForMovement[0].alloyDensity) -
        this.selectedDestContainerForMovement[0].mass);

    if (this.selectedSrcContainerForMovement.length === 0 || this.selectedDestContainerForMovement.length === 0) {
      this.translate.get('Dialog.Message5').subscribe(value => this.Mssg = value);
      this.dialogMsg = this.Mssg;
      this.openDialog();
    } else if (!this.isSieveScreen &&
      ((!this.selectedDestContainerForMovement[0].isMachine &&
        !this.isUnloadUnusedPowder &&
        !this.isUnloadPollutedPowder &&
        this.powderMass > effectiveMachineOrConatinerCapacityInKg) ||
        (this.selectedDestContainerForMovement[0].isMachine &&
          this.powderMass > effectiveMachineOrConatinerCapacityInKg))) {
      this.translate.get('Dialog.Message6').subscribe(value => this.Mssg = value);
      this.dialogMsg = this.Mssg;
      this.dialogMsg += this.selectedDestContainerForMovement[0].isMachine ?
        ', maximum machine occupancy : ' + Math.round(effectiveMachineOrConatinerCapacityInKg * 100) / 100 + ' Kg' :
        '';
      this.openDialog();
    } else if (!this.isSieveScreen && this.powderMass > this.totalSrcMass) {
      this.translate.get('Dialog.Message7').subscribe(value => this.Mssg = value);
      this.dialogMsg = this.Mssg;
      this.openDialog();
    } else if (!this.isSieveScreen &&
      ((!this.selectedDestContainerForMovement[0].isMachine &&
        !this.isUnloadUnusedPowder &&
        !this.isUnloadPollutedPowder &&
        this.powderMass > effectiveMachineOrConatinerCapacityInKg) ||
        this.selectedDestContainerForMovement[0].isMachine &&
        this.powderMass > effectiveMachineOrConatinerCapacityInKg)) {
      this.translate.get('Dialog.Message8').subscribe(value => this.Mssg = value);
      this.dialogMsg = this.Mssg;
      this.dialogMsg += this.selectedDestContainerForMovement[0].isMachine ?
        ', maximum machine occupancy : ' + Math.round(effectiveMachineOrConatinerCapacityInKg * 100) / 100 + ' Kg' :
        '';
      this.openDialog();
    } else {
      isMovementValid = true;
    }
    return isMovementValid && this.validationsRelatedToSieving();
  }

  /**
    * Performs validation checks related to sieving
    */
  validationsRelatedToSieving(): boolean {
    let isSievingValid = false;

    // if (this.powderMass < this.powderLostMass) {
    //   this.dialogMsg = 'Powder movement is not allowed as the lost mass is greater than the powder mass';
    //   this.openDialog();

    // } else {
    isSievingValid = true;
    // }

    return isSievingValid;
  }

  /**
   * Prepares the required data that needs to be sent to the form dialog
   * and return the CommonFormDialogData object
   */
  private getCommonFormDialogDataForSieving(): CommonFormDialogData {
    const formDialogData: CommonFormDialogData = new CommonFormDialogData();

    this.translate.get('Dialog.Title9').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message9').subscribe(value => this.Mssg = value);
    formDialogData.dialogTitle = this.Title;
    formDialogData.dialogMsg = this.Mssg;
    formDialogData.isSieveForm = true;
    formDialogData.destinationCapacity = this.selectedDestContainerForMovement[0].capacity;
    formDialogData.sourceMass = Math.round(
        ( this.powderMass +
          this.selectedSrcContainerForMovement[0].containerEmptyWeight
        ) * 100
      ) / 100;
    formDialogData.destinationMass = this.selectedDestContainerForMovement[0].mass;
    formDialogData.isSourceMassDisabled = this.selectedSrcContainerForMovement.length > 1 ? true : false;
    formDialogData.sourceCapacity = this.selectedSrcContainerForMovement[0].capacity;
    formDialogData.sourceCapacityKg = (this.selectedSrcContainerForMovement[0].capacity *
      this.selectedSrcContainerForMovement[0].alloyDensity *
      this.selectedSrcContainerForMovement[0].granularityFactor / 100);

    if (formDialogData.isSourceMassDisabled) {
      formDialogData.sourceMass = 0;
    }
    return formDialogData;
  }

  /**
   * Prepares the required data that needs to be sent to the form dialog
   * and return the CommonFormDialogData object
   */
  private getCommonFormDataForUnload(): CommonFormDialogData {
    const formDialogData: CommonFormDialogData = new CommonFormDialogData();

    this.translate.get('Dialog.Title10').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message10').subscribe(value => this.Mssg = value);
    formDialogData.dialogTitle = this.Title;
    formDialogData.dialogMsg = this.Mssg;
    formDialogData.destinationCapacity = this.selectedDestContainerForMovement[0].capacity;
    formDialogData.destinationCapacityKg = (this.selectedDestContainerForMovement[0].capacity *
      this.selectedSrcContainerForMovement[0].granularityFactor / 100 *
      this.selectedSrcContainerForMovement[0].alloyDensity);
    formDialogData.sourceMass = Math.round((this.powderMass + Number.EPSILON) * 100) / 100;
    formDialogData.destinationMass = Math.round(this.selectedDestContainerForMovement[0].mass);

    if (this.selectedSrcContainerForMovement[0].mData.factor2 === 'height') {
      this.translate.get('Dialog.Title27').subscribe(value => this.Title = value);
      formDialogData.userInputTitle = this.Title;
    } else {
      this.translate.get('Dialog.Title28').subscribe(value => this.Title = value);
      formDialogData.userInputTitle = this.Title;
    }

    return formDialogData;
  }

  /**
   * Identifies whether the source and destination has even a single different batch
   * return true if it has different batch
   */
  private hasSrcTgtDiffBatch(): boolean {
    const selectedContainers = this.selectedSrcContainerForMovement.concat(this.selectedDestContainerForMovement);
    const batchName: string = selectedContainers[0].batchName;
    const diffBatchName: IContainer = selectedContainers.find(container => container.batchName !== batchName);

    if (diffBatchName && diffBatchName.batchName !== '') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Setting lastSieveCycle to 0 in case of an empty container
   */
  private setCycleZeroForEmptyContainer(): void {
    if (this.selectedSrcContainerForMovement[0].mass === 0) {
      this.selectedSrcContainerForMovement[0].lastSeiveCycle = 0;
      this.selectedSrcContainerForMovement[0].isSeived = true;
      this.selectedSrcContainerForMovement[0].lastLabCheckAtCycle = 0;
      this.selectedSrcContainerForMovement[0].labCheckAtCycle = 0;
    }
    if (this.selectedDestContainerForMovement[0].mass === 0) {
      this.selectedDestContainerForMovement[0].lastSeiveCycle = 0;
      this.selectedSrcContainerForMovement[0].isSeived = true;
      this.selectedDestContainerForMovement[0].lastLabCheckAtCycle = 0;
      this.selectedDestContainerForMovement[0].labCheckAtCycle = 0;
    }
  }


  /**
   * For the factors introduced on the Machine Screen, we take into account the same
   * for the calculation of remaning powder in the source machine
   */
  private factorsBasedPowderMovement(result: any) {

    if (this.selectedSrcContainerForMovement[0].mData.factor2 === 'height') {
      if (this.selectedSrcContainerForMovement[0].mData.geometry === 'rectangular') {
        if (this.selectedSrcContainerForMovement[0].mData.noOfFeedPistons === 1) {
          this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mData.xDimension
            * this.selectedSrcContainerForMovement[0].mData.yDimension
            * (this.selectedSrcContainerForMovement[0].mData.maxFeedPistonHeight - result.zact1) / 1000000
            * this.selectedSrcContainerForMovement[0].alloyDensity
            * this.selectedSrcContainerForMovement[0].granularityFactor / 100;
        } else if (this.selectedSrcContainerForMovement[0].mData.noOfFeedPistons === 2) {
          this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mData.xDimension
            * this.selectedSrcContainerForMovement[0].mData.yDimension
            * ((this.selectedSrcContainerForMovement[0].mData.maxFeedPistonHeight - result.zact1)
              + (this.selectedSrcContainerForMovement[0].mData.maxFeedPistonHeight - result.zact2)) / 1000000
            * this.selectedSrcContainerForMovement[0].alloyDensity
            * this.selectedSrcContainerForMovement[0].granularityFactor / 100;
        }
      } else {
        if (this.selectedSrcContainerForMovement[0].mData.noOfFeedPistons === 1) {
          this.selectedSrcContainerForMovement[0].mass = (3.14159 / 4)
            * this.selectedSrcContainerForMovement[0].mData.diametre
            * this.selectedSrcContainerForMovement[0].mData.diametre
            * (this.selectedSrcContainerForMovement[0].mData.maxFeedPistonHeight - result.zact1) / 1000000
            * this.selectedSrcContainerForMovement[0].alloyDensity
            * this.selectedSrcContainerForMovement[0].granularityFactor / 100;
        } else if (this.selectedSrcContainerForMovement[0].mData.noOfFeedPistons === 2) {
          this.selectedSrcContainerForMovement[0].mass = (3.14159 / 4)
            * this.selectedSrcContainerForMovement[0].mData.diametre
            * this.selectedSrcContainerForMovement[0].mData.diametre
            * ((this.selectedSrcContainerForMovement[0].mData.maxFeedPistonHeight - result.zact1)
              + (this.selectedSrcContainerForMovement[0].mData.maxFeedPistonHeight - result.zact2)) / 1000000
            * this.selectedSrcContainerForMovement[0].alloyDensity
            * this.selectedSrcContainerForMovement[0].granularityFactor / 100;
        }
      }
    } else {
      this.selectedSrcContainerForMovement[0].mass = this.selectedSrcContainerForMovement[0].mData.capacity
        * result.hopperLoad / 100
        * this.selectedSrcContainerForMovement[0].alloyDensity
        * this.selectedSrcContainerForMovement[0].granularityFactor / 100;
    }

  }
}
