import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IContainerDetail } from './IContainerDetail';
import { IContainer } from './IContainer';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Handles the services to fetch Powder data from the backend
 */
@Injectable({
  providedIn: 'root'
})
export class PowderPickerService {
  [x: string]: any;

  /**
   * Back-end service URL
   */
  backendUrl = environment.apiUrl;

  /**
   * common timestamp used during the powder movement cycle until its completion.
   * Identifies the time remaining to complete the transaction
   */
  private transactionTimestamp = 0;

  /**
   * transaction time limit for a complete powder movement process (9 min interval) in miliseconds
   */
  // private TRANSACTION_TIME_LIMIT: number = 540000;
  private TRANSACTION_TIME_LIMIT = 60000;

  private transactionTimeLimitObservable: any;
  private transactionTimeLimitSubscriber: Subscription;

  /**
   * Used to indicate when to open a dialog
   */
  private dialogSubject = new BehaviorSubject<boolean>(false);

  private sourcecontainers = new BehaviorSubject([]);
  currentconatiners = this.sourcecontainers.asObservable();

  private sourceuser = new BehaviorSubject<string>('');
  currentuser = this.sourceuser.asObservable();

  private timestamp = new BehaviorSubject<string>('');
  currenttimestamp = this.timestamp.asObservable();

  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '5200/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'container-apis';
    }
  }

  changecontainer(containers: string[], user_id: string) {
    this.sourcecontainers.next(containers);
    this.sourceuser.next(user_id);
    // this.timestamp.next(timestamp)
  }

  /**
   * Finding the containers based on the below parameters
   * @param search identifies search keyword
   * @param sortOrder identifies the sorting order
   * @param pageNumber identifies pagination page number
   * @param pageSize identifies pagination page size
   */
  findContainers(
    search = '', sortOrder = 'asc', activeSortName: string, filter: Map<string, string>,
    pageNumber = 0, pageSize = 3, isSource: boolean, isSieveScreen: boolean, isMixBatchScreen: boolean,
    isLoadMachineScreen: boolean,
    isUnloadUnusedPowder: boolean,
    isUnloadCompletePowder: boolean,
    isUnloadPollutedPowder: boolean,
    isListContainerScreen: boolean,
    isPowderOverviewScreen: boolean):  Observable<IContainerDetail> {

    const path = this.STAGE + '/containers';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
        params: new HttpParams()
            .set('search', search)
            .set('sortOrder', sortOrder)
            .set('activeSortName', activeSortName)
            .set('isSource', isSource + '')
            .set('isSieveScreen', isSieveScreen + '')
            .set('isMixBatchScreen', isMixBatchScreen + '')
            .set('isLoadMachineScreen', isLoadMachineScreen + '')
            .set('isUnloadUnusedPowder', isUnloadUnusedPowder + '')
            .set('isUnloadCompletePowder', isUnloadCompletePowder + '')
            .set('isUnloadPollutedPowder', isUnloadPollutedPowder + '')
            .set('isListContainerScreen', isListContainerScreen + '')
            .set('isPowderOverviewScreen', isPowderOverviewScreen + '')
            .set('filter', JSON.stringify(
              Array.from(filter.entries())
              .reduce((o, [key, value]) => {
                o[key] = value;
                return o;
              }, {})
            ))
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
    }).pipe(
        map(res =>  res['containerDetail'])
    );
  }

  /**
   * Adding the selected containers to source / destination
   * @param containers selected containers
   */
  addToSourceDesContainers(containers: IContainer[], isSource: boolean, userId: string): Observable<Object> {
    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/add-to-src-des';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.put(endpoint, {
      containers: containers,
      isSource: isSource,
      userId: userId,
      oldTimestamp: this.transactionTimestamp
    });
  }

  /**
   * Getting the selected source / destination containers
   * @param isSource source identifier
   */
  getSelectedContainers(pageNumber = 0, pageSize = 3,
    isSource: boolean, isUnloadPollutedPowder: boolean): Observable<IContainerDetail> {

    const path = this.STAGE + '/selected-containers';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
      params: new HttpParams()
        .set('isSource', isSource + '')
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('isUnloadPollutedPowder', isUnloadPollutedPowder + '')
    }).pipe(
      map(res => res['containerDetail'])
    );
  }

   /**
   * Moving powder from source to destination using mass i.e. source decrement and
   * destination increment
   * @param containers selected containers
   */
  movePowder(containers: IContainer[], newBatchColor: string): Observable<Object> {

    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/move-powder';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.put(endpoint, {
      containers: containers,
      newBatchColor: newBatchColor
    });
  }

  /**
   * Moving powder from source to destination using mass i.e. source decrement and
   * destination increment
   * @param containers selected containers
   */
  resetStateAfterPowderMovementCompletion(userId: string): Observable<Object> {

    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/complete-powder-movement';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.put(endpoint, {
      userId: userId
    });
  }

  updateTransactionTimestamp(userId: string): Observable<object> {

    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/transaction-timestamp';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.put(endpoint, {
      userId: userId
    });
  }

  /**
   * Completes the powder movement operation so that the container data can be reset w.r.t
   * the booked for source/destination and selected by user options
   */
  public completePowderMovement(): Observable<Object> {

    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/complete-powder-movement';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.put(endpoint, {
      userId: 'Z003udfg'
    });
  }

   /**
   * Backend call to increase transaction time limit
   */
  public increasePowderMovementTimeLimit(): Observable<Object> {
    return this.http.put(this.backendUrl + 'api/increasePowderMovementTimeLimit', {
      userId: 'Z003udfg',
      oldTimestamp: this.transactionTimestamp
    });
  }

   /**
   * Backend call to scrap powder for the selected containers
   */
  public scrapPowder(containerNames: string[]): Observable<Object> {
    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/scrap-powder';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.put(endpoint, {
      containers: containerNames
    });
  }

   /**
   * Backend call to scrap empty containers
   */
  public scrapContainer(containerNames: string[]): Observable<Object> {
    const path = this.STAGE + '/scrap-container';
    const endpoint = this.API_ROOT + path;

    return this.http.put(endpoint, {
      containers: containerNames
    });
  }

  /**
   * Backend call to fetch all suppliers
   */
  public getSuppliers(): Observable<string[]> {
    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
    const path = STAGE + '/suppliers';
    const endpoint = this.authService.getUrl('6200/') + path;

    return this.http.get(endpoint).pipe(
      map(res =>  res['suppliers']));
  }


  /**
   * Backend call to fetch all suppliers
   */
  public isContainerOrMachineLocked(userId: string): Observable<boolean> {
    // TODO: Need to handle based on user
    const path = this.STAGE + '/locked-container';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
      params: new HttpParams()
        .set('userId', userId)
    }).pipe(
      map(res => JSON.parse(JSON.stringify(res)).isLocked));
  }

  /**
   * Initiates the transaction time limit for the powder movement process
   */
  public initiateTransactionTimeLimit(): void {
    this.transactionTimeLimitSubscriber = this.transactionTimeLimitObservable.subscribe(time => {
      // TODO: emit an event in a behaviour subject to open a dialog
      if (time === 0) {
        this.dialogSubject.next(true);
      }
      this.transactionTimeLimitSubscriber.unsubscribe();
    });
  }

  /**
   * Inceases the transaction time limit
   * @param userId time limit to increase for the user
   */
  public increaseTransTimeLimit(): void {
    this.increasePowderMovementTimeLimit().subscribe(res => {

      console.log(JSON.stringify(res));

      const jsonObj = JSON.parse(JSON.stringify(res));
      this.transactionTimestamp = jsonObj.currTimestamp;
      this.initiateTransactionTimeLimit();
    });
  }

  getTransactionTimestamp(): number {
    return this.transactionTimestamp;
  }

  setTransactionTimestamp(timestamp: number): void {
    this.transactionTimestamp = timestamp;
  }

  resetTransactionTimestamp(): void {
    this.transactionTimestamp = 0;
  }

  getDialogObservable(): Observable<boolean> {
    return this.dialogSubject.asObservable();
  }

}
