import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PowderPickerService } from 'src/app/shared/powderpicker/powder-picker-service';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sieve-target',
  templateUrl: './sieve-target.component.html',
  styleUrls: ['./sieve-target.component.css']
})
export class SieveTargetComponent implements OnInit {
    
  constructor() { }
  
  ngOnInit() {
  } 
}
