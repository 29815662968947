import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PMAppComponent } from './pm.app.component';
import { SharedModule } from './shared/shared.module';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { WizardsModule } from './wizards/wizards.module';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './interceptor/auth-interceptor';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient,'./assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    WizardsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [TranslateModule],
  providers: [{provide : HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  declarations: [
    PMAppComponent
  ],
  bootstrap: [PMAppComponent]
})
export class AppModule { }
