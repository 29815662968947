import { IContainer } from './IContainer';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Contains the manipulation of data for the datasource
 */
export class DataSourceHelper {
  private tmpContainersSubject = new BehaviorSubject<IContainer[]>([]);
  private fetchedSelectedContainers = new Set<string>();

  constructor(private authService: AuthService, private containersSubject?: BehaviorSubject<IContainer[]>) { }

  /**
   * Syncing the fetched containers into the temporary container
   *
   * @param containers fetched containers from the backend
   * @param isSource identifier between source and destination
   */
  public addToTemporaryContainerSubject(containers: IContainer[], isSource: boolean): void {

    // setting the fetched selected containers.
    this.setFetchedSelectedContainers(containers, isSource);

    // common container b/w tmp and original
    const commonContainers: IContainer[] = this.findCommonContainers(containers, this.getTemporaryContainers());
    // modified container from the common containers
    const modifiedContainers: IContainer[] = this.getModifiedContainersOnSelection(commonContainers, isSource);

    // used when modifying the fetched containers when there are common selected containers
    const modifiedFetchedContainers: IContainer[] = Object.values(containers);

    if (commonContainers.length === 0) {
      // merges the temporary and fetched containers

      // strange behaviour when using spread syntax therefore commented the below code instead used concat
      // const newContainers: IContainer[] = { ...this.tmpContainersSubject.value, ...containers };
      const newContainers: IContainer[] = this.tmpContainersSubject.value.concat(containers);

      this.tmpContainersSubject.next(newContainers);
      this.containersSubject.next(containers);

    } else {
      const uncommonContainers: IContainer[] = this.findUnCommonContainers(modifiedFetchedContainers, this.getTemporaryContainers());

      // Modifying the fetched containers when there are modified containers
      if (modifiedContainers.length > 0) {
        modifiedFetchedContainers.forEach(container => {
          modifiedContainers.forEach(commonContainer => {
            if (commonContainer.containerOrMachineName.toLowerCase() === container.containerOrMachineName.toLowerCase()) {
              if (isSource) {
                container.bookedForSource = commonContainer.bookedForSource;
              } else {
                container.bookedForDest = commonContainer.bookedForDest;
              }
              container.selectedByUserId = commonContainer.selectedByUserId;
            }
          });
        });
      }
      if (uncommonContainers) {
        this.tmpContainersSubject.next(this.getTemporaryContainers().concat(uncommonContainers));
      }
      this.containersSubject.next(modifiedFetchedContainers);
      // TODO add uncommon containers

    }
  }

  /**
   * Modifies the temporary containers list for a
   * specific container identified by container name with selection of the checkbox
   *
   * @param selectedContainer selected container identifier
   */
  public modifyTemporaryContainerForSelection(selectedContainer: IContainer, isSource: boolean, checked: boolean, userId: string): void {
    const tmpContainerList: IContainer[] = Object.values(this.getTemporaryContainers());

    const index = tmpContainerList.findIndex(container => {
      return container.containerOrMachineName.toLowerCase() === selectedContainer.containerOrMachineName.toLowerCase();
    });

    if (index !== -1) {
      const container = tmpContainerList[index];
      if (checked === false) {
        container.selectedByUserId = null;
        selectedContainer.selectedByUserId = null;
        container.selectionTimestamp = '0';
      } else {
        container.selectedByUserId = userId;
        selectedContainer.selectedByUserId = userId;
      }

      this.modifyContainerOnSelection(isSource, container);
    }

    this.tmpContainersSubject.next(tmpContainerList);
  }

  /**
   * Gets the selected containers from the temporary containers
   *
   * @param isSource identifier between source and destination
   */
  public getSelectedContainers(isSource: boolean): IContainer[] {
    return this.getModifiedContainersOnSelection(Object.values(this.tmpContainersSubject.value), isSource);
  }

  /**
    * Getting the temporary containers
    */
  public getTemporaryContainers(): IContainer[] {
    return this.tmpContainersSubject.value;
  }

  /**
   * Modifies the container source/destination
   *
   * @param isSource identifier between source and destination
   * @param container container to modify
   */
  private modifyContainerOnSelection(isSource: boolean, container: IContainer) {
    if (isSource) {
      container.bookedForSource = !container.bookedForSource;
    } else {
      container.bookedForDest = !container.bookedForDest;
    }
  }

  /**
   * Setting the fetched selected containers inside a Set with unique container name
   *
   * @param containers fetched containers
   * @param isSource identifier between source and destination
   */
  private setFetchedSelectedContainers(containers: IContainer[], isSource: boolean) {
    containers.forEach(container => {
      if (isSource && container.bookedForSource) {
        this.fetchedSelectedContainers.add(container.containerOrMachineName);
      } else if (!isSource && container.bookedForDest) {
        this.fetchedSelectedContainers.add(container.containerOrMachineName);
      }
    });
  }

  /**
   * Finds common containrs between fetched and temporary containers
   *
   * @param containers fetched containers
   */
  public findCommonContainers(containers: IContainer[], tmpContainers: IContainer[]) {

    const commonContainers: IContainer[] = Object.values(tmpContainers)
      .filter(o => containers
        .some(({ containerOrMachineName }) => o.containerOrMachineName === containerOrMachineName));

    return commonContainers;
  }

  /**
   * Finds un-common containers between fetched and temporary containers
   *
   * @param containers fetched containers
   */
  public findUnCommonContainers(containers: IContainer[], tmpContainers: IContainer[]) {

    const unCommonContainers: IContainer[] = Object.values(containers)
      .filter(container => !tmpContainers.find(tmp => tmp.containerOrMachineName === container.containerOrMachineName));

    return unCommonContainers;
  }

  /**
   * Gets the modified containers from the tmp containers
   *
   * @param containers temporary containers
   * @param isSource identifier between source and destination
   */
  private getModifiedContainersOnSelection(containers: IContainer[], isSource: boolean) {

    const userId: string = this.authService.getUserId();

    return containers.filter(container => {
      if (isSource) {
        return ((this.fetchedSelectedContainers.has(container.containerOrMachineName) && (container.selectedByUserId === userId || container.selectedByUserId === null || container.selectedByUserId === ''))
        || (container.bookedForSource && container.selectedByUserId === userId));
      } else {
        return ((this.fetchedSelectedContainers.has(container.containerOrMachineName) && (container.selectedByUserId === userId || container.selectedByUserId === null || container.selectedByUserId === ''))
        || (container.bookedForDest && container.selectedByUserId === userId));
      }
    });
  }

  public checkDestFormState(): boolean {
    const containers: IContainer[] = this.getSelectedContainers(false);
    let bool = false;

    for (let i = 0; i < containers.length; i++) {
      if (this.fetchedSelectedContainers.has(containers[i].containerOrMachineName)) {
        if (!containers[i].bookedForDest) {
          bool = true;
          break;
        }
      } else {
        bool = true;
        break;
      }
    }
    return bool;
  }

  /**
   * Removing the containers / machines with mass 0 from the source
   */
  public removeSrcEmptyContainerAndMachines(): void {
    let containers: IContainer[] = Object.values(this.containersSubject.value);

    containers = containers.filter(container => container.mass !== 0);
    this.containersSubject.next(containers);
  }

  /**
  * Removing the selected containers
  */
  public removeSelectedContainers(containerNames: string[]): void {
    let containers: IContainer[] = Object.values(this.containersSubject.value);
    let tmpContainers: IContainer[] = Object.values(this.tmpContainersSubject.value);

    containers = containers.filter(container => {
      const deleteContainer: string = containerNames.find(containerName => containerName === container.containerOrMachineName);
      if (deleteContainer) {
        return false;
      } else {
        return true;
      }
    });
    this.containersSubject.next(containers);

    tmpContainers = tmpContainers.filter(container => {
      const deleteContainer: string = containerNames.find(containerName => containerName === container.containerOrMachineName);
      if (deleteContainer) {
        return false;
      } else {
        return true;
      }
    });
    this.tmpContainersSubject.next(tmpContainers);
  }


  /**
   * Scrapping the powder for the selected containers
   */
  public scrapPowderForSelectedContainers(containerNames: string[]): void {
    const tmpContainers: IContainer[] = Object.values(this.tmpContainersSubject.value);
    const fetchedContainers: IContainer[] = Object.values(this.containersSubject.value);

    // scrapping refresh for tmp list
    tmpContainers.forEach(container => {
      const scrapPowder: string = containerNames.find(containerName => containerName === container.containerOrMachineName);

      if (scrapPowder && scrapPowder.startsWith('HCN')) {
        container.batchName = '';
        container.batchCssColor = '';
        container.mass = 0;
        container.lastSeiveCycle = 0;
        container.bookedForSource = false;
      } else if (scrapPowder &&  !scrapPowder.startsWith('HCN')) {
        container.powderName = '';
        container.powderCssColor = '';
        container.batchName = '';
        container.batchCssColor = '';
        container.mass = 0;
        container.lastSeiveCycle = 0;
        container.bookedForSource = false;
      }
    });

    this.tmpContainersSubject.next(tmpContainers);

    // scrapping refresh for fetched container list
    fetchedContainers.forEach(container => {
      const scrapPowder: string = containerNames.find(containerName => containerName === container.containerOrMachineName);

      if (scrapPowder && scrapPowder.startsWith('HCN')) {
        container.batchName = '';
        container.batchCssColor = '';
        container.mass = 0;
        container.lastSeiveCycle = 0;
        container.bookedForSource = false;
      } else if (scrapPowder &&  !scrapPowder.startsWith('HCN')) {
        container.powderName = '';
        container.powderCssColor = '';
        container.batchName = '';
        container.batchCssColor = '';
        container.mass = 0;
        container.lastSeiveCycle = 0;
        container.bookedForSource = false;
      }
    });

    this.containersSubject.next(fetchedContainers);
  }
}

