import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complete-powder-source',
  templateUrl: './complete-powder-source.component.html',
  styleUrls: ['./complete-powder-source.component.css']
})
export class CompletePowderSourceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
