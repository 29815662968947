import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { Observable } from 'rxjs';


/**
 * Handles the services to fetch Powder data from the backend
 */
@Injectable({
  providedIn: 'root'
})
export class ChangePropertiesService {

  /**
   * Back-end service URL
   */
  backendUrl = environment.apiUrl;
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '6200/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
  }

  changeProperties(container: IContainer): Observable<Object> {
    const path = this.STAGE + '/edit-powder-inventory';
    const endpoint = this.API_ROOT + path;

    return this.http.put(endpoint, {
      container: container
    });
  }

}
