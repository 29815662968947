import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QRCodeDialogData } from './QRCodeDialogData';
import { IContainer } from '../../powderpicker/IContainer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qrcodegenerator',
  templateUrl: './qrcodegenerator.component.html',
  styleUrls: ['./qrcodegenerator.component.css']
})
export class QrcodegeneratorComponent implements OnInit {


  /**
  * Dialog Title
  */
  dialogTitle: string;

  /**
   * Dialog Message
   */
  dialogMsg: string;

  public containers: IContainer[];

  rowClass: string[] = ["col-md-12 col-xs-12", "col-md-12 col-xs-12 btn-menu-row"];

  constructor(private dialogRef: MatDialogRef<QrcodegeneratorComponent>,
    @Inject(MAT_DIALOG_DATA) public qrDialog: QRCodeDialogData,
    private router: Router) {

    this.dialogTitle = qrDialog.dialogTitle;
    // this.dialogMsg = qrDialog.dialogMsg;
    this.containers = qrDialog.containers;

    this.generateQRSCreen(qrDialog);
  }


  ngOnInit() {
  }

  generateQRSCreen(qrDialog: QRCodeDialogData): void {

  }

  close(val: string) {

    if (val === 'cancle') {
      this.dialogRef.close();
      this.router.navigate(['/app/', 'powder-settings']);
    } else {
      this.dialogRef.close();
    }

  }

  print(): void {
  }

}
