import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DOCUMENT } from '@angular/common';
import { Cookies } from 'js-cookie';
import { SwitchEnvironment } from 'src/app/shared/powderpicker/switchEnvironment.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  window;
  userId: string;
  selectedvalue: string;
  languageSelected = 'English';
  Environment = ['Production' , 'Test' ];
  isUserATester = false;
  testers: string[] = ['z003pecb', 'z003e2ju', 'z0041hfv'];


  constructor(public authService: AuthService,
    public SwitchEnv: SwitchEnvironment,
    @Inject(DOCUMENT) private document: any,
    public translate: TranslateService) {
      translate.addLangs(['English', 'German']);
      translate.setDefaultLang('English');

      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/English|German/) ? browserLang : 'English');
    }

  ngOnInit() {
    this.userId = this.authService.getUserId();
    this.isUserATester = (this.testers.find(tester => tester === this.userId.toLowerCase())
    === undefined) ? false : true;
  }

  onLogout() {
    // this.window = window.open('https://myid.siemens.com/ext/myid/logout', '', 'width=600,height=400,left=200,top=200');
    // setTimeout(() => {
    //   this.window.close();
    //   this.document.location.href = 'https://ampowdermanagement.siemens.com/';
    // }, 1000);
    this.authService.logout();
  }

  switchEnvironment() {
    this.SwitchEnv.changeEnvironment(this.selectedvalue).subscribe(res => {
      const obj = JSON.parse(JSON.stringify(res));
      console.log('response from server ' , obj);
    });
  }
}
