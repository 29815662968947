import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { MachineParkListDataSource } from './machine-park-datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { Router } from '@angular/router';
import { ComponentCommunicationService } from 'src/app/services/component-communication.service';
import { MachineParkService } from './machine-park.service';
import { TranslateService } from '@ngx-translate/core';
import { QrcodegeneratorComponent } from 'src/app/shared/dialog/qrcodegenerator/qrcodegenerator.component';
import { QRCodeDialogData } from 'src/app/shared/dialog/qrcodegenerator/QRCodeDialogData';

@Component({
  selector: 'app-list-machine-park',
  templateUrl: './machine-park-list.component.html',
  styleUrls: ['./machine-park-list.component.css']
})
export class MachineParkListComponent implements OnInit, AfterViewInit, OnDestroy {


  dataSource: MachineParkListDataSource;
  displayedColumns: string[];
  searchString = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('input') input: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  btn: any;

  constructor(private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private machineParkService: MachineParkService,
    private router: Router,
    public translate: TranslateService,
    private compCommService: ComponentCommunicationService<IContainer>) { }

  ngOnInit() {
    this.displayedColumns = ['machinePicture', 'manufacturer', 'serialNumber',
    'powderName', 'batchName', 'mass', 'cycle', 'status', 'edit', 'generateQRCode' ];
    this.dataSource = new MachineParkListDataSource(this.machineParkService);
    this.dataSource.loadMachinesInPark('', 'asc', 0, 10);
  }

  ngAfterViewInit(): void {

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadMachinesInParkPage())
    )
      .subscribe();
  }

  loadMachinesInParkPage() {
    this.searchString = this.input.nativeElement.value;

    this.dataSource.loadMachinesInPark(
      this.searchString,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  ngOnDestroy(): void {
  }

  /**
   * New Machine Type Addition to Park
   */
  addMachineToParkNav(): void {
    this.compCommService.setCommObject(null);
    this.machineParkNavigator();
  }

  /**
   * Edit existing machine type in Park
   */
  editMachineParkNav(selectedMachine: IContainer): void {
    this.compCommService.setCommObject(selectedMachine);
    this.machineParkNavigator();
  }

  /**
   * Router instace to navigate to machine park form route
   */
  private machineParkNavigator(): void {
    this.router.navigate(['/app/machines/add-machine-to-park']);
  }

  checkBTN() {
    this.translate.get('BTN.add_matrl_typ_BTN').subscribe(value => this.btn = value);
  }

  /**
   * Generating QR code and showing the generated QR code to take a print
   * @param containerOrMachineName
   */
  generateQRCode(container: IContainer): void {
    container.containerType = container.containerOrMachineName;
    this.openQRPrintDialog(container);
  }

   /**
   * This method opens the QR(s) Dialog, for print.
   */
  openQRPrintDialog(container: IContainer): void {
    const formDialogData: QRCodeDialogData = new QRCodeDialogData();
    const containers: IContainer[] = [];
    containers.push(container);

    formDialogData.dialogTitle = 'Print QR CODES';
    formDialogData.dialogMsg = '';
    formDialogData.containers = containers;

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(QrcodegeneratorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('form dialog result : ' + JSON.stringify(result));

      this.dialog.closeAll();
    });
  }
}

