import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/modals/DialogData';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.css']
})

export class CommonModalComponent implements OnInit{

  ngOnInit(): void {
  }

  constructor(public dialogRef: MatDialogRef<CommonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    fb: FormBuilder) {
    }

  onNoClick(): void {
    if(this.data.no_text === 'No, I\'m Done') {
      this.dialogRef.close({ backScreen: 'start' })
    } else {
      this.dialogRef.close({ backScreen: 'no' });
    }
  }

  onYesClick(): void {
    if (this.data.myaction === 'actual_movement') {
      this.dialogRef.close({ backScreen: 'movement' })
    } else if (this.data.myaction === 'select_for_movement') {
      this.dialogRef.close({ backScreen: 'destination' })
    }  else if (this.data.myaction === 'no_source') {
      this.dialogRef.close({backScreen: 'noSource'});
    }  else if (this.data.myaction === 'no_destination') {
      this.dialogRef.close();
    } else if (this.data.myaction === 'time_out') {
      this.dialogRef.close({backScreen: 'timeOut'});
    } else if (this.data.myaction === 'discard_pwm_process') {
      this.dialogRef.close({ backScreen: 'start' })
    } else if (this.data.myaction === 'scrap_powder') {
      this.dialogRef.close({ backScreen: 'scrapPowder' })
    } else if (this.data.myaction === 'scrap_container') {
      this.dialogRef.close({ backScreen: 'scrapContainer' })
    } else if (this.data.myaction === 'new-delivery') {
      this.dialogRef.close({ backScreen: 'new-delivery' })
    } else {
      this.dialogRef.close({ backScreen: 'source' })
    }
  }

}
