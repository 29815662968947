import { AuthService } from './../services/auth.service';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, @Inject(DOCUMENT) private document: any) {

  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      const isAuth = this.authService.getIsAuthorized();
      if (!isAuth) {
        this.document.location.href = 'https://entitlement.siemens.com/GetAccess/Saml/IDP/SSO/Unsolicited?GA_SAML_SP=AMPowdermangement';
      }
      return isAuth;
  }

}
