import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPowderType } from 'src/app/modals/IPowderType';
import { MaterialTypeService } from './material-type.service';
import { MatSnackBar } from '@angular/material';
import { ComponentCommunicationService } from 'src/app/services/component-communication.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-material-type',
  templateUrl: './add-material-type.component.html',
  styleUrls: ['./add-material-type.component.css']
})
export class AddMaterialTypeComponent implements OnInit, OnDestroy {

  /**
   * Form group used for the form
   */
  materialTypeForm: FormGroup;

  commSub: Subscription;

  addAndEditMaterialTypeBtnTxt = 'Save';

  isAddMaterialTypeScreen = true;

  oldPowderName: string;
  powderId: string;

  constructor(public formBuilder: FormBuilder, private materialTypeService: MaterialTypeService,
    private snackBar: MatSnackBar, private compCommService: ComponentCommunicationService<IPowderType>,
    private router: Router) { }

  ngOnInit() {
    this.materialTypeForm = this.formBuilder.group({
      powderName: ['', [Validators.required]],
      comment: [''],
      granularityFactor: [100, [Validators.required, Validators.min(20), Validators.max(100)]],
      alloyDensity: [1, [Validators.required, Validators.min(1)]],
      labCheckAtCycle: [1, [Validators.required, Validators.min(1)]],
    });

    this.commSub = this.compCommService.getCommObject().subscribe(res => {
      console.log(JSON.stringify(res));

      if (res) {
        this.materialTypeForm.get('powderName').setValue(res.powderName);
        this.materialTypeForm.get('comment').setValue(res.comment);
        this.materialTypeForm.get('alloyDensity').setValue(res.alloyDensity);
        this.materialTypeForm.get('granularityFactor').setValue(res.granularityFactor);
        this.materialTypeForm.get('labCheckAtCycle').setValue(res.labCheckAtCycle);
        // this.addAndEditMaterialTypeBtnTxt = "Edit Material Type";
        this.oldPowderName = res.powderName;
        this.powderId = res._id;
        this.isAddMaterialTypeScreen = false;
        this.compCommService.setCommObject(null);
      }

    });

  }

  /**
   * Saving the new material type
   */
  addNewMaterialType(): void {

    const materialType: IPowderType = this.materialTypeForm.value as IPowderType;
    materialType.powderCssColor = this.randomHexCode();
    console.log('new material type : ' + materialType);

    if (this.isAddMaterialTypeScreen) {

      this.materialTypeService.addNewMaterialType(materialType).subscribe(res => {
        this.openSnackBar('New Material Type successfully created', 'New Material Type');
        this.viewMaterial();
      });
    } else {

      this.materialTypeService.editMaterialType(materialType, this.oldPowderName, this.powderId).subscribe(res => {
        this.openSnackBar('Material Type successfully edited', 'Edit Material Type');
        this.viewMaterial();
      });
    }
  }

  // function to reset the new-delivery form on click of reset
  reset(): void {
    // this.materialTypeForm.reset();
    // this.materialTypeForm.markAsUntouched({onlySelf: true});
    this.materialTypeForm.get('powderName').setValue(' ');
    this.materialTypeForm.get('comment').setValue('');
    this.materialTypeForm.get('alloyDensity').setValue(1);
    this.materialTypeForm.get('granularityFactor').setValue(100);
    this.materialTypeForm.get('labCheckAtCycle').setValue(1);

  }

  private randomHexCode(): string {
    return '#80' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }

  /**
   * Opens a toast / snacKbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }


  ngOnDestroy(): void {
    this.commSub.unsubscribe();
  }

  viewMaterial(): void {
    this.router.navigate(['/app/manage-material']);
  }

}
