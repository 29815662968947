import { Component, OnInit, ViewChildren, QueryList, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PowderOverviewChartComponent } from 'src/app/shared/powder-overview-chart/powder-overview-chart.component';
import { PowderPickerComponent } from 'src/app/shared/powderpicker/powder-picker.component';
import { IPowderType } from 'src/app/modals/IPowderType';
import { IBatch } from 'src/app/modals/IBatch';
import { ISeiveState } from 'src/app/modals/ISeiveState';
import { PowderPickerService } from 'src/app/shared/powderpicker/powder-picker-service';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExcelService } from '../../services/excel.service';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';

@Component({
  selector: 'app-powder-overview',
  templateUrl: './powder-overview.component.html',
  styleUrls: ['./powder-overview.component.css']
})
export class PowderOverviewComponent implements OnInit {

  /**
   * Chart data for powder types
   */
  chartPowderTypes: IPowderType[];

   /**
   * Chart data for batches
   */
  chartBatches: IBatch[] = [];

  /**
   * Used when component is loaded first time
   */
  defaultSelectedPowderType: string;

  /**
   * Default selected chart
   */
  selectedChart: string = 'batch';

  /**
   * Charts used for the second chart component in powder overview
   */
  charts: string[] = ['Batch', 'Cycle'];

  totalContainers: Array<IContainer>;
  totalContainersLength = 0;
  jsonToExport = [];
  exportExcel = false;

  @ViewChild(PowderPickerComponent) powderPickerComponent: PowderPickerComponent;

  @ViewChildren(PowderOverviewChartComponent) powderOverviewChartComponents: QueryList<PowderOverviewChartComponent>;

  constructor(
    private route: ActivatedRoute,
    private powderPickerService: PowderPickerService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.totalContainersLength = 10;
    this.totalContainers = new Array<IContainer>();
    this.getAllContainers();

    // getting the charts data from the resolver
    this.chartPowderTypes = this.route.snapshot.data['powderTypes'];

    this.defaultSelectedPowderType = this.chartPowderTypes[0].powderName;
    this.chartBatches = this.getChartBatches(this.defaultSelectedPowderType);
  }

  getAllContainers() {

    let filtermapping = new Map();
    filtermapping.set('sieved', false);
    filtermapping.set('unsieved', false);
    filtermapping.set('metal_containers', false);
    filtermapping.set('plastic_containers', false);
    filtermapping.set('machines', false);

    this.powderPickerService.findContainers('', '', 'powderName',
    filtermapping, 0, this.totalContainersLength, true, false,
      false, false, false,
      false, false, false,
      true).pipe(
        catchError(() => of([])),
        finalize(() => {})
      )
      .subscribe(containerDetail => {

        if (containerDetail[0] !== undefined) {
          this.jsonToExport = [];
          this.totalContainersLength = containerDetail[1].containerLength;
          this.totalContainers = containerDetail[0].containers;
          // console.log(containerDetail[0].containers);

          if (this.exportExcel) {
            this.exportExcel = false;
            this.totalContainers.forEach(container => {
              const Json = {
                PowderName: container.powderName,
                BatchName: container.batchName,
                ContainerOrMachineName: container.containerOrMachineName,
                SieveStatus: container.isSeived,
                Mass: container.mass,
                Cycle: container.lastSeiveCycle
              };

              this.jsonToExport.push(Json);
            });

            this.excelService.exportAsExcelFile(this.jsonToExport, 'powder-data');
          }
        }

      });
  }

  /**
   * Refresing the second chart data based on a click event on the first chart
   * @param powderName selected powder name from first chart on click event
   */
  refreshChildChart(powderName: string): void {
    this.defaultSelectedPowderType = powderName;
    this.powderPickerComponent.loadContainersOnChartClick(powderName);
    let chartComponent: PowderOverviewChartComponent = this.powderOverviewChartComponents.find(component => component.powderOverviewChartType !== 'powder');

    if(chartComponent.powderOverviewChartType === 'batch') {
      chartComponent.chartBatchData = this.getChartBatches(powderName);
      chartComponent.refreshChart(powderName);

    } else {
      chartComponent.chartSeiveStateData = this.getSeiveStates(powderName);
      chartComponent.refreshChart(powderName);
    }

    console.log('inside powder overview: ' + powderName);
  }


  /**
   * Getting the seive states based on powder name selected
   * @param powderName powder type name
   */
  private getSeiveStates(powderName: string) {
    let seiveStates: ISeiveState[] = []

    this.chartPowderTypes.forEach(powder => {
      if (powder.powderName === powderName) {
        seiveStates = powder.seiveStates;
      }
    });
    return seiveStates;
  }

  /**
   * Getting the chart batches based on powder name selected
   * @param powderName powder type name
   */
  private getChartBatches(powderName: string): IBatch[] {
    let batches: IBatch[] = [];

    this.chartPowderTypes.forEach(powder => {
      if (powder.powderName === powderName) {
        batches = powder.batches;
      }
    });
    return batches;
  }

  /**
   * Changing the chart on radio button change event
   * @param $event event for the selected chart value
   */
  changeChart($event): void {
    console.log('radio button event : ' + $event.value);

    const chartComponent: PowderOverviewChartComponent =
      this.powderOverviewChartComponents.find(component => component.powderOverviewChartType !== 'powder');
    chartComponent.powderOverviewChartType = $event.value;

    this.refreshChildChart(this.defaultSelectedPowderType);

  }

  exportToExcel() {
    this.exportExcel = true;
    this.getAllContainers();
  }
}
