import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { PowderOverviewChartService } from "./powder-overview-chart-service";
import { IPowderType } from "src/app/modals/IPowderType";

@Injectable({
    providedIn: 'root'
})
export class PowderOverviewChartResolver implements Resolve<IPowderType[]> {

    constructor(private powderOverviewChartService: PowderOverviewChartService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<IPowderType[]> {
        return this.powderOverviewChartService.getPowderOverviewChartsData();
    }
}
