import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scanning-screen',
  templateUrl: './scanning-screen.component.html',
  styleUrls: ['./scanning-screen.component.css']
})
export class ScanningScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
