import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IPowderType } from 'src/app/modals/IPowderType';
import { map, catchError } from 'rxjs/operators';
import { IMachineType } from 'src/app/modals/IMachineType';

/**
 * Handles the services to fetch Powder data from the backend
 */
@Injectable({
  providedIn: 'root'
})
export class MachineTypeService {

  /**
   * Back-end service URL
   */
  backendUrl = environment.apiUrl;
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '4300/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'machine-apis';
    }
  }

  /**
   * Creating a new machine type
   */
  addNewMachineType(machineType: IMachineType, machinePicture: string): Observable<Object> {
    const path = this.STAGE + '/machine-type';
    const endpoint = this.API_ROOT + path;

    return this.http.post(endpoint, {
      machineTypeData: machineType,
      machinePicture: machinePicture
    }).pipe(catchError(this.handleError));
  }

  /**
   * Finding the machine types based on certain parameters
   */
  findMachineTypes(
    search: string, sortOrder: string,
    pageNumber: number, pageSize: number):  Observable<IMachineType> {

    const path = this.STAGE + '/machine-types';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
        params: new HttpParams()
            .set('search', search)
            .set('sortOrder', sortOrder)
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
    }).pipe(
        map(res =>  res['machineDetails'])
    );
  }

  /**
   * Editing a machine type
   */
  editMachineType(machineType: IMachineType, machinePicture: string, _id: string): Observable<Object> {
    const path = this.STAGE + '/machine-type';
    const endpoint = this.API_ROOT + path;

    return this.http.put(endpoint, {
      machineTypeData: machineType,
      machinePicture: machinePicture,
      _id: _id
    }).pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = err.error;
    }
    return throwError(errorMessage);
  }
}
