import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavResolver implements Resolve<any> {

  constructor(private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot): void {
    if (route.queryParams['code']) {
      this.authService.signIn(route.queryParams['code']).subscribe(token => {
        this.authService.localStoreToken(token);
        this.authService.validateUser().subscribe(user => {
            this.authService.isAuthorized = true;
            this.authService.authStatusListener.next(true);
            this.authService.localStoreUser(user);
        }, err => console.error(err));
      });
    } else {
      this.authService.authStatusListener.subscribe(async authenticated => {
        if (authenticated) {
          this.authService.validateUser().subscribe(user => {
              this.authService.localStoreUser(user);
          }, err => console.error(err));
        } else {
          this.authService.redirectToSSO();
        }
      });
      this.authService.initAuth();
    }
  }
}
