import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-polluted-powder-target',
  templateUrl: './polluted-powder-target.component.html',
  styleUrls: ['./polluted-powder-target.component.css']
})
export class PollutedPowderTargetComponent implements  OnInit {
    
  constructor() { }
  
  ngOnInit() {
  } 
}
