import { Component, OnInit, ViewChild, ViewChildren, QueryList, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { PowderPickerComponent } from 'src/app/shared/powderpicker/powder-picker.component';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { Router, ActivatedRoute } from '@angular/router';
import { PowderPickerService } from 'src/app/shared/powderpicker/powder-picker-service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from 'src/app/shared/dialog/common-modal.component';
import { PowderOverviewChartComponent } from 'src/app/shared/powder-overview-chart/powder-overview-chart.component';
import { IPowderType } from 'src/app/modals/IPowderType';
import { IBatch } from 'src/app/modals/IBatch';
import { ISeiveState } from 'src/app/modals/ISeiveState';
import { AuthService } from 'src/app/services/auth.service';
import { TimerService } from 'src/app/services/timer.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.css']
})

export class SourceComponent implements OnInit, AfterViewInit, OnDestroy {

  timerSub: Subscription;
  Title: any;
  Mssg: any;
  Button: any;

  constructor(
    private router: Router,
    private powderPickerService: PowderPickerService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private timerService: TimerService,
    public translate: TranslateService,
    private mediaObserver: MediaObserver,
  ) {
    this.userId = authService.getUserId();
  }

  yes_text: string;
  no_text: string;
  dialogTitle: string;
  dialogMsg: string;
  myaction: string;
  userId: string = null;

  alloyDensity: number;
  granularityFactor: number;

  @Input()
  showChart = true;

  /**
  * Identifies the source / destination screen for sieve powder module
  */
  @Input()
  isSieveScreen = false;

  /**
   * Identifies the source / destination screen for mix batches module
   */
  @Input()
  isMixBatchScreen = false;

  /**
  * Identifies the source / destination screen for load machine module
  */
  @Input()
  isLoadMachineScreen = false;

  /**
  * Identifies the source / destination screen for machine unload unused powder module
  */
  @Input()
  isUnloadUnusedPowder = false;

  /**
  * Identifies the source / destination screen for machine unload complete powder module
  */
  @Input()
  isUnloadCompletePowder = false;

  /**
  * Identifies the source / destination screen for machine unload polluted powder module
  */
  @Input()
  isUnloadPollutedPowder = false;

  @ViewChild(PowderPickerComponent) picker;

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(CommonModalComponent,{
  //     width: '250px',
  //     data: {myaction: this.myaction,  dialogTitle: this.dialogTitle, dialogMsg: this.dialogMsg, yes_text: this.yes_text, no_text: this.no_text}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {

  //     // if(result.backScreen === 'timeOut') {
  //     //   // dialog with a yes click for time out
  //     //   this.powderPickerService.increaseTransTimeLimit();

  //     // } else if (result.backScreen === 'start') {
  //     //   // dialog with a no for time out
  //     //   this.powderPickerService.completePowderMovement().subscribe(res => {
  //     //     console.log(JSON.stringify(res));
  //     //     this.powderPickerService.setTransactionTimestamp(0);
  //     //   });

  //     // }
  //   });
  // }


  /**
   * Chart data for powder types
   */
  chartPowderTypes: IPowderType[];

  /**
  * Chart data for batches
  */
  chartBatches: IBatch[] = [];

  /**
   * Used when component is loaded first time
   */
  defaultSelectedPowderType: string;

  /**
  * Default selected chart
  */
  selectedChart = 'batch';

  /**
   * Charts used for the second chart component in powder overview
   */
  charts: string[] = ['Batch', 'Cycle'];

  /**
   * array for Time Stamp table
   */
  timestamparray: string[] = new Array();

  // Subscription of the observer of the screen size
  mediaQuery$: Subscription;

  // The active media query (xs | sm | md | lg | xl)
  activeMediaQuery: string;


  @ViewChild(PowderPickerComponent) powderPickerComponent: PowderPickerComponent;

  @ViewChildren(PowderOverviewChartComponent) powderOverviewChartComponents: QueryList<PowderOverviewChartComponent>;


  ngOnInit() {

    if (this.showChart) {
      // getting the charts data from the resolver
      this.chartPowderTypes = this.route.snapshot.data['powderTypes'];

      this.defaultSelectedPowderType = this.chartPowderTypes[0].powderName;
      this.chartBatches = this.getChartBatches(this.defaultSelectedPowderType);
    }
  }

  ngAfterViewInit(): void {
    this.mediaQuery$ = this.mediaObserver.media$.subscribe( (change: MediaChange) => {
      this.activeMediaQuery = `${change.mqAlias}`;
  });
  }

  ngOnDestroy () {
    this.mediaQuery$.unsubscribe();
  }

  selectContainerViaQRCode() {
    this.powderPickerComponent.selectContainerViaQRCode();
  }

  getSelectedContainers() {

    const containers: IContainer[] = this.picker.getSelectedContainers();
    let isAnythingSelected: boolean = false;
    for (const container of containers) {
      this.timestamparray.push(container.containerOrMachineName);
      if (container.bookedForSource === true) {
        isAnythingSelected = true;
      }
    }
    console.log('containers selected = ', this.timestamparray);

    if (containers.length > 0 && isAnythingSelected) {
      let user = this.authService.getUserId();
      this.powderPickerService.changecontainer(this.timestamparray, user);
      this.powderPickerService.addToSourceDesContainers(containers, true, this.authService.getUserId()).subscribe(res => {

        //IF the res for isSuccess is true start the timer function
        if (JSON.parse(JSON.stringify(res)).isSuccess === true) {
          this.timerService.startTimer(0);
        }

        const currTimestamp = JSON.parse(JSON.stringify(res)).currTimestamp;

        this.powderPickerService.setTransactionTimestamp(currTimestamp);

        this.granularityFactor = containers[0].granularityFactor;
        this.alloyDensity = containers[0].alloyDensity;

        if (this.isSieveScreen) {
          this.router.navigate(['/app/powder-settings/', 'sieve-target'],
          {
            queryParams: { granularityFactor: this.granularityFactor , alloyDensity: this.alloyDensity  }
          });
        } else if (this.isMixBatchScreen) {
          this.router.navigate(['/app/powder-settings/', 'mix-batch-target'],
          {
            queryParams: { granularityFactor: this.granularityFactor , alloyDensity: this.alloyDensity  }
          });
        } else if (this.isLoadMachineScreen) {
          this.router.navigate(['/app/powder-settings/', 'load-machine-target'],
          {
            queryParams: { granularityFactor: this.granularityFactor , alloyDensity: this.alloyDensity  }
          });
        } else if (this.isUnloadUnusedPowder) {
          this.router.navigate(['/app/powder-settings/unload-machine', 'unload-unused-powder-target'],
          {
            queryParams: { granularityFactor: this.granularityFactor , alloyDensity: this.alloyDensity  }
          });
        } else if (this.isUnloadCompletePowder) {
          this.router.navigate(['/app/powder-settings/unload-machine', 'unload-complete-powder-target'],
          {
            queryParams: { granularityFactor: this.granularityFactor , alloyDensity: this.alloyDensity  }
          });
        } else if (this.isUnloadPollutedPowder) {
          this.router.navigate(['/app/powder-settings/unload-machine', 'unload-polluted-powder-target'],
          {
            queryParams: { granularityFactor: this.granularityFactor , alloyDensity: this.alloyDensity  }
          });
        }
      });
      // console.log('.... ' + JSON.stringify(containers));
    } else {
      this.translate.get('Dialog.Title1').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message1').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.ok_BTN').subscribe(value => this.Button = value);
      this.myaction = 'no_source';
      this.no_text = '';
      this.yes_text = this.Button;
      this.dialogTitle = this.Title;
      this.dialogMsg = this.Mssg;

      this.openDialog();
    }
  }

  /**
   * Refresing the second chart data based on a click event on the first chart
   * @param powderName selected powder name from first chart on click event
   */
  refreshChildChart(powderName: string): void {
    this.defaultSelectedPowderType = powderName;
    this.powderPickerComponent.loadContainersOnChartClick(powderName);
    const chartComponent: PowderOverviewChartComponent = this.powderOverviewChartComponents.find(
      component => component.powderOverviewChartType !== 'powder');

    if (chartComponent.powderOverviewChartType === 'batch') {
      chartComponent.chartBatchData = this.getChartBatches(powderName);
      chartComponent.refreshChart(powderName);

    } else {
      chartComponent.chartSeiveStateData = this.getSeiveStates(powderName);
      chartComponent.refreshChart(powderName);
    }

    console.log('inside powder overview: ' + powderName);
  }

  /**
  * Getting the seive states based on powder name selected
  * @param powderName powder type name
  */
  private getSeiveStates(powderName: string) {
    let seiveStates: ISeiveState[] = [];

    this.chartPowderTypes.forEach(powder => {
      if (powder.powderName === powderName) {
        seiveStates = powder.seiveStates;
      }
    });
    return seiveStates;
  }

  /**
   * Getting the chart batches based on powder name selected
   * @param powderName powder type name
   */
  private getChartBatches(powderName: string): IBatch[] {
    let batches: IBatch[] = [];

    this.chartPowderTypes.forEach(powder => {
      if (powder.powderName === powderName) {
        batches = powder.batches;
      }
    });
    return batches;
  }

  /**
   * Changing the chart on radio button change event
   * @param $event event for the selected chart value
   */
  changeChart($event): void {
    console.log('radio button event : ' + $event.value);

    const chartComponent: PowderOverviewChartComponent = this.powderOverviewChartComponents.find(
      component => component.powderOverviewChartType !== 'powder');
    chartComponent.powderOverviewChartType = $event.value;

    this.refreshChildChart(this.defaultSelectedPowderType);

  }


  openDialog(): void {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '250px',
      data: {
        myaction: this.myaction,
        dialogTitle: this.dialogTitle,
        dialogMsg: this.dialogMsg,
        yes_text: this.yes_text,
        no_text: this.no_text
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
