import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { MatSnackBar } from '@angular/material';
import { ComponentCommunicationService } from 'src/app/services/component-communication.service';
import { IModelData } from 'src/app/modals/IModelData';
import { IMachineMetaData } from 'src/app/modals/IMachineMetaData';
import { MachineParkService } from '../machine-park-list/machine-park.service';

@Component({
  selector: 'app-add-machine-park',
  templateUrl: './add-machine-park.component.html',
  styleUrls: ['./add-machine-park.component.css']
})
export class AddMachineParkComponent implements OnInit, OnDestroy {

  errorMessage = '';
  startDate: Date;
  modelData: IModelData[];

  /**
   * To get a reference of the machine which
   * a user has chosen to update inside of the
   * park.
   */
  machine: IContainer;

  /**
   * Form group used for the form
   */
  machineParkForm: FormGroup;

  commSub: Subscription;

  isAddMachineToParkScreen = true;

  existingModelNumber: string;

  constructor(public formBuilder: FormBuilder,
    private machineParkService: MachineParkService,
    private snackBar: MatSnackBar,
    private router: Router,
    private compCommService: ComponentCommunicationService<IContainer>,
    private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.startDate = new Date(1990, 0, 1);
    this.machineParkForm = this.formBuilder.group({
      model: ['', [Validators.required]],
      serialNumber: ['', [Validators.required]],
      deliveryDate: ['', [Validators.required]],
      sapNumber: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

    this.commSub = this.compCommService.getCommObject().subscribe(res => {
      if (res) {
        this.machine = res;
        this.setData(res);
      } else {
        this.machine = undefined;
      }
    });

    this.modelData = this.route.snapshot.data['models'];
  }


  /**
   * Adding the machine to Park.
   */
  addMachineToPark(): void {

    const machineInPark: IMachineMetaData = this.machineParkForm.value as IMachineMetaData;

    for (let i = 0; i < this.modelData.length; i++) {
      if (this.modelData[i].model === this.machineParkForm.value.model) {
        machineInPark._id = this.modelData[i].id;
      }
    }

    if (!this.machine) {
      this.machineParkService.addNewMachineInMachinePark(machineInPark)
        .subscribe(res => {
          this.parseResult(res);
        },
        error => {
          this.errorUIUpdate(error);
         });
    } else {
      this.machineParkService.editMachineInMachinePark(machineInPark,
        this.machine._id).subscribe(res => {
          this.parseResult(res);
          this.viewPark();
        },
        error => {
          this.errorUIUpdate(error);
        });
    }

  }


  /**
   * This method parses the JSON response received after
   * a call to newDelivery Service
   */
  private parseResult(res: Object): void {
    const obj = JSON.parse(JSON.stringify(res));
    if (obj.isSuccess === true) {
      this.openSnackBar('Machine Successfuly Added to Machine Park', 'Machine Park');
      this.viewPark();
    }
  }

   /**
   * This method handles the response json for a failure due to uniqueness
   * conflict for SAP and SERIAL Number values after the response from
   * newDelivery Service
   */
  private errorUIUpdate(error: Object) {
    const obj = JSON.parse(JSON.stringify(error));
    if (obj.isSerialNumber) {
      this.machineParkForm.get('serialNumber').setErrors({'alreadyexists': true});
    } else if (obj.isSapNumber) {
      this.machineParkForm.get('sapNumber').setErrors({'alreadyexists': true});
    }
  }

  /**
   * Opens a toast / snacbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  /**
   * Resets the form to pneultimate state.
   */
  reset(): void {
    this.machineParkForm.reset();
    this.machineParkForm.markAsUntouched({ onlySelf: true });

    this.machineParkForm.get('model').setValue('');
    this.machineParkForm.get('serialNumber').setValue('');
    this.machineParkForm.get('sapNumber').setValue('');
    this.machineParkForm.get('deliveryDate').setValue('');
    this.machineParkForm.get('status').setValue('');
  }


 /**
   * Sets the form data for the Machine In Edit
   */
  setData(machine: IContainer): void {
    this.machineParkForm.get('model').setValue(machine.mData.model);
    this.machineParkForm.get('serialNumber').setValue(machine.machineDetails.serialNumber);
    this.machineParkForm.get('sapNumber').setValue(machine.machineDetails.sapNumber);
    this.machineParkForm.get('deliveryDate').setValue(machine.machineDetails.deliveryDate);
    this.machineParkForm.get('status').setValue(machine.machineDetails.status);
  }

  /**
   * Naviates the user to Machine Park List Screen.
   */
  viewPark(): void {
    this.router.navigate(['/app/machines/manage-machine-park']);
  }

  ngOnDestroy(): void {
    this.commSub.unsubscribe();
  }

}
