import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { BatchHistoryService } from './batch-history.service';
import { IBatchHistoryDetails } from './IBatchHistoryDetail';

@Injectable({
    providedIn: 'root'
})
export class BatchHistoryDataResolver implements Resolve<IBatchHistoryDetails> {

    constructor(private batchHistoryService: BatchHistoryService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<IBatchHistoryDetails> {
        return this.batchHistoryService.fetchBatchHistoryDetails();
    }
}
