import { AuthService } from './../../services/auth.service';
import { TimerService } from 'src/app/services/timer.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navdata',
  template: '<div></div>'
})
export class NavdataComponent implements OnInit {

  constructor(private router: Router) {

  }

  ngOnInit() {
    this.router.navigate(['/app']);
  }

}
