import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScannerService } from '../scanning-screen/scanner.service';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.css']
})
export class QrScannerComponent implements OnInit {

  qrResultString = '';
  torchEnabled = true;
  scannerEnabled = true;
  showCameraSwitch = false;
  activeDeviceId = '';

  activeCamera: MediaDeviceInfo = null;
  allCameras: MediaDeviceInfo[];

  constructor(private scanner: ScannerService) { }

  ngOnInit() {

  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.scanner.changeQRResponse(this.qrResultString);
    this.disableScanner();
  }

  enableScanner() {
    this.scannerEnabled = true;
  }

  disableScanner() {
    this.scannerEnabled = false;
  }

  /**
   * ZXing Library's callback function, receives a hit when the
   * cameras are identified
   */
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.allCameras = devices;
    this.activeCamera = this.allCameras[0];
    this.activeDeviceId = this.allCameras[0].deviceId;

    if (this.allCameras.length > 1) {
      this.showCameraSwitch = true;
    }
  }

  /**
   *
   * @param deviceId
   *
   * The Default Camera on some devices is picked as the front
   * therefore the switch camera button helps in these scenarios,
   * here we toggle the activecamera instance by checking for the
   * active camera's deviceId
   */
  swtichCamera(deviceId: string): void {

    const toggledDevice = this.allCameras.find(device => (device.deviceId !== deviceId));
    this.activeDeviceId = toggledDevice.deviceId;
    this.activeCamera = toggledDevice;
  }

}
