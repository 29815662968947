import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-complete-powder-target',
  templateUrl: './complete-powder-target.component.html',
  styleUrls: ['./complete-powder-target.component.css']
})
export class CompletePowderTargetComponent implements OnInit {
    
  constructor() { }
  
  ngOnInit() {
  } 

}
