import { DataSource } from "@angular/cdk/table";
import { CollectionViewer } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { IPowderType } from "src/app/modals/IPowderType";
import { MaterialTypeService } from "../add-material-type/material-type.service";

export class MaterialTypeDataSource implements DataSource<IPowderType> {

  private materialTypesSubject = new BehaviorSubject<IPowderType[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private noOfMaterialTypesSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public noOfMaterialTypes$ = this.noOfMaterialTypesSubject.asObservable();


  constructor(private materialTypeService: MaterialTypeService) { }

  connect(collectionViewer: CollectionViewer): Observable<IPowderType[]> {
    return this.materialTypesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.materialTypesSubject.complete();
    this.noOfMaterialTypesSubject.complete();
    this.loadingSubject.complete();
  }

  loadMaterialTyes(
    search: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number) {

    this.loadingSubject.next(true);
    
    this.materialTypeService.findMaterialTypes(search, sortDirection,pageIndex, pageSize).
      pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(materialTypeDetails => {

        this.materialTypesSubject.next(materialTypeDetails[0].materialTypes);
        this.noOfMaterialTypesSubject.next(materialTypeDetails[1].materialTypeLength);
      });
  }
}

