import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-action-thumbnail',
  templateUrl: './action-thumbnail.component.html',
  styleUrls: ['./action-thumbnail.component.css']
})
export class ActionThumbnailComponent implements OnInit, AfterViewInit, OnDestroy {

  // Subscription of the observer of the screen size
  mediaQuery$: Subscription;

  // The active media query (xs | sm | md | lg | xl)
  activeMediaQuery: string;

  @Input() action: Imenuoption;

  @Input() cssCardHeight: number;
  Home: any;
  index: any;
  HomeScreen: any;
  cardname: any;


  constructor(private mediaObserver: MediaObserver,
    public translate: TranslateService) {
   }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.mediaQuery$ = this.mediaObserver.media$.subscribe( (change: MediaChange) => {
      this.activeMediaQuery = `${change.mqAlias}`;
  });
  }

  ngOnDestroy () {
    this.mediaQuery$.unsubscribe();
  }

  // [translate]="changeTranslation(action.name)"
  // changeTranslation(value) {
  //   // console.log('value = ' + value);
  //   this.translate.get('Home.Cards').subscribe(value => this.Home = value);
  //   this.translate.get('HomeScreen.Cards').subscribe(value => this.HomeScreen = value);
  //   for (const title of this.Home) {
  //     if (title === value) {
  //       console.log('title == ' + title + 'index == ' + this.Home.indexOf(title));
  //       this.index = this.Home.indexOf(title);
  //     }
  //   }

  //   for (const title of this.HomeScreen) {
  //     console.log('german value == ' + this.HomeScreen[this.index] + 'index == ' + this.index);
  //     this.cardname = this.HomeScreen[this.index];
  //   }
  // }

}
