import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SwitchEnvironment {

  backendUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  // changeEnvironment(Env: string): Observable<Object> {
  //   return this.http.put(this.backendUrl + 'api/switchEnvironment', {
  //     requestedEnv: Env
  //   });
  // }

  changeEnvironment(env: string): Observable<Object> {
    return this.http.post(this.backendUrl + 'api/switchEnvironment', {
      env: env
    });
  }
}



