import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IContainer } from './IContainer';
import { PowderPickerDataSource } from './powder-picker-datasource';
import { PowderPickerService } from './powder-picker-service';
import { MatPaginator, MatSort, MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { fromEvent, merge, Subscription } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DiscardDialogHelper } from './DiscardDialogHelper';
import { IScreens } from './IScreens';
import { CommonModalComponent } from '../dialog/common-modal.component';
import { QRCodeDialogData } from '../dialog/qrcodegenerator/QRCodeDialogData';
import { QrcodegeneratorComponent } from '../dialog/qrcodegenerator/qrcodegenerator.component';
import { AuthService } from 'src/app/services/auth.service';
import { TimerService } from "src/app/services/timer.service";
import { TranslateService } from '@ngx-translate/core';
import { MaterialTypeService } from 'src/app/wizards/add-material-type/material-type.service';
import { IPowderType } from 'src/app/modals/IPowderType';
import { ScannerService } from '../qr-scanner/scanner.service';
import { QrScannerComponent } from '../qr-scanner/qr-scanner.component';

@Component({
  selector: 'app-powder-picker',
  templateUrl: './powder-picker.component.html',
  styleUrls: ['./powder-picker.component.css']
})
export class PowderPickerComponent implements OnInit, AfterViewInit, OnDestroy {
  Title: any;
  Mssg: any;
  YButton: any;
  NButton: any;
  Mssg1: any;
  Mssg2: any;

  scanQR = false;

  constructor(private router: Router,
    private powderPickerService: PowderPickerService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private timerService: TimerService,
    public translate: TranslateService,
    private materialTypeService: MaterialTypeService,
    private scannerService: ScannerService,
    private mediaObserver: MediaObserver,
    ) {
    this.discardDialogHelper = new DiscardDialogHelper(router,
      powderPickerService,
      dialog,
      authService,
      timerService,
      translate);
  }

  /**
   * This works as an input for change properties screen.
   */
  @Input()
  isChangePropertiesScreen = false;

  /**
   * This would be used in powder-movement module
   * to filter out the data based upon the selected
   * batch name or powder type.
   */
  activeBatchOrPowderSearchName = '';

  /**
   * A mat-select field with this name is present
   * in the html file of powder-picker.component
   */
  filters = new FormControl();

  /**
   * Data visible to the user in the filters form-control.
   */
  filtersList: string[] = ['Sieved', 'Unsieved', 'Metal Containers', 'Plastic Containers', 'Machines'];

  /**
   * The data of filter is sent as a key-value pair
   * inside of the loadcontainers function i.e. to the backend.
   */
  filtermapping = new Map();


  /**
   * Whatever user types in the search box is captured
   * in the ngModel
   */
  searchString = '';

  @Input()
  pageSize = 10;

  @Input()
  showSelection = false;

  @Input()
  showCapacityAndLockedBy = false;

  @Input()
  showSearch = false;

  @Input()
  showFilter = false;

  @Input()
  showDiscard = false;

  /**
   * In powder overiew screen we would like to show the containers with
   * the sieve status
   */
  @Input()
  showIsSieved = false;

 /**
   * In powder overiew screen we would like to show the containers with
   * the sieve status
   */
  @Input()
  selectedByUserId = false;

  @Input()
  isSource = true;

  @Input()
  userId: string = null;

  @Input()
  isPowderMovementScreen = false;


  /**
   * For the purpose of displaying Container or Machine in the Table Header
   */
  containerOrMachineLabel = '';

  /**
   * Identifies the source / destination screen for sieve powder module
   */
  @Input()
  isSieveScreen = false;

  /**
   * Identifies the source / destination screen for mix batches module
   */
  @Input()
  isMixBatchScreen = false;

  /**
   * Identifies the source / destination screen for load machine module
   */
  @Input()
  isLoadMachineScreen = false;

  /**
  * Identifies the source / destination screen for machine unload unused powder module
  */
  @Input()
  isUnloadUnusedPowder = false;

  /**
  * Identifies the source / destination screen for machine unload complete powder module
  */
  @Input()
  isUnloadCompletePowder = false;

  /**
  * Identifies the source / destination screen for machine unload polluted powder module
  */
  @Input()
  isUnloadPollutedPowder = false;

  /**
   * Identifier for listing containers screen
   */
  @Input()
  isListContainerScreen = false;

  /**
  * Identifies the powder overview screen
  */
  @Input()
  isPowderOverviewScreen = false;

  @Output()
  massEmitter = new EventEmitter<any>();

  /**
   * Used for changing properties
   */
  @Output()
  changePropertiesEmitter = new EventEmitter<any>();

  dataSource: PowderPickerDataSource;
  displayedColumns: string[];

  /**
   * Helper object to Call the Discard Dialog
   */
  discardDialogHelper: DiscardDialogHelper;

  /**
   * Different screen identification
   */
  pmScreens: IScreens;

  materialTypes: Array<IPowderType>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('input') input: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(QrScannerComponent) QrScannerComponent: QrScannerComponent;

  // Subscription of the observer of the screen size
  mediaQuery$: Subscription;

  // The active media query (xs | sm | md | lg | xl)
  activeMediaQuery: string;


  powderTypes: IContainer[];

  ngAfterViewInit(): void {

    this.mediaQuery$ = this.mediaObserver.media$.subscribe( (change: MediaChange) => {
      this.activeMediaQuery = `${change.mqAlias}`;
    });

    /**
     *  Reset the paginator after sorting
     *  e.g. if the user is on a different page and he sorts the
     *  table data w.r.t any column he gets navigated to the first page
     */
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    if (this.showSearch) {

      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;

            this.loadContainersPage(this.activeBatchOrPowderSearchName, false);
          })
        )
        .subscribe();
    }

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadContainersPage(this.activeBatchOrPowderSearchName, false))
    )
      .subscribe();

  }

  ngOnInit() {

    this.setupScreen();

    this.filtermapping.set('sieved', false);
    this.filtermapping.set('unsieved', false);
    this.filtermapping.set('metal_containers', false);
    this.filtermapping.set('plastic_containers', false);
    this.filtermapping.set('machines', false);

    this.scannerService.currentQRResponse.subscribe( qrResultString => {
      if (qrResultString !== 'qr-response-source') {
        this.scannerService.findContainerDetails(qrResultString).subscribe( containerDetail => {
          const tempContainers = this.getTemporaryContainers();
          tempContainers.forEach( container => {
            if (container.containerOrMachineName === containerDetail[0].containerOrMachineName) {
              this.selectContainer(true, container);
            }
          });
        });
      }
    });

    if (this.showCapacityAndLockedBy) {
      this.displayedColumns = ['powderName', 'batchName', 'containerOrMachineName', 'mass', 'seiveCycle', 'capacity', 'selectedByUserId'];
    } else {

      if (this.isPowderMovementScreen === true) {
        this.displayedColumns = ['powderName', 'batchName', 'containerOrMachineName', 'mass', 'seiveCycle', 'capacity'];
      } else {
        this.displayedColumns = ['powderName', 'batchName', 'containerOrMachineName', 'showIsSieved', 'mass', 'seiveCycle'];
      }
    }

    if (this.isListContainerScreen) {
      this.displayedColumns.push('generateQRCode');
      this.displayedColumns.push('edit');
    }

    if (this.showSelection) {
      this.displayedColumns.push('select');
    }

    if (this.isChangePropertiesScreen) {
      this.displayedColumns.push('edit');
    }

    this.dataSource = new PowderPickerDataSource(this.powderPickerService, this.authService);

    this.materialTypeService.findMaterialTypes(
      '',
      'asc',
      0,
      10
      ).subscribe(res => {
        this.materialTypes = res[0].materialTypes;
        this.dataSource.loadContainers('',
        'asc',
        'powderName',
        this.filtermapping,
        0, this.pageSize,
        this.isSource,
        this.isSieveScreen,
        this.isMixBatchScreen,
        this.isLoadMachineScreen,
        this.isUnloadUnusedPowder,
        this.isUnloadCompletePowder,
        this.isUnloadPollutedPowder,
        this.isPowderMovementScreen,
        this.isListContainerScreen,
        this.isPowderOverviewScreen,
        this.materialTypes);
      });
  }


  /**
   * hasUserChecked is true only when the user selects the checkbox
   * whereas the value is false when navigating between the screens
   * or when the screen is opened for the first time
   */
  loadContainersPage(batchName: string, hasUserChecked: boolean) {

    if (this.showSearch) {
      this.searchString = this.input.nativeElement.value;
    }

    const containers: IContainer[] = this.getSelectedContainers();

    if (containers.length > 0) {
     if (!this.isMixBatchScreen) {
        batchName = containers[0].batchName;
      } else {
        batchName = containers[0].powderName;
      }
    }

    this.dataSource.loadContainers(
      batchName === '' ? this.searchString : (batchName + '#99#' + this.searchString),
      this.sort.direction,
      this.sort.active,
      this.filtermapping,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.isSource,
      this.isSieveScreen,
      this.isMixBatchScreen,
      this.isLoadMachineScreen,
      this.isUnloadUnusedPowder,
      this.isUnloadCompletePowder,
      this.isUnloadPollutedPowder,
      this.isPowderMovementScreen,
      this.isListContainerScreen,
      this.isPowderOverviewScreen,
      this.materialTypes);
  }

  loadContainersOnChartClick(powderName: string): void {
    this.searchString = powderName;
    this.dataSource.loadContainers(
      powderName,
      this.sort.direction,
      this.sort.active,
      this.filtermapping,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.isSource,
      this.isSieveScreen,
      this.isMixBatchScreen,
      this.isLoadMachineScreen,
      this.isUnloadUnusedPowder,
      this.isUnloadCompletePowder,
      this.isUnloadPollutedPowder,
      this.isPowderMovementScreen,
      this.isListContainerScreen,
      this.isPowderOverviewScreen,
      this.materialTypes);
  }

  getSelectedContainers(): IContainer[] {
    return this.dataSource.getSelectedContainers(this.isSource);
  }

  getTemporaryContainers(): IContainer[] {
    return this.dataSource.getTemporaryContainers();
  }

  getSelectedContainerForMovement(): IContainer[] {
    return this.dataSource.getSelectedContainersForMovement();
  }

  checkDestFormState(): boolean {
    return this.dataSource.checkDestFormState();
  }

  selectContainerViaQRCode() {
    this.QrScannerComponent.enableScanner();
    this.scanQR = true;
  }

  /**
   * Called on checkbox selection and modifies the temporary container list
   *
   * @param $event checkbox event
   * @param selectedContainer selected cotainer
   */
  selectContainer($event, selectedContainer: IContainer): void {
    console.log(selectedContainer);

    this.dataSource.modifyTemporaryContainerForSelection(selectedContainer,
      $event,
      this.isSource,
      this.isPowderMovementScreen,
      this.userId,
      this.pmScreens);

    if ($event === true && !this.isPowderMovementScreen && this.isSource) {

      if (!this.isMixBatchScreen) {
        this.activeBatchOrPowderSearchName = selectedContainer.batchName;
      } else {
        this.activeBatchOrPowderSearchName = selectedContainer.powderName;
      }
      if (this.isListContainerScreen) {
        this.activeBatchOrPowderSearchName = '';
      }

      if (this.getSelectedContainers().length === 1 && !this.isListContainerScreen) {
        this.loadContainersPage(this.activeBatchOrPowderSearchName, true);
      }
    } else {

      if (this.getSelectedContainers().length === 0 && !this.isPowderMovementScreen && this.isSource && !this.isListContainerScreen) {
        this.activeBatchOrPowderSearchName = '';
        this.loadContainersPage(this.activeBatchOrPowderSearchName, true);
      }
    }


    if (this.isPowderMovementScreen && this.isSource) {
      this.emitTotalSelectedMass();
    }
  }

  filterData(): void {

    const filterArray: string[] = this.filters.value;

    this.filtermapping.forEach((value: string, key: string) => {
      const containes = filterArray.find(filter => {
        return filter.toLowerCase().replace(' ', '_') === key;
      });
      if (containes) {
        this.filtermapping.set(key, true);
      } else {
        this.filtermapping.set(key, false);
      }

    });

    this.dataSource.loadContainers(
      this.searchString,
      this.sort.direction,
      this.sort.active,
      this.filtermapping,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.isSource,
      this.isSieveScreen,
      this.isMixBatchScreen,
      this.isLoadMachineScreen,
      this.isUnloadUnusedPowder,
      this.isUnloadCompletePowder,
      this.isUnloadPollutedPowder,
      this.isPowderMovementScreen,
      this.isListContainerScreen,
      this.isPowderOverviewScreen,
      this.materialTypes);
  }

  /**
   * This method emits the Total Container
   * Mass of the selected container(s) which is required
   * at the powder-movement screen to populate the select mass
   * input field.
   */
  emitTotalSelectedMass(): void {
    const selectedContainers: IContainer[] = this.getSelectedContainerForMovement();
    let mass = 0;
    let allowEdit = true;
    if (selectedContainers.length > 1) {
      allowEdit = false;
    }

    if (selectedContainers.length > 0) {

      selectedContainers.forEach(container => {
        mass = mass + container.mass;
      });
      this.massEmitter.emit({ totalMass: mass, allowEdit: allowEdit });
    } else {
      this.massEmitter.emit({ totalMass: 1, allowEdit: allowEdit });
    }

  }

  discardMovement(): void {
    this.discardDialogHelper.discardMovement();
  }

  /**
   * This method is used to trigger the change-properties
   * dialog which is present inside of ChangePropertiesComponent.
   */
  openChangePropertiesDialog($event, selectedContainer: IContainer): void {
    $event.stopPropagation();
    console.log('selectedContainer === ', selectedContainer);
    console.log('isChangePropertiesScreen == ' , this.isChangePropertiesScreen);
    console.log('isListContainerScreen == ' , this.isListContainerScreen);
    if (this.isChangePropertiesScreen || this.isListContainerScreen) {
      console.log('true');
      this.changePropertiesEmitter.emit(selectedContainer);
    }
  }

  /**
   * Removing the containers / machines with mass 0 from the source
   */
  public removeSrcEmptyContainerAndMachines(): void {
    this.dataSource.removeSrcEmptyContainerAndMachines();
  }

  /**
   * getting Source containers / machines
   */
  public getSrcContainerAndMachines() {
    return this.dataSource.getSrcContainerAndMachines();
  }

  /**
   * Redirect to New container creation page
   */
  newContainerDelivery(): void {
    this.router.navigate(['/app/', 'add-container']);
  }

  private setupScreen(): void {

    this.pmScreens = new IScreens();

    if (this.isLoadMachineScreen) {
      this.pmScreens.setCurrentScreenAsLoadMachine();
      if (this.isSource) {
        this.containerOrMachineLabel = 'Container';
      } else {
        this.containerOrMachineLabel = 'Machine';
      }
    } else if (this.isSieveScreen) {
      this.pmScreens.setCurrentScreenAsSieve();
      this.containerOrMachineLabel = 'Container';
    } else if (this.isMixBatchScreen) {
      this.pmScreens.setCurrentScreenAsMixBatch();
      this.containerOrMachineLabel = 'Container';
    } else if (this.isUnloadUnusedPowder) {
      this.pmScreens.setCurrentScreenAsUnloadUnusedPowderScreen();
      if (this.isSource) {
        this.containerOrMachineLabel = 'Machine';
      } else {
        this.containerOrMachineLabel = 'Container';
      }
    } else if (this.isUnloadCompletePowder) {
      this.pmScreens.setCurrentScreenAsUnloadCompletePowderScreen();
      if (this.isSource) {
        this.containerOrMachineLabel = 'Machine';
      } else {
        this.containerOrMachineLabel = 'Container';
      }
    } else if (this.isUnloadPollutedPowder) {
      this.pmScreens.setCurrentScreenAsUnloadPollutedPowderScreen();
      if (this.isSource) {
        this.containerOrMachineLabel = 'Machine';
      } else {
        this.containerOrMachineLabel = 'Container';
      }
    } else {
      this.containerOrMachineLabel = 'Container / Machine';
    }

  }


  /**
   * Scrap powder from the container
   */
  scrapPowder(): void {
    const containers: IContainer[] = this.getSelectedContainers();
    const selectedContainers: IContainer[] = [];
    const selectedContainerNames: string[] = [];

    if (containers.length > 0) {
      containers.forEach(container => {
        selectedContainers.push(container);
        selectedContainerNames.push(container.containerOrMachineName);
      });

      this.translate.get('Dialog.Title14').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message14').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
      this.translate.get('BTN.no_BTN').subscribe(value => this.NButton = value);

      this.openDialog('scrap_powder', this.Title, this.Mssg, this.YButton, this.NButton,
                       selectedContainers, selectedContainerNames);

    } else {

      this.translate.get('Dialog.Title15').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message15').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.ok_BTN').subscribe(value => this.YButton = value);

      this.openDialog('', this.Title, this.Mssg, this.YButton,
                       '', null, null);
    }

    console.log(containers);
  }

   /**
   * Scrap container - the container must be empty before scrapping the container
   */
  scrapContainer(): void {
    const containers: IContainer[] = this.getSelectedContainers();
    const emptyContainers: IContainer[] = [];
    const emptyContainerNames: string[] = [];
    const nonemptyContainers: IContainer[] = [];
    const nonEmptyContainerNames: string[] = [];

    if (containers.length > 0) {
      containers.forEach(container => {
        if (container.mass === 0) {
          emptyContainers.push(container);
          emptyContainerNames.push(container.containerOrMachineName);
        } else {
          nonemptyContainers.push(container);
          nonEmptyContainerNames.push(container.containerOrMachineName);
        }
      });
      if (nonEmptyContainerNames.length === 0) {

      this.translate.get('Dialog.Title16').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message16').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
      this.translate.get('BTN.no_BTN').subscribe(value => this.NButton = value);

      this.openDialog('scrap_container', this.Title, this.Mssg, this.YButton, this.NButton,
      emptyContainers, emptyContainerNames);

      } else {
        this.translate.get('Dialog.Title16').subscribe(value => this.Title = value);
        this.translate.get('Dialog.Message17').subscribe(value => this.Mssg1 = value);
        this.translate.get('Dialog.Message18').subscribe(value => this.Mssg2 = value);
        this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
        this.translate.get('BTN.no_BTN').subscribe(value => this.NButton = value);

        this.openDialog('scrap_container', this.Title, this.Mssg1 + nonEmptyContainerNames.join(',') + this.Mssg2, this.YButton,
        this.NButton, nonemptyContainers, nonEmptyContainerNames);
        // As per PM-242 the parameters are correctly passed to fix the bug
      }
    } else {
      this.translate.get('Dialog.Title15').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message15').subscribe(value => this.Mssg = value);
      this.translate.get('BTN.ok_BTN').subscribe(value => this.YButton = value);

      this.openDialog('', this.Title, this.Mssg, this.YButton,
                       '', null, null);
    }

    console.log(containers);
  }

  public openDialog(myaction: string,
    dialogTitle: string,
    dialogMsg: string,
    yes_text: string,
    no_text: string,
    selectedContainers: IContainer[],
    selectedContainerNames: string[]): void {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '250px',
      data: { myaction: myaction , dialogTitle: dialogTitle, dialogMsg: dialogMsg , yes_text: yes_text, no_text: no_text}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.backScreen === 'scrapPowder') {
        console.log('scrap powder');

        this.powderPickerService.scrapPowder(selectedContainerNames).subscribe(res => {

          const obj = JSON.parse(JSON.stringify(res));

          if (obj.isSuccess === true ) {
            this.openSnackBar('Powder scrapped successfully', 'Scrap Powder');
            this.dataSource.scrapPowderForSelectedContainers(selectedContainerNames);

          } else {
            // show error notification to the user
          }
        });

      } else if (result.backScreen === 'scrapContainer') {
        console.log('scrap container');
        this.powderPickerService.scrapContainer(selectedContainerNames).subscribe(res => {

          const obj = JSON.parse(JSON.stringify(res));

          if (obj.isSuccess === true ) {
            this.dataSource.removeSelectedContainers(selectedContainerNames);
            this.openSnackBar('Containers scrapped successfully', 'Scrap Container');
          } else {
            // show error notification to the user
          }
        });
      }
    });
  }


  /**
   * Opens a toast / snacbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  /**
   * Generating QR code and showing the generated QR code to take a print
   * @param containerOrMachineName
   */
  generateQRCode(container: IContainer): void {
    container.containerType = container.containerOrMachineName;
    this.openQRPrintDialog(container);
  }

   /**
   * This method opens the QR(s) Dialog, for print.
   */
  openQRPrintDialog(container: IContainer): void {
    const formDialogData: QRCodeDialogData = new QRCodeDialogData();
    const containers: IContainer[] = [];
    containers.push(container);

    formDialogData.dialogTitle = 'Print QR CODES';
    formDialogData.dialogMsg = '';
    formDialogData.containers = containers;

    const dialogConfig = new MatDialogConfig();

    // dialog native configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';

    dialogConfig.data = formDialogData;

    const dialogRef = this.dialog.open(QrcodegeneratorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('form dialog result : ' + JSON.stringify(result));

      this.dialog.closeAll();
    });
  }

  ngOnDestroy () {
    this.mediaQuery$.unsubscribe();
  }

}
