import { Component, OnInit } from '@angular/core';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonFormDialogData } from 'src/app/shared/dialog/common-form-dialog/CommonFormDialogData';
import { CommonFormDialogComponent } from 'src/app/shared/dialog/common-form-dialog/common-form-dialog.component';
import { ChangePropertiesService } from './change-properties.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { INewDelivery } from 'src/app/modals/INewDelivery';
import { IPowderType } from 'src/app/modals/IPowderType';
import { Observable } from 'rxjs';
import { IBatch } from 'src/app/modals/IBatch';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-properties',
  templateUrl: './change-properties.component.html',
  styleUrls: ['./change-properties.component.css']
})

export class ChangePropertiesComponent implements OnInit {

  newDeliveryData: INewDelivery;
  powders: IPowderType[] = [];
  filteredPowder: Observable<IPowderType[]>;
  Title: any;
  Mssg: any;

  constructor(private route: ActivatedRoute,
     private snackBar: MatSnackBar,
     public dialog: MatDialog,
     private changePropertiesService: ChangePropertiesService,
     public translate: TranslateService) { }

  ngOnInit() {
    this.newDeliveryData = this.route.snapshot.data['newDeliveryData'];
    this.powders = this.newDeliveryData.powderTypes;
  }

  /**
   * This method opens the Change Properties Dialog.
   */
  openChangePropertiesDialog(selectedContainer: IContainer): void {
      // this.openDialog(selectedContainer);
      this.translate.get('Dialog.Title20').subscribe(value => this.Title = value);
      this.translate.get('Dialog.Message20').subscribe(value => this.Mssg = value);

      const formDialogData: CommonFormDialogData = new CommonFormDialogData();
      formDialogData.dialogTitle = this.Title;
      // tslint:disable-next-line: max-line-length
      formDialogData.dialogMsg = this.Mssg + selectedContainer.containerOrMachineName;
      formDialogData.isChangePropForm = true;
      formDialogData.containerData = selectedContainer;
      // to pass the powder list to the common-form-dialog.component.ts
      formDialogData.powderTypes = this.powders;
      const dialogConfig = new MatDialogConfig();

      // dialog native configurations
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '350px';

      dialogConfig.data = formDialogData;

      const dialogRef = this.dialog.open(CommonFormDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if (result !== undefined) {

          const data = JSON.parse(JSON.stringify(result));
          selectedContainer.isSeived = data.isSieved;
          selectedContainer.lastSeiveCycle = data.cycle;
          selectedContainer.mass = data.mass;
          // to update the powderName, batchName, powderCssColor, batchCssColor, lastLabCheckDone, labCheckAtCycle after the dialog close.
          selectedContainer.powderName = data.powderName;
          if (data.powderName !== null) {
            for (const powder of this.powders) {
              if (powder.powderName === data.powderName) {
                selectedContainer.powderCssColor = powder.powderCssColor;
                if (data.batchName !== null ) {
                  for (const batch of powder.batches) {
                    if (batch.batchName === data.batchName) {
                      selectedContainer.batchCssColor = batch.batchCssColor;
                    }
                  }
                }
              }
            }
          }
          selectedContainer.batchName = data.batchName;
          selectedContainer.lastLabCheckAtCycle = (data.labCheck === true) ?
            selectedContainer.lastSeiveCycle : selectedContainer.lastLabCheckAtCycle;
          selectedContainer.labCheckAtCycle = selectedContainer.labCheckAtCycle;

          this.changePropertiesService.changeProperties(selectedContainer).subscribe(res => {

            const obj = JSON.parse(JSON.stringify(res));

            if (obj.isSuccess === true ) {
              this.openSnackBar('Properties Changed Successfully!', 'Change Properties');
              this.dialog.closeAll();
            } else {
              this.openSnackBar('Something Went Wront, Please Try Again', 'Change Properties');
              this.dialog.closeAll();
            }

          });
        }

      });
  }

  /**
   * Opens a toast / snacbar message
   * @param message message to be displayed
   * @param action  itentifies the purpose
   */
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
}
