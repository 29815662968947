import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-consumable-type',
  templateUrl: './add-consumable-type.component.html',
  styleUrls: ['./add-consumable-type.component.css']
})
export class AddConsumableTypeComponent implements OnInit {

  /**
   * Form group used for the form
   */
  consumableTypeForm: FormGroup;

  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.consumableTypeForm = this.formBuilder.group({

    });
  }

  viewConsumables(): void {
    this.router.navigate(['/app/consumables/', 'manage-consumable-type']);
  }

}
