import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IMachineMetaData } from 'src/app/modals/IMachineMetaData';
import { IContainer } from 'src/app/shared/powderpicker/IContainer';
import { IModelData } from 'src/app/modals/IModelData';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Handles the services to fetch Powder data from the backend
 */
@Injectable({
  providedIn: 'root'
})
export class MachineParkService {

  /**
   * Back-end service URL
   */
  backendUrl = environment.apiUrl;
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '5200/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'container-apis';
    }
  }

  /**
   * Finding the machine types based on certain parameters
   */
  findMachinesInPark(
    search: string, sortOrder: string,
    pageNumber: number, pageSize: number): Observable<IContainer> {

    const path = this.STAGE + '/machine-park';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint, {
      params: new HttpParams()
        .set('search', search)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res['machineDetails'])
    );
  }


  /**
   * get all models.
   *
   */
  getModels(): Observable<IModelData[]> {

    const STAGE = environment.stage;
    if (this.STAGE.includes('-')) {
      this.STAGE += 'machine-apis';
    }
    const path = STAGE + '/model-names';
   const endpoint = this.authService.getUrl('4300/') + path;

   return this.http.get(endpoint)
   .pipe(
     map(res => res['models'])
    );
  }

  /**
   * Editing a machine type
   */
  editMachineType(machineData: IContainer): Observable<Object> {
    const path = this.STAGE + '/edit-machine-park';
    const endpoint = this.API_ROOT + path;

    return this.http.post(endpoint, {
      machineTypeData: machineData
    });
  }


  addNewMachineInMachinePark(metadata: IMachineMetaData): Observable<Object> {
    const path = this.STAGE + '/machine-park';
    const endpoint = this.API_ROOT + path;

    return this.http
           .post(endpoint, { metadata: metadata })
           .pipe(catchError(this.handleError));
  }


  editMachineInMachinePark(metadata: IMachineMetaData, containerOrMachineId: string): Observable<Object> {
    const path = this.STAGE + '/edit-machine-park';
    const endpoint = this.API_ROOT + path;

    return this.http.put(endpoint, {
      machineParkData: metadata,
      containerOrMachineId: containerOrMachineId
    }).pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = err.error;
    }
    return throwError(errorMessage);
  }
}
