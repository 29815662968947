import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sieve-source',
  templateUrl: './sieve-source.component.html',
  styleUrls: ['./sieve-source.component.css']
})
export class SieveSourceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
