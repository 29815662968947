import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { PowderPickerService } from '../shared/powderpicker/powder-picker-service';
import { CommonModalComponent } from '../shared/dialog/common-modal.component';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ContainerMachineGuard implements CanActivate {
  Title: any;
  Mssg: any;
  YButton: any;
  NButton: any;

  constructor(public dialog: MatDialog,
    private powderPickerService: PowderPickerService,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService) {

    }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    console.log("current path: " + this.router.url)
    console.log('next url: ' + next.url);
    console.log('state url: ' + state.url);

    if (this.router.url.search('target') !== -1) {
      return true;
    }

    return this.powderPickerService.isContainerOrMachineLocked(this.authService.getUserId()).pipe(
      map(res => {
        if (res) {
          this.openDialog(state);
          return false;
        } else {
          return true;
        }
      }));

  }

  public openDialog(state: RouterStateSnapshot): void {
    this.translate.get('Dialog.Title25').subscribe(value => this.Title = value);
    this.translate.get('Dialog.Message25').subscribe(value => this.Mssg = value);
    this.translate.get('BTN.yes_BTN').subscribe(value => this.YButton = value);
    this.translate.get('BTN.no_BTN').subscribe(value => this.NButton = value);
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '250px',
      data: { myaction: 'discard_pwm_process',
              dialogTitle: this.Title,
              dialogMsg: this.Mssg,
              yes_text: this.YButton,
              no_text: this.NButton
            }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.backScreen === 'start') {
        console.log('process discarded');

        this.powderPickerService.resetStateAfterPowderMovementCompletion(this.authService.getUserId()).subscribe(res => {

          const obj = JSON.parse(JSON.stringify(res));
          this.router.navigate([state.url]);
        });

      } else {

      }
    });

  }
}
