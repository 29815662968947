import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-log-history',
  templateUrl: './log-history.component.html',
  styleUrls: ['./log-history.component.css']
})
export class LogHistoryComponent implements OnInit {

  logs = [];
  constructor(
    private router: ActivatedRoute
  ) { }

  ngOnInit() {
    this.logs = this.router.snapshot.data.logsData;
    this.segregateContainers();
  }

  segregateContainers() {
    for (const log of this.logs) {
      const srcContainers = [];
      const desContainers = [];
      if (log.data.containers) {
        for (const container of log.data.containers) {
          container.bookedForSource && container.bookedForSource === true ? srcContainers.push(container) : desContainers.push(container);
        }
      }
      log.data.srcContainers = srcContainers;
      log.data.desContainers = desContainers;
    }

    console.log(this.logs);
  }

  getTitle(log) {

    const srcIndex = log.data.srcContainers.findIndex(container => {
      return container.isMachine === true;
    });

    if (srcIndex > -1) {
      return 'Load Machine';
    }

    const desIndex = log.data.desContainers.findIndex(container => {
      return container.isMachine === true;
    });

    if (desIndex > -1) {
      return 'UnLoad Machine';
    }

    const title = log.data.newBatchColor && log.data.newBatchColor !== '' ? 'Mix Batch' : 'Move Powder';

    return title;
  }

}
