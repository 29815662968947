import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import { Permissions } from './permissions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private tokenTimer: NodeJS.Timer;
  public isAuthorized = false;
  public authStatusListener = new Subject<boolean>();

  refreshToken = new BehaviorSubject<string>('');

  backendUrl = environment.apiUrl;
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '7002/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'machine-apis';
    }

    this.refreshToken.subscribe(value => {
      if (value === 'Refresh Token') {
        this.autoAuthUser();
      }
    });
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getIsAuthorized() {
    return this.isAuthorized;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  /**
   * reset the application by removing the stored items in the browser
   * settting isAuthorized flag to false and observable authStatusListener to return false
   * clear the timer and calling the api logout method.
   */
  logout() {
    this.isAuthorized = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    localStorage.clear();
    this.unSubscribeAll();
    window.location.href = `${environment.authUri}/logout?` +
      `client_id=${environment.clientId}` +
      `&logout_uri=${environment.logoutUri}`;
  }

  /**
   * To retrieve all the features for which
   * the user doesn't have the privilege and
   * accordingly hide those features
   */
  getRestrictions(): string[] {
    const token: any = this.getToken();
    const restrictions: string[] = [];

    if (token) {
      const permissions = this.hex2bin(localStorage.getItem('permissions'));
      if (permissions) {
        for (let i = 0; i < permissions.length; i++) {
          if (permissions.charAt(i) === '0') {
            restrictions.push(Permissions['_' + (i + 1)]);
          }
        }
      }
    }
    return restrictions;
  }

  checkRestrictions(restrictionToBeChecked) {
    const restrictions = this.getRestrictions();
    const index = restrictions.indexOf(restrictionToBeChecked);
    if (index !== -1) {
      return false;
    }
    return true;
  }

  /**
   * For each application reload we first check whether the user
   * is authorized i.e. by retrieving the stored data in the browser
   * and checking the token for expiration
   */
  autoAuthUser() {
    clearTimeout(this.tokenTimer);
    this.refreshToken.next('');
    const now = new Date();
    const expiresIn = parseInt(localStorage.getItem('expiration')) * 1000 - now.getTime();
    if (expiresIn > 0) {
      this.setAuthTimer(expiresIn / 1000);
    }
  }


  /**
   * Timer is set here i.e. the first time when the user logs in
   * and for each reload with the calculated duration value.
   */
  public setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      /**
       * We again hit the refresh token service so that we can refresh
       * the token and expiration values stored inside of
       * the browser
       */
      this.initAuth();
    }, duration * 1000);
  }

  /**
   * retrieve the token and expiration values
   * from the local storage if not present then simply return
   */
  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: expirationDate
    };
  }

  /**
   *
   * To identify the respective previliges w.r.t the user
   * premission HEX Data returned from the server
   * as part of jwt ( i.e. index value = 1 means previlige provided)
   */
  hex2bin(hex: string): string {
    return (parseInt(hex, 16).toString(2)).padStart(8, '0');
  }

  /**
   * To retrive the user-id from the jwt token
   */
  getUserId(): string {
    return localStorage.getItem('userId');
  }

  /**
   * To retrive the transaction timestamp from the jwt token
   */
  getTransactionTimeStamp(): number {
    if (this.getToken() !== null) {
      return jwt_decode(this.getToken()).transactionTimestamp;
    } else {
      return 0;
    }
  }

  getUrl(port) {
    if (this.backendUrl === 'http://localhost:') {
      return this.backendUrl + port;
    }
    return this.backendUrl;
  }

  validateUser(): Observable<Object> {
    return this.http.get(this.API_ROOT + this.STAGE + '/validate-user');
  }

  signIn(code: string): Observable<Object> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const body = `grant_type=authorization_code` +
      `&client_id=${environment.clientId}` +
      `&code=${code}` +
      `&redirect_uri=${environment.redirectUri}`;

    return this.http.post(`${environment.authUri}/oauth2/token`, body, { headers: headers });
  }

  redirectToSSO() {
    window.location.href = `${environment.authUri}/oauth2/authorize` +
      `?identity_provider=MyID` +
      `&redirect_uri=${environment.redirectUri}` +
      `&response_type=CODE` +
      `&client_id=${environment.clientId}` +
      `&scope=aws.cognito.signin.user.admin%20email%20openid%20profile`;
  }

  /**
   * When the application is launched in the browser
   * we first check for the authentication i.e. whether a valid
   * user session already exists or not.
   */
  initAuth() {
    this.isAuthenticated().subscribe((auth: boolean) => {
      this.authStatusListener.next(auth);
      this.isAuthorized = auth;
    });
  }

  /**
   * Asynchronously check for the valid session of
   * the logged in user.
   */
  isAuthenticated(): Observable<boolean> {
    const authInfo = this.getAuthData();

    const obs = Observable.create(observer => {
      if (!authInfo) {
        observer.next(false);
      } else {
        const now = new Date();
        const expiration = parseInt(localStorage.getItem('expiration'));
        const expiresIn = expiration * 1000 - now.getTime();
        if (expiresIn > 0) {
          // TO-DO: Refresh Token
          this.refreshToken.next('Refresh Token');
          observer.next(true);
        } else {
          observer.next(false);
        }
      }
      observer.complete();
    });
    return obs;
  }

  /**
   * Parse token and setup the local storage
   * with the required data.
   */
  localStoreToken(token) {
    localStorage.setItem(
      'token',
      token.id_token
    );

    localStorage.setItem(
      'expiration',
      jwt_decode(token.id_token)['exp']
    );
  }

  /**
   * Parse user and setup the local storage
   * with the required data.
   */
  localStoreUser(user) {
    localStorage.setItem(
      'userId',
      user.userId
    );

    localStorage.setItem(
      'firstName',
      user.firstName
    );

    localStorage.setItem(
      'lastName',
      user.lastName
    );

    localStorage.setItem(
      'email',
      user.email
    );

    localStorage.setItem(
      'role',
      user.role
    );

    localStorage.setItem(
      'permissions',
      user.permissions
    );
  }

  /**
   * To unsubscribe all the subjects &
   * behaviour subjects at time of logging
   * out of the application
   */
  unSubscribeAll() {
    this.refreshToken.next('');
    this.authStatusListener.next(false);
  }

}
