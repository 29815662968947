import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sieve-powder-src-tgt-transfer',
  templateUrl: './sieve-powder-src-tgt-transfer.component.html',
  styleUrls: ['./sieve-powder-src-tgt-transfer.component.css']
})
export class SievePowderSrcTgtTransferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
