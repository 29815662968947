import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IPowderType } from 'src/app/modals/IPowderType';

/**
 * Handles backend interactions for powder overview chart
 */
@Injectable({
    providedIn: 'root'
})
export class PowderOverviewChartService {

  /**
   * Back-end service URL
   */
  backendUrl = environment.apiUrl;
  API_ROOT = environment.apiUrl;
  STAGE = environment.stage;

  constructor(
    private http: HttpClient,
  ) {
    if (this.API_ROOT === 'http://localhost:') {
      this.API_ROOT += '6200/';
    } else if (this.STAGE.includes('-')) {
      this.STAGE += 'transaction-apis';
    }
  }

  /**
   * Getting the data for powder overview charts
   */
  getPowderOverviewChartsData(): Observable<IPowderType[]> {
    const path = this.STAGE + '/powder-chart';
    const endpoint = this.API_ROOT + path;

    return this.http.get(endpoint).pipe(
      map(res => res['powderTypes'])
    );
  }

}
