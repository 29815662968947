import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-load-machine-source',
  templateUrl: './load-machine-source.component.html',
  styleUrls: ['./load-machine-source.component.css']
})
export class LoadMachineSourceComponent implements OnInit {

  @Input()
  batchName: string = null;

  constructor() { }

  ngOnInit() {
  }

  /**
   *
   * @param $event Gets the value of total mass
   * that has been selected from the source-powder-picker.
   */
  getSelectedBatch($event) {

  }


}
